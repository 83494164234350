import styled from 'styled-components'
import sizes from '../../styles/media'

export const ImgLaptop = styled.div`
  display: none;
  margin-bottom: 1rem;
  && svg {
    border-radius: 10px;
  }
  @media ${sizes.lg} {
    display: flex;
  }
`

export const ImgTablet = styled.img`
  width: 100%;
  display: none;
  margin-bottom: 1rem;
  @media ${sizes.md} {
    display: flex;
  }
  @media ${sizes.lg} {
    display: none;
  }
`

export const ImgMobile = styled.div`
  margin-bottom: 1rem;
  && svg {
    border-radius: 50px;
  }
  @media ${sizes.lg} {
    display: none;
  }
`
