import { IPageEventProducts, PageEventProps } from "./IPageEvent";
import Product from "../../model/Product";

export class ProductEvent implements IPageEventProducts {
  private readonly TypeEvent: string;
  private readonly ActionField: string;
  private readonly GroupActionEvent: string;

  constructor(typeEvent: string, actionField: string, groupActionEvent: string) {
    this.TypeEvent = typeEvent;
    this.ActionField = actionField;
    this.GroupActionEvent = groupActionEvent;
  }

  initialize(product: Product): PageEventProps {
    let clickProduct = {
      name: product.name,
      price: product.minPrice,
      maxRegularPrice: product.maxRegularPrice,
      category: product.categories !== undefined && product.categories.length > 0 && product.categories.map((x) => x.slug),
      supplier: product.supplier.business_name,
    };

    return {
      event: this.TypeEvent,
      ecommerce: {
        [this.GroupActionEvent]: {
          actionField: { list: this.ActionField },
          products: clickProduct,
        },
      },
    };
  }
}
