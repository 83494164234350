import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { Items, TypeItems, FilteredItems } from '../../helpers/itemsMobile'

import CartContext from '../../context/CartContext'
import { ContainerItems, Box, Item } from './style'
import Icon from '../Icon'

function MenuItems({ showBanner = true }: any) {
  const { items } = useContext(CartContext)
  const itemsToShow = showBanner ? Items : FilteredItems
  return (
    <ContainerItems>
      <Box>
        {itemsToShow.map(({ pathname, name, order }: TypeItems) => {
          if (name == 'Carrito de compras' && items.length == 0) {
            pathname = 'empty-cart'
          }
          return (
            <Item key={pathname}>
              <Link to={pathname}>
                <span>{name}</span>
                <Icon
                  color="black"
                  size="20px"
                  name="right-open"
                  className="kronos-icon"
                />
              </Link>
            </Item>
          )
        })}
      </Box>
    </ContainerItems>
  )
}

export default withRouter(MenuItems)
