import gql from 'graphql-tag'

const GET_SUPPLIER_STATUS = gql`
  query getSupplierById($supplierID: String!) {
    supplierId(id: $supplierID) {
      id
      status
    }
  }
`

export { GET_SUPPLIER_STATUS }
