import { createContext } from "react";

type Load = {
  loading: boolean;
};

export type Loading = {
  loading: Load;
  setLoading?: (loading: Load) => void;
};

const LoadingContext = createContext<Loading>({ loading: { loading: false } });

export default LoadingContext;
