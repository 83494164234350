import styled from 'styled-components'
import { COLORS } from '../../../../styles/colors'

type PropsSpan = {
  empty?: boolean
}
export const StyledFlexCol = styled.div`
  display: flex;
  flex-direction: column;
  label,
  span {
    margin-bottom: 7px;
  }
  span {
    margin-top: 0px;
  }

  .css-dpec0i-option {
    background-color: ${(props: PropsSpan) => {
      return props.empty === true ? '#9cd878 !important' : 'initial'
    }};
    -webkit-tap-highlight-color: ${(props: PropsSpan) =>
      props.empty === true ? 'unset' : 'rgba(0, 0, 0, 0)'};
  }

  .select-control-container {
    .select-control__control {
      background-color: ${COLORS.teeth};
    }
  }
`

export const StyledAction = styled.span`
  margin: 0 !important;
  font-weight: bold;
  color: #008900;
`

export const StyledNewChoice = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background: #9cd878;
    ${StyledAction} {
      color: ${COLORS.white};
    }
  }
`

export const StyledNewData = styled.span`
  margin: 0 !important;
  position: relative;
  top: 2px;
`

export const TextAreaElements = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const Element = styled.div`
  width: 80%;
`

export const CountContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  width: 20%;
  font-size: 12px;
  color: #303030;
  margin-top: 3px;
  line-height: 2;
`
