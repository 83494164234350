import styled from 'styled-components'

export const ContainerItems = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #f0f0f0;
`
export const Box = styled.ul`
  display: block;
  padding: 0;
`

export const Item = styled.li`
  list-style-type: none;
  padding: 1.5rem;
  text-transform: uppercase;
  font-size: 14px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  width: 280px;

  a,
  a:hover {
    display: flex;
    justify-content: space-between;
    color: black;
  }
`
