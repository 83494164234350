import React, { useContext, useState, useEffect } from 'react'
import { Sticky } from 'react-sticky'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import CartButton from '../../components/CartButton'
import BannersBox from '../../components/BannersBox'
import { HeaderWrapper, FooterWrapper, Main, StyledSideBar, StyledContainer, CustomStickyContainer, Searcher, StaticBanner } from './style'
import { useIsMobile } from '../../helpers/resize'
import Loader from '../Loader'
import ThemeContext from '../../context/ThemeContext'
import LoaderContext from '../../context/LoaderContext'
import MenuItems from '../MenuItems'
import Search from '../Search'
import Breadcrumb, { Item } from '../../components/Breadcrumb'
import { Column } from '../Grid'
import Localization from '../Localization'
import LocationContext from '../../context/LocationContext'
import { COLORS } from '../../styles/colors'

type Props = {
  children: React.ReactNode
  backgroundColor?: string
  isHome?: boolean
  showCart?: boolean
  staticBannerUrl?: string
  breadcrumbLinks?: Item[]
  hasLocationBar?: boolean
}

export type CouponNotification = {
  hasCoupon: boolean
  couponValue: string
  showNotification: ({}: { type: string; message: string }) => void
}

function Layout({ children, backgroundColor, isHome, showCart = false, staticBannerUrl, breadcrumbLinks, hasLocationBar }: Props) {
  const initialMql = window.matchMedia(`(max-width: 576px)`)
  const isMobile = useIsMobile(initialMql.matches)
  const { primary, third } = useContext(ThemeContext)
  const { withCoverage } = useContext(LocationContext)
  const isHidden = localStorage.getItem('hb')
  let {
    loading: { loading },
  } = useContext(LoaderContext)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [sidebarTop, setSidebarTop] = useState('5rem')
  const [searchOpen, setSearchOpen] = useState(false)
  const [mcn, setMcn] = useState(false)

  useEffect(() => {
    const mcnStorage = window.sessionStorage.getItem('mcn')
    if (mcnStorage === '1') {
      setMcn(true)
    }
  }, [])

  const onSetSidebarOpen = () => {
    setSidebarOpen(!sidebarOpen)
  }

  const onSetSearchOpen = () => {
    setSearchOpen(!searchOpen)
  }

  var token = window.localStorage.getItem('token') || ''
  if (!token) {
    token = Math.random().toString(36).substring(2, 15).toUpperCase() + Math.round(+new Date() / 1000)
    window.localStorage.setItem('token', token)
  }

  return (
    <CustomStickyContainer>
      <Searcher
        sidebar={<Search setSearchOpen={setSearchOpen} />}
        open={searchOpen}
        onSetOpen={onSetSearchOpen}
        pullRight={true}
        overlayClassName={'customOverlay'}
        styles={{
          root: {
            height: '100vh',
          },
          sidebar: {
            position: 'fixed',
            background: 'white',
            top: '0',
            height: isMobile ? '100vh' : 'fit-content',
            width: '100%',
            zIndex: '4',
          },
          content: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
          },
        }}
      >
        <StyledSideBar
          sidebar={<MenuItems showBanner={isHidden === 'true' ? false : true} />}
          open={sidebarOpen}
          onSetOpen={onSetSidebarOpen}
          styles={{
            sidebar: {
              position: 'fixed',
              background: 'white',
              top: sidebarTop,
              zIndex: '4',
            },
            content: {
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'nowrap',
            },
          }}
        >
          {loading && <Loader />}
          <Sticky topOffset={45}>
            {({ style, isSticky }) => {
              setSidebarTop(isSticky === true ? '4.5rem' : '60px')
              return (
                <HeaderWrapper style={style} backgroundcolor={mcn ? COLORS.white : primary} showBanner={isHidden === 'true' ? false : true} className={searchOpen ? 'active' : ''}>
                  <div className="header-wrapper">
                    <Header showBanner={isHidden === 'true' ? false : true} sidebarOpen={sidebarOpen} onSetSidebarOpen={onSetSidebarOpen} setSearchOpen={setSearchOpen} />
                  </div>
                  {hasLocationBar && <Localization />}
                </HeaderWrapper>
              )
            }}
          </Sticky>

          {hasLocationBar ? <div className={`dummy-space${withCoverage ? ' coverage-yes' : ' no-coverage'}`} /> : <div className="dummy-space no-bar" />}
          {!mcn && isHome && <BannersBox />}

          {!mcn && breadcrumbLinks && breadcrumbLinks.length > 0 && (
            <Column className="breadcrumb-margin-top">
              <StyledContainer>
                <Breadcrumb links={breadcrumbLinks} />
              </StyledContainer>
            </Column>
          )}
          {staticBannerUrl && <StaticBanner staticBannerUrl={staticBannerUrl} />}
          <Main backgroundcolor={backgroundColor ? backgroundColor : third} className={searchOpen ? 'blured' : ''}>
            <StyledContainer>{children}</StyledContainer>
          </Main>
          {!mcn && (
            <FooterWrapper backgroundcolor={primary}>
              <Footer />
            </FooterWrapper>
          )}
          {showCart && <CartButton></CartButton>}
        </StyledSideBar>
      </Searcher>
    </CustomStickyContainer>
  )
}

export default Layout
