import React from "react";
import BillingViewHandler from "../views/BillingViewHandler";

type SwitchProps = {
  Component: React.ComponentType;
  FallbackComponent: React.ComponentType;
};

type BillingFallBackProps = {
  Component: React.ComponentType;
  FallbackComponent: React.ComponentType;
};

const Switch = (props: SwitchProps) => {
  const { Component, FallbackComponent, ...rest } = props;

  return (
    <BillingViewHandler>
      {(hasAddress: boolean) => {
        if (!hasAddress) {
          return <FallbackComponent {...rest} />;
        } else {
          return <Component {...rest} />;
        }
      }}
    </BillingViewHandler>
  );
};

const billingFallback = (
  Component: React.ComponentType,
  FallbackComponent: React.ComponentType
) => {
  return (props: BillingFallBackProps) => (
    <Switch
      {...props}
      Component={Component}
      FallbackComponent={FallbackComponent}
    />
  );
};

export default billingFallback;
