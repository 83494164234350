import styled from 'styled-components'
import { Link } from 'react-router-dom'
import sizes from '../../styles/media'
import { COLORS } from '../../styles/colors'

export const SupplierContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  height: unset;
  margin: 1rem 0;
  padding: 0;
  max-height: 200px;
  &.mt {
    margin: 0;
    height: 100%;
  }
  @media ${sizes.sm} {
    margin: 2rem 0;
    height: unset;
  }
`
export const HomeTag = styled.div`
  width: 48%;
  margin: 0;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  img {
    width: 24px;
    max-width: 24px;
    margin-left: 0.3rem;
  }
  @media ${sizes.sm} {
    width: 50%;
    max-width: 140px;
  }
`

export const CloseTag = styled.div`
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.dark};
`

export const ScheduleTag = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 2rem;
  left: 1rem;
  img {
    width: 40px;
    margin-right: 0.5rem;
  }
  p {
    margin: 0%;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${COLORS.white};
  }
`

export const Spacer = styled.div`
  height: 2.35rem;
  display: block;
  @media ${sizes.sm} {
    display: none;
  }
`

export const DiscountLabel = styled.div`
  position: absolute;
  top: -0.2rem;
  right: 0.7rem;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.white};
  background-color: #de8f07;
  padding: 0.35rem 0.7rem;
  border-radius: 0 0 5px 5px;
  &.closed {
    background-color: ${COLORS.dark};
  }
`
export const ImgContainer = styled.div`
  display: block;
  position: absolute;
  top: 0.72rem;
  left: 0.72rem;
  img {
    width: 70px;
    max-width: 70px;
    margin: 0;
    border-radius: 50%;
  }
  &.closed {
    img {
      filter: grayscale(90%);
    }
  }
`
export const CoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
  border: 0px solid;
  border-color: transparent;
  height: 100%;
  overflow: hidden;

  @media ${sizes.sm} {
    box-shadow: 0 2px 4px 0 rgba(137, 137, 137, 0.3);
  }
  &.closed {
    img {
      filter: grayscale(90%);
    }
  }
  &:hover {
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    @media ${sizes.sm} {
      box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
    }
  }
  img {
  }
`
export const Layer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(36, 36, 36, 0.4);
`
export const Img = styled.img`
  width: 100%;
  max-width: 100%;
`

export const SupplierInfoContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 0 0;
  align-items: center;
  justify-content: space-between;
  p {
    font-size: 12px;
    text-align: center;
    margin: 0;
    letter-spacing: 0.5px;
    min-height: 2.5rem;
  }
`

export const SupplierPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 0.7rem;
`

export const ButtonContainer = styled.div`
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.63px;
  text-align: center;
  color: ${COLORS.white};
  width: 70%;
  margin: 0.2rem auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  letter-spacing: 1px;
`

export const TitleCenter = styled.h2`
  margin-top: 21px;
  font-size: 18px;
  text-align: center;
  @media ${sizes.xs} {
    margin-bottom: 10px;
  }
  @media ${sizes.md} {
    font-size: 24px;
    text-align: left;
    margin-bottom: 24px;
  }
`

export const CustomTitle = styled.div`
  word-break: break-word;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`

export const SupplierContainerResponsive = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  grid-auto-rows: minmax(min-content, max-content);
`

export const SupplierResponsive = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(137, 137, 137, 0.3);
  background-color: white;
  padding: 0.5rem;
  height: 100%;
`

export const LinkResponsive = styled(Link)`
  color: black;
`

export const PriceContainer = styled.div`
  display: flex-end;
`

export const StyledTitle = styled.h2`
  margin: 0;
  margin-top: 1.5rem;
`
