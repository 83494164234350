import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { Query, QueryResult } from 'react-apollo'
import Supplier from '../../model/Supplier'
import Product from '../../model/Product'
import { GET_SUPPLIERS_QUERY, GET_PRODUCTS_QUERY } from './query'
import * as Grid from '../Grid'
import {
  Wrapper,
  Logo,
  ContentContainer,
  StoreItem,
  SearchBox,
  SearchWrapper,
  Input,
  Close,
  EmptyBox
} from './style'
import Icon from '../Icon'
import gtLogo from '../../assets/header/logo-gt-black.svg'
import searchIconBlack from '../../assets/header/search-black.svg'
import closeIcon from '../../assets/header/close.svg'
import emptySearchIcon from '../../assets/header/empty-search.svg'
import Categories from './categories'

interface DataSuppliers {
  suppliers: {
    nodes: Supplier[]
  }
}

interface DataProducts {
  products: {
    nodes: Product[]
  }
}

interface Filter {
  k: String
}

interface Options {
  limit: Number
  sortBy: String
}

interface Variables {
  filters: Filter
  options: Options
}

function EmptyResults() {
  return (
    <EmptyBox>
      <img src={emptySearchIcon} />
      <h2>No hay resultados para tu búsqueda</h2>
      <p>Intenta una búsqueda diferente</p>
    </EmptyBox>
  )
}

function Search({ setSearchOpen }: any) {
  const [keyword, setKeyword] = useState('')
  let hasResult = false;
  const [suppliersVariables, setSuppliersVariables] = useState({
    filters: { k: '-1' },
    options: {
      limit: 100,
      sortBy: 'priority'
    }
  })

  const handleSearch = (e: any) => {
    setKeyword(e.target.value)
    if (e.target.value.length > 2) {
      setSuppliersVariables({
        ...suppliersVariables,
        filters: { k: e.target.value }
      })
      return
    }
    setSuppliersVariables({
      ...suppliersVariables,
      filters: { k: '-1' }
    })
  }

  const closeSlider = () => {
    setSearchOpen(false)
    setKeyword('')
    setSuppliersVariables({
      ...suppliersVariables,
      filters: { k: '-1' }
    })
  }

  const hasSuppliers = (data: DataSuppliers) => {
    return (
      data.suppliers &&
      data.suppliers.nodes &&
      data.suppliers.nodes.length > 0
    )
  }

  const hasProducts = (data: DataProducts) => {
    return (
      data.products &&
      data.products.nodes &&
      data.products.nodes.length > 0
    )
  }

  return (
    <Grid.Container>
      <Wrapper>
        <SearchBox>
          <Logo to="/">
            <img src={gtLogo} />
          </Logo>

          <ContentContainer>
            <SearchWrapper>
              <img src={searchIconBlack} className="search" />
              <Input
                type="text"
                placeholder="Buscar"
                onChange={(e: any) => {
                  handleSearch(e)
                }}
                value={keyword}
              />
            </SearchWrapper>
            {keyword.length < 3 ? (
              <Categories setSearchOpen={setSearchOpen} />
            ) : (
              <Query<DataSuppliers, Variables>
                query={GET_SUPPLIERS_QUERY}
                variables={suppliersVariables}
              >
                {({loading, error, data}: QueryResult<DataSuppliers, Variables>) =>{
                  if (loading) {
                    return null
                  }

                  if (!data || !hasSuppliers(data)) {
                    hasResult = true
                    return null
                  }

                  if (error) {
                    return <Categories setSearchOpen={setSearchOpen} />
                  }

                  const { nodes } = data.suppliers
                  hasResult = false

                  return (
                    <>
                      <p className="title">Restaurantes</p>
                      {nodes.map((item: Supplier, index: number) => {
                        return(
                          <Link
                            to={`/restaurant/${item.slug}`}
                            onClick={closeSlider}
                          >
                            <StoreItem key={index}>
                              <div className="store">
                                <img
                                  src={
                                    item.assets && item.assets.length > 0
                                      ? item.assets[0].url
                                      : 'https://storage.googleapis.com/gaia-assets-bucket/dba24fe0-3151-4063-927d-178b881ef7aa/759df298-d1d5-41bf-9a68-24395e9089df.jpg'
                                  }
                                />
                                <div className="item-info">
                                  <p>{item.business_name}</p>
                                  <p className="city-name">{item.city}</p>
                                </div>
                              </div>
                              <Icon
                                color="black"
                                size="12px"
                                name="right-open"
                                className="kronos-icon"
                              />
                            </StoreItem>
                          </Link>
                        )
                      })}
                    </>
                  )
                }}
              </Query>
            )}

            {keyword.length < 3 ? "" : (
              <Query<DataProducts, Variables>
                query={GET_PRODUCTS_QUERY}
                variables={suppliersVariables}
              >
                {({loading, error, data}: QueryResult<DataProducts, Variables>) =>{
                  if (loading) {
                    return null
                  }

                  if (error) {
                    return null
                  }

                  if (!data || !hasProducts(data)) {
                    return hasResult ? <EmptyResults /> : null
                  }
                  
                  const { nodes } = data.products

                  return (
                    <>
                      <p className="title">Restaurantes</p>
                      {nodes.map((item: Product, index: number) => {
                        return(
                          <Link
                            to={`/restaurant/${item.supplier.slug}`}
                            onClick={closeSlider}
                          >
                            <StoreItem key={index}>
                              <div className="store">
                                <img
                                  src={
                                    item.supplier.assets && item.supplier.assets.length > 0
                                      ? item.supplier.assets[0].url
                                      : 'https://storage.googleapis.com/gaia-assets-bucket/dba24fe0-3151-4063-927d-178b881ef7aa/759df298-d1d5-41bf-9a68-24395e9089df.jpg'
                                  }
                                />
                                <div className="item-info">
                                  <p>{item.supplier.business_name}</p>
                                  <p className="city-name">{item.supplier.city}</p>
                                </div>
                              </div>
                              <Icon
                                color="black"
                                size="12px"
                                name="right-open"
                                className="kronos-icon"
                              />
                            </StoreItem>
                          </Link>
                        )
                      })}
                    </>
                  )
                }}
              </Query>
            )}
          </ContentContainer>

          <Close onClick={closeSlider}>
            <img src={closeIcon} />
          </Close>
        </SearchBox>
      </Wrapper>
    </Grid.Container>
  )
}

export default withRouter(Search)
