import gql from 'graphql-tag'

const GET_SUPPLIERS_QUERY = gql`
  query getSuppliersQuery($filters: SuppliersFilters!, $options: SuppliersOptions!) {
    suppliers(filters: $filters, options: $options) {
      nodes {
        id
        slug
        allow_coupons
        business_name
        legal_name
        city
        assets {
          url
          order
        }
        metaData {
          categoria
          recomendado
          descuento
          en_casa
          descripcion
          horarios
        }
      }
    }
  }
`

const GET_CITIES = gql`
  query getCities($filters: CategoriesFilters!, $options: CategoriesOptions!) {
    categories(filters: $filters, options: $options) {
      nodes {
        id
        name
      }
    }
  }
`

export { GET_SUPPLIERS_QUERY, GET_CITIES }
