import styled from 'styled-components'
import media from '../../styles/media'

export const SpecialtiesBox = styled.div`
  padding-bottom: 15px;
  @media ${media.xl} {
    padding-bottom: 5px;
  }

  .no-side-margins {
    margin: 0 -15px;
  }

  .specialty-slider {
    .slick-list {
      margin: 0 45px;
      @media ${media.xl} {
        margin: 0;
      }

      .slick-track {
        .slick-active {
          margin-right: -20px;
          @media ${media.lg} {
            margin-right: 0;
          }
        }
      }
    }

    &.left-aligned {
      .slick-list {
        margin: 0 auto 0 0;

        .slick-track {
          width: 100% !important;

          @media ${media.xl} {
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            &:before {
              content: none;
            }
            &:after {
              content: none;
            }
          }

          .slick-active {
            @media ${media.xl} {
              margin-right: 19px;
              width: 263px !important;

              & > div {
                width: 263px;
              }
            }
          }
        }
      }
    }
  }
`
