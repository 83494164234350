import styled from 'styled-components'
import { COLORS } from '../../../../styles/colors'
import media from '../../../../styles/media'

export const Specialty = styled.div`
  border: 1px solid ${COLORS.camel};
  padding: 28px 15px 28px 20px;
  border-radius: 10px;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 263px;
  color: ${COLORS.dark};
  margin: 0 15px;
  @media ${media.sm} {
  margin: 0;
  }

  .circle {
    background-color: ${COLORS.camel};
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      display: block;
      color: ${COLORS.white};
      font-size: 25px;
      margin-top: -3px;
    }
  }

  .title {
    font-family: 'Roboto-Bold';
  }

  .description {
    font-family: 'Roboto-Light';
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 156px;
  }
`
