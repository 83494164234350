import * as React from 'react'
import { COLORS } from '../../styles/colors'
import { BagdeCircle, BagdeContainer } from './style'

interface Props {
  children: React.ReactNode
  type?: string
  size?: string
  customFunc?: () => void
}

const Badge = (props: Props) => {
  const { type, size, customFunc } = props
  let color = ''
  let margin = ''
  let cicleSize = ''

  if (type) {
    switch (type) {
      case 'fail': {
        color = '#ff3b30'
        break
      }
      case 'ok': {
        color = '#4cd964'
        break
      }
      case 'alert': {
        color = '#ffcc00'
        break
      }
      default: {
        color = COLORS.white
        break
      }
    }
  } else {
    color = COLORS.white
  }

  switch (size) {
    case 's': {
      cicleSize = '30px'
      margin = '8px 14px'
      break
    }
    case 'm': {
      cicleSize = '40px'
      margin = '8.5px'
      break
    }
    case 'l': {
      cicleSize = '50px'
      margin = '25px'
      break
    }
    default: {
      margin = '8px'
      cicleSize = '25px'
      break
    }
  }

  return (
    <BagdeContainer onClick={customFunc}>
      <BagdeCircle margin={margin} size={cicleSize} color={color}>
        {props.children}
      </BagdeCircle>
    </BagdeContainer>
  )
}

export default Badge
