import gql from 'graphql-tag'

const REDEEM_COUPON_QUERY = gql`
  query getCouponByCode($filters: CouponFilters!) {
    coupon(filters: $filters) {
      id
      code
      identification
      redeem
      value
      expires
      expires_from
      expires_to
      createdAt
      updatedAt
    }
  }
`

const ADD_TRACKING_MUTATION = gql`
  mutation AddTrack(
    $token: String!
    $createdAt: String!
    $createdAtUnix: Int!
    $type: String!
    $eventType: String!
    $email: String
    $phoneNumber: String
    $document: String
    $docUpdated: Int!
    $items: [VariationItems]
  ) {
    addTrack(
      token: $token
      createdAt: $createdAt
      createdAtUnix: $createdAtUnix
      type: $type
      eventType: $eventType
      email: $email
      phoneNumber: $phoneNumber
      document: $document
      docUpdated: $docUpdated
      items: $items
    ) {
      token
    }
  }
`

const GET_SUPPLIER_STATUS = gql`
  query getSupplierById($supplierID: String!) {
    supplierId(id: $supplierID) {
      id
      status
    }
  }
`

export { REDEEM_COUPON_QUERY, ADD_TRACKING_MUTATION, GET_SUPPLIER_STATUS }
