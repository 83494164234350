import React from 'react'
import { ContainerForm, FlexCol, Hr } from '../../style'
import { Input, Error } from '../../../../components/FormElements'
import { Spacer } from '../../../../components/Global/style'
import * as Grid from '../../../../components/Grid'
import { WritableLocationItem } from '../../types'
import { COLORS } from '../../../../styles/colors'

export type ContactInfoProps = {
  isDisabled: () => boolean
  isMobile: boolean
  saveInLocalStorage: (value: string, id: string) => void
  firstName: WritableLocationItem
  lastName: WritableLocationItem
  phone: WritableLocationItem
  email: WritableLocationItem
}
function ContactInfo(props: ContactInfoProps) {
  const {
    isDisabled,
    isMobile,
    saveInLocalStorage,
    firstName,
    lastName,
    phone,
    email
  } = props

  return isDisabled() && isMobile ? (
    <Hr />
  ) : (
    <ContainerForm backgroundcolor={COLORS.white} isDisabled={isDisabled()}>
      <Grid.Row>
        <Grid.Column lg={6} md={6} sm={12}>
          <FlexCol>
            <Input
              onBlur={(e: any) => {
                saveInLocalStorage(e.target.value, 'firstName')
              }}
              onChange={(e: any) => {
                saveInLocalStorage(e.target.value, 'firstName')
              }}
              id="firstName"
              type="text"
              label="Nombre"
              borderColor="#2684FF"
              required
              error={firstName.error}
              value={firstName.value}
              disabled={isDisabled()}
            />
            {firstName.error ? <Error>{firstName.message}</Error> : <Spacer />}
          </FlexCol>
        </Grid.Column>
        <Grid.Column lg={6} md={6} sm={12}>
          <FlexCol>
            <Input
              onBlur={(e: any) => {
                saveInLocalStorage(e.target.value, 'lastName')
              }}
              onChange={(e: any) => {
                saveInLocalStorage(e.target.value, 'lastName')
              }}
              id="lastName"
              type="text"
              label="Apellido"
              borderColor="#2684FF"
              required
              error={lastName.error}
              value={lastName.value}
              disabled={isDisabled()}
            />
            {lastName.error ? <Error>{lastName.message}</Error> : <Spacer />}
          </FlexCol>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column lg={6} md={6} sm={12}>
          <FlexCol>
            <Input
              onBlur={(e: any) => {
                saveInLocalStorage(e.target.value, 'phone')
              }}
              onChange={(e: any) => {
                saveInLocalStorage(e.target.value, 'phone')
              }}
              id="phone"
              type="text"
              label="Teléfono de Contacto"
              borderColor="#2684FF"
              required
              error={phone.error}
              value={phone.value}
              disabled={isDisabled()}
            />
            {phone.error ? <Error>{phone.message}</Error> : <Spacer />}
          </FlexCol>
        </Grid.Column>
        <Grid.Column lg={6} md={6} sm={12}>
          <FlexCol>
            <Input
              onBlur={(e: any) => {
                saveInLocalStorage(e.target.value, 'email')
              }}
              onChange={(e: any) => {
                saveInLocalStorage(e.target.value, 'email')
              }}
              id="email"
              type="text"
              label="Correo Electrónico"
              borderColor="#2684FF"
              maxLength={60}
              required
              error={email.error}
              value={email.value}
              disabled={isDisabled()}
            />
            {email.error ? <Error>{email.message}</Error> : <Spacer />}
          </FlexCol>
        </Grid.Column>
      </Grid.Row>
    </ContainerForm>
  )
}

export default ContactInfo
