import React, { useContext, useState, useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import Select from 'react-select'
import { Row, Column } from '../../../../components/Grid'
import Icon from '../../../../components/Icon'
import { Button } from '../../../../components/Buttons'
import SliderProduct from '../../../../components/SliderProduct'
import VariationModal from '../../../../components/VariationModal'
import CartContext from '../../../../context/CartContext'
import Price from './components/Price'
import ThemeContext from '../../../../context/ThemeContext'
import calculateDiscount from '../../../../helpers/calculateDiscount'
import {
  ButtonContainer,
  Name,
  Title,
  Subtitle,
  Restaurant,
  ItemContainer,
  ItemContainerDesktop,
  Quantity,
  QuantityContainer,
  QuantityButton,
  DetailContainer,
  StyledColumnCustom,
  CustomButtonContainer,
  StyledContainerFluid,
  ServiceContainer,
  StyleText,
  ContainerInfo,
  Tooltip,
  OptionGroup,
  CustomLabelOption,
  CustomlOption,
  CustomCheckmark,
  ProductDescription,
  SectorModal,
  ModalDataContainer,
  PriceDescription,
} from './style'
import { DiscountContainer, DiscountLabel } from '../../../../components/Global/style'
import Product from '../../../../model/Product'
import Variation from '../../../../model/Variation'
import Assets from '../../../../model/Assets'
import Breadcrumb from '../../../../components/Breadcrumb'
import frozen from '../../../../assets/product/frozen.svg'
import ready from '../../../../assets/product/ready.svg'
import tooltip from '../../../../assets/product/tooltip.png'
import modalart from '../../../../assets/images/modalart.png'
import sectorerror from '../../../../assets/images/sectorerror.png'
import { COLORS } from '../../../../styles/colors'
import LocationContext from '../../../../context/LocationContext'

const staticAddress = {
  province: '',
  provinceName: '',
  city: '',
  cityName: '',
}

interface Props extends RouteComponentProps<any> {
  product?: Product
  getCityByName?: (cityName: string) => void
  getZones?: (supplierSlug: string) => void
  mcn?: boolean
}

interface Zone {
  label: string
  value: string
}

function Description({ history, product, getCityByName, getZones, mcn }: Props) {
  if (!product) {
    return <></>
  }

  const { changeDeliveryInfo, onAddToCart, validateSameRestaurantItems, forceShowNotification, onDelAllItems, onSetProductScroll } = useContext(CartContext)
  const { primary, secondary } = useContext(ThemeContext)
  const { withCoverage } = useContext(LocationContext)
  const [modalOpen, setModalOpen] = useState(false)
  const [featureSelected, setFeaturesSelected] = useState(false)
  const [showVariatons, setShowVariations] = useState(false)
  const [sectorStatus, setSectorStatus] = useState('OK')
  const [selectedSector, setSelectedSector] = useState(() => {
    const selectedSectorLS = window.localStorage.getItem('selectedSectorSupplierID')
    return product.supplier && selectedSectorLS && selectedSectorLS === product.supplier.id ? '1' : '0'
  })
  const [count, setCount] = useState(1)
  const [assetsArray, setAssetsArray] = useState<Assets[] | []>([])
  const [maxRegularPrice, setMaxRegularPrice] = useState<number>(0)
  const [selectedVariation, setselectedVariation] = useState<Variation | undefined>()
  const [zones, setZones] = useState<String[] | []>([])
  const [selectedZone, setSelectedZone] = useState<Zone>({
    label: 'Seleccione un sector',
    value: '',
  })
  let no_stock = false
  if (selectedVariation) {
    let {
      inventory: { localStock, storageStock },
    } = selectedVariation
    no_stock = localStock + storageStock == 0 ? true : false
  }

  const redirectProduct = (slug: string) => history.push(`/restaurant/${slug}`)
  const closeVariationModal = () => setShowVariations(false)

  const selectVariation = (variation: Variation) => {
    setselectedVariation(variation)
    let arrAssets: Assets[] = []
    if (product.assets) {
      arrAssets = arrAssets.concat(product.assets)
    }
    setAssetsArray(arrAssets)
  }

  const addVariationToCart = (variation: Variation) => {
    setselectedVariation(variation)
    setFeaturesSelected(true)
    closeVariationModal()

    if (withCoverage) {
      openZonesModal()
    }
  }

  const renderProductImages = () => {
    if (product.assets) {
      setAssetsArray(product.assets)
    }
  }

  const handleSelectedZone = (zone: Zone) => {
    setSelectedZone(zone)
  }

  const openZonesModal = async () => {
    if (selectedSector === '1') {
      const zonesLS = JSON.parse(window.localStorage.getItem('address') || '{}')

      if (zonesLS.zone) {
        setSelectedZone({ label: zonesLS.zone, value: zonesLS.zone })
      }

      addtoCart(false)
      return
    }

    const zonesResponse = getZones && (await getZones(product.supplier ? product.supplier.slug : ''))
    if (zonesResponse) {
      const schedulesData: string = zonesResponse
      const schedules = schedulesData.indexOf(',') >= 0 ? schedulesData.split(',') : [schedulesData]
      setZones(schedules)
      setSelectedZone({ label: schedules[0], value: schedules[0] })
    }
    setModalOpen(true)
  }

  const replaceProduct = () => {
    onDelAllItems && onDelAllItems()
    window.localStorage.removeItem('scheduled')
    addtoCart(true)
  }

  const addtoCart = async (fromDelete: boolean) => {
    if (selectedVariation) {
      const addVariation = selectedVariation
      addVariation['product'] = product
      let isVariationValid = validateSameRestaurantItems && validateSameRestaurantItems(addVariation)

      if (sectorStatus === 'ERROR' && fromDelete) {
        isVariationValid = true
      }

      if (!isVariationValid) {
        setSectorStatus('ERROR')
        setModalOpen(true)
        return
      }

      if (isVariationValid) {
        const {
          inventory: { localStock, storageStock },
        } = selectedVariation
        let no_stock = localStock + storageStock == 0 ? true : false
        if (no_stock) {
          forceShowNotification &&
            forceShowNotification({
              type: 'fail',
              message: 'Producto fuera de stock',
            })
        } else {
          const addVariation = selectedVariation
          addVariation['product'] = product
          onAddToCart && onAddToCart(addVariation, count)
          forceShowNotification &&
            forceShowNotification({
              type: 'ok',
              message: `${name} se ha añadido al carrito.`,
            })
          window.localStorage.removeItem('response')

          if (selectedSector === '1') {
            const zonesLS = JSON.parse(window.localStorage.getItem('address') || '{}')
            window.localStorage.setItem('sector', selectedZone.value)
            staticAddress['zone'] = zonesLS.zone
            staticAddress['zoneName'] = zonesLS.zone
          } else {
            window.localStorage.setItem('sector', selectedZone.value)
            staticAddress['zone'] = selectedZone.value
            staticAddress['zoneName'] = selectedZone.value
          }

          const cityResponse: any = getCityByName && (await getCityByName(product.supplier ? product.supplier.city : ''))

          if (cityResponse) {
            staticAddress.city = cityResponse.id
            staticAddress.cityName = cityResponse.name.toUpperCase()
            staticAddress.province = cityResponse.parent.id
            staticAddress.provinceName = cityResponse.parent.name.toUpperCase()
          }

          window.localStorage.setItem('address', JSON.stringify(staticAddress))
          setCount(1)
          setModalOpen(false)
          setSectorStatus('OK')

          if (product.supplier && product.supplier.metaData && product.supplier.metaData.entrega) {
            changeDeliveryInfo && changeDeliveryInfo(product.supplier.metaData.entrega)
          }

          if (product.supplier) {
            setSelectedSector('1')
            window.localStorage.setItem('selectedSectorSupplierID', product.supplier.id)
            redirectProduct(product.supplier.slug)
          }
        }
      }
      return
    }

    forceShowNotification &&
      forceShowNotification({
        type: 'fail',
        message: 'Seleccione un producto',
      })
  }

  const quantityComponent = () => {
    if (selectedVariation) {
      let {
        inventory: { localStock, storageStock },
      } = selectedVariation
      let no_stock = localStock + storageStock == 0 ? true : false

      return (
        <>
          <DetailContainer>
            <ItemContainer>
              <Title>Cantidad: </Title>
              <QuantityContainer>
                <QuantityButton backgroundcolor={primary} fontcolor={secondary} onClick={() => count > 1 && setCount(count - 1)}>
                  <Icon color={secondary} name="minus" className="kronos-icon" />
                </QuantityButton>
                <Quantity backgroundcolor={primary}>{no_stock ? 0 : count}</Quantity>
                <QuantityButton backgroundcolor={primary} fontcolor={secondary} onClick={() => localStock + storageStock > count && 10 > count && setCount(count + 1)}>
                  <Icon color={secondary} name="plus" className="kronos-icon" />
                </QuantityButton>
              </QuantityContainer>
            </ItemContainer>
          </DetailContainer>
          <CustomButtonContainer>
            <Button
              backgroundColor={no_stock ? '#aaaaaa80' : secondary}
              color={primary}
              bordercolor={primary}
              data-product={name}
              data-quantity={count}
              mcn={mcn}
              id="MKTPL_DESPRO_COM"
              onClick={() => {
                product && product.variations && product.variations.length === 1 ? openZonesModal() : setShowVariations(true)
              }}
            >
              Agregar al carrito
            </Button>
          </CustomButtonContainer>
        </>
      )
    }
    return <></>
  }

  useEffect(() => {
    if (product) {
      setMaxRegularPrice(product.maxRegularPrice)
      if (product.variations) {
        const filteredVariations = product.variations.filter(variation => {
          return variation.status == 'active'
        })
        if (filteredVariations.length > 0) {
          selectVariation(filteredVariations[0])
        } else {
          renderProductImages()
        }
        const retularPrice = product.variations.map((variation: Variation) => {
          return variation.regularPrice
        })
        setMaxRegularPrice(Math.min(...retularPrice))
      }
      if (product.name !== undefined) {
        onSetProductScroll && onSetProductScroll(product.name)
      }
    }
  }, [])

  useEffect(() => {
    if (selectedVariation) {
      let {
        inventory: { localStock, storageStock },
      } = selectedVariation
      no_stock = localStock + storageStock == 0 ? true : false

      if (!no_stock) {
        openZonesModal()
        return
      }
      setFeaturesSelected(false)
    }
  }, [featureSelected])

  let { name, description, variations } = product

  const suppliersZones: Zone[] = []
  if (zones.length > 0) {
    zones.forEach((zone: any) => {
      suppliersZones.push({ label: zone, value: zone })
    })
  } else {
    suppliersZones.push({ label: 'opcion', value: 'Valor' })
  }

  const typeProduct = product.categories && product.categories[1] && product.categories[1].slug

  const discountValue = () => {
    return calculateDiscount(product.minPrice, maxRegularPrice)
  }

  return (
    <>
      <Row>
        <Column>
          <Breadcrumb
            links={[
              { label: 'Inicio', to: '/' },
              {
                label: product && product.supplier ? product.supplier.business_name : 'N/A',
                to: product && product.supplier ? '/restaurant/' + product.supplier.slug : '',
              },
              { label: name },
            ]}
          />
        </Column>
      </Row>
      <Row>
        <Column xs={12}>
          <StyledContainerFluid>
            <Row>
              <Column sm={12} md={0}>
                <ItemContainer>
                  <Name>{name}</Name>
                  <DiscountContainer>
                    de
                    {discountValue() > 0 && (
                      <DiscountLabel fontSize={'1rem'} margin={'5px 0'} backgroundcolor={primary} fontcolor={secondary}>
                        -{discountValue()}% de descuento
                      </DiscountLabel>
                    )}
                  </DiscountContainer>
                </ItemContainer>{' '}
              </Column>
              <StyledColumnCustom xs={12} md={6}>
                {assetsArray.length > 0 && <SliderProduct assets={assetsArray} alt={name + '-' + product.supplier.business_name + '-Grande Table Delivery'} />}
              </StyledColumnCustom>
              <Column md={6}>
                <ItemContainerDesktop>
                  <Restaurant>{product.supplier && product.supplier.business_name}</Restaurant>
                  <Name>{name}</Name>

                  <DiscountContainer>
                    {discountValue() > 0 && (
                      <DiscountLabel fontSize={'1rem'} margin={'5px 0'} backgroundcolor={primary} fontcolor={secondary}>
                        -{discountValue()}% de descuento
                      </DiscountLabel>
                    )}
                  </DiscountContainer>
                </ItemContainerDesktop>
                <ItemContainer>{selectedVariation && <Price variation={selectedVariation} />}</ItemContainer>
                <PriceDescription>Precio incluye IVA {discountValue() > 0 ? 'y descuento' : ''}</PriceDescription>
                <ContainerInfo>
                  <ServiceContainer>
                    <img src={typeProduct === 'congelado' ? frozen : ready} />
                    <StyleText>
                      <div className="box-container">
                        {typeProduct === 'congelado' ? (
                          <span>
                            Empacado <br></br>al vacío
                          </span>
                        ) : (
                          <span>
                            Listo para <br></br>disfrutar
                          </span>
                        )}
                        <Tooltip>
                          <span title={typeProduct === 'congelado' ? 'Producto debe ser preparado antes de consumir' : 'Producto listo para consumo'} className="tooltip">
                            <img className="icon-tooltip" src={tooltip}></img>
                          </span>
                        </Tooltip>
                      </div>
                    </StyleText>
                  </ServiceContainer>
                </ContainerInfo>
                {variations && (
                  <ItemContainer>
                    <OptionGroup>
                      {variations
                        .sort((a: any, b: any) => {
                          return a.price > b.price ? 1 : -1
                        })
                        .map((variation, index) => {
                          if (variation.status == 'active') {
                            return (
                              <CustomLabelOption style={{ display: 'none' }} key={`variation-option-${variation}-${index}`}>
                                <CustomlOption
                                  type="radio"
                                  value={variation.options[0].name}
                                  name="group"
                                  checked={selectedVariation ? variation.options[0].id == selectedVariation.options[0].id : false}
                                  onChange={() => selectVariation(variation)}
                                />
                                <CustomCheckmark>$ {variation.price}</CustomCheckmark>
                              </CustomLabelOption>
                            )
                          }
                          return false
                        })}
                    </OptionGroup>
                  </ItemContainer>
                )}
                {quantityComponent()}
              </Column>
            </Row>
          </StyledContainerFluid>
        </Column>
      </Row>
      <Row>
        <Column>&nbsp;</Column>
      </Row>
      <Row>
        <Column>
          <Subtitle>Incluye</Subtitle>
        </Column>
      </Row>
      <ProductDescription>
        <Column>
          <StyledContainerFluid>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </StyledContainerFluid>
        </Column>
      </ProductDescription>

      <SectorModal
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: 'auto',
            padding: '15px',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 100,
          },
        }}
        ariaHideApp={false}
        isOpen={modalOpen}
      >
        <>
          <ModalDataContainer padding={'30px 30px 0px 30px'} backgroundcolor={COLORS.white}>
            <Row>
              <Column>
                <img src={sectorStatus === 'OK' ? modalart : sectorerror} alt="art" width="80" className="modalIcon" />
              </Column>
              {sectorStatus === 'OK' ? (
                <Column>
                  <h2>Sectores con cobertura</h2>
                  <p className="description">Este establecimiento ofrece cobertura en ciertos sectores de la ciudad, escoja su sector:</p>
                  <Row>
                    <Column style={{ textAlign: 'left' }}>
                      <p>* Sector</p>
                      <Select
                        options={suppliersZones}
                        onChange={async (optionSelected: any) => {
                          handleSelectedZone(optionSelected)
                        }}
                        value={selectedZone}
                      />
                    </Column>
                  </Row>
                  <br></br>
                  <br></br>
                </Column>
              ) : (
                <Column>
                  <h2>Confirmación</h2>
                  <p className="description">
                    Ya tienes productos agregados a tu carrito, solo puedes escoger productos de un solo restaurante. ¿Quieres eliminar el producto seleccionado y agregar éste en
                    su lugar?
                  </p>
                </Column>
              )}
            </Row>
          </ModalDataContainer>
          <ModalDataContainer padding={'30px 30px 10px 30px'} backgroundcolor={'#f5f5f5'}>
            <span className="arrow"></span>
            {sectorStatus === 'OK' ? (
              <>
                <Row>
                  <Column sm={6}>
                    <ButtonContainer>
                      <Button backgroundColor={'#75787b'} color={secondary} onClick={() => setModalOpen(false)}>
                        Cancelar
                      </Button>
                    </ButtonContainer>
                  </Column>
                  <Column sm={6}>
                    <ButtonContainer>
                      <Button backgroundColor={primary} color={secondary} onClick={() => addtoCart(false)}>
                        Aceptar
                      </Button>
                    </ButtonContainer>
                  </Column>
                </Row>
                <Row>
                  <Column>
                    <p>
                      ¿No encontraste tu sector? <br></br>Pronto tendremos más opciones para tí.
                    </p>
                  </Column>
                </Row>
              </>
            ) : (
              <Row>
                <Column sm={6}>
                  <ButtonContainer>
                    <Button mcn={mcn} backgroundColor={'#75787b'} color={secondary} onClick={() => setModalOpen(false)}>
                      Cancelar
                    </Button>
                  </ButtonContainer>
                </Column>
                <Column sm={6}>
                  <ButtonContainer>
                    <Button mcn={mcn} backgroundColor={primary} color={secondary} onClick={replaceProduct}>
                      Carrito Nuevo
                    </Button>
                  </ButtonContainer>
                </Column>
              </Row>
            )}
          </ModalDataContainer>
        </>
      </SectorModal>

      <VariationModal
        variations={product && product.variations ? product.variations : []}
        productname={product && product.name}
        features={product && product.features}
        addVariationToCart={addVariationToCart}
        modalOpen={showVariatons}
        closeVariationModal={closeVariationModal}
      />
    </>
  )
}

export default withRouter(Description)
