import styled from 'styled-components'

export const StyledContainer = styled.div`
  width: 100%;
  margin: 1rem 0;
`

export const SocialNetworks = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`

export const Title = styled.h4`
  text-align: center;
`

export const SocialButton = styled.div`
  margin: 0 0.25rem;
  border: solid 1px #dddddd;
  border-radius: 5px;
  padding: 1rem 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 50px;
  &:hover {
    cursor: pointer;
  }
`
