import React from 'react'
import { StyledColumn, StyledSubTitle, SliderWrapper, StyledCarousel, CardWrapper, Line } from './style'
import SupplierCard from '../../components/SupplierCard'
import Supplier from '../../model/Supplier'
import { Row } from '../Grid'

interface Props {
  suppliers: any
  title: string
  mcn?: boolean
}

const settings = {
  dots: false,
  infinite: false,
  arrows: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '40px',
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

export const Recommended = ({ suppliers, title, mcn }: Props) => {
  return (
    <>
      {suppliers && suppliers.length > 0 && (
        <>
          <Row>
            <StyledColumn md={12} xs={12}>
              <Line />
            </StyledColumn>
          </Row>
          <Row>
            <StyledColumn sm={7} xs={12}>
              <StyledSubTitle>{title}</StyledSubTitle>
            </StyledColumn>
          </Row>
          {true ? (
            <Row>
              {suppliers &&
                suppliers.map((supplier: Supplier, index: number) => {
                  return (
                    <StyledColumn md={6} xs={12} lg={4} key={`recommended-${index}`}>
                      <SupplierCard supplier={supplier} />
                    </StyledColumn>
                  )
                })}
              <StyledColumn md={12} xs={12}>
                <Line></Line>
              </StyledColumn>
            </Row>
          ) : (
            <SliderWrapper>
              <StyledCarousel {...settings}>
                {suppliers &&
                  suppliers.map((supplier: Supplier, index: number) => {
                    return (
                      <CardWrapper>
                        <SupplierCard mcn={mcn} supplier={supplier} type="slider" />
                      </CardWrapper>
                    )
                  })}
              </StyledCarousel>
            </SliderWrapper>
          )}
        </>
      )}
    </>
  )
}

export default Recommended
