import gql from 'graphql-tag'

const GET_SUPPLIERS_QUERY = gql`
  query getSuppliersQuery(
    $filters: SuppliersFilters!
    $options: SuppliersOptions!
  ) {
    suppliers(filters: $filters, options: $options) {
      nodes {
        id
        slug
        allow_coupons
        business_name
        legal_name
        city
        assets {
          url
          order
        }
      }
    }
  }
`

const GET_PRODUCTS_QUERY = gql`
  query getProductsQuery(
    $filters: ProductsFilters!
    $options: ProductsOptions!
  ) {
    products(filters: $filters, options: $options) {
      nodes {
        id
        name
        supplier {
          id
          slug
          allow_coupons
          business_name
          legal_name
          city
          assets {
            url
            order
          }
        }
      }
    }
  }
`

export { GET_SUPPLIERS_QUERY, GET_PRODUCTS_QUERY }
