import styled from 'styled-components'
import Modal from 'react-modal'
import { COLORS } from '../../styles/colors'
import media from '../../styles/media'
import { Container } from '../Grid'

type LocalizationProps = {
  error?: boolean
  line?: boolean
}
export const LocalizationBar = styled.div`
  background-color: ${(props: LocalizationProps) => (props.error ? COLORS.darkSalmon : COLORS.camel)};
  display: flex;
  ${(props: LocalizationProps) => {
    if (props.line) {
      return `
      min-height: 42px;
      `
    }
    return ''
  }}

  #google-map-container.relative-position-first-child {
    & > div {
      display: none;
    }
  }
`

export const LocalizationContainer = styled(Container)`
  padding-top: 10px;
  padding-bottom: 15px;
  color: ${COLORS.dark};
  font-size: 14px;
  text-align: center;
  @media ${media.md} {
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    padding: 12px 0;
  }

  div {
    button {
      text-decoration: underline;

      img.info {
        position: absolute;
        margin-left: -25px;
        @media ${media.md} {
          position: relative;
          margin-left: 0;
          margin-right: 5px;
        }
      }

      .drop-down-icon {
        padding-left: 5px;
      }
    }
  }

  &.blocks {
    display: flex;
    flex-direction: row;

    @media ${media.md} {
      > div {
        display: flex;
        justify-content: flex-start;
        align-content: center;
        padding-right: 10px;
      }
    }
  }
`

export const FirstBlock = styled.div`
  color: ${COLORS.white};
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  @media ${media.md} {
    padding: 0;
    align-items: center;
  }

  .gt-city-sel {
    padding-right: 5px;
    @media ${media.md} {
      padding: 0 5px 0 15px;
    }
  }

  &.line {
    padding-bottom: 0;
    @media ${media.md} {
      width: 100%;
      justify-content: flex-start;
    }
  }

  .message {
    position: relative;
    padding: 0 15px;
    display: flex;
    align-items: flex-start;
    @media ${media.lg} {
      padding: 0 10px 0 30px;
      align-items: center;
    }

    .info {
      margin-right: 5px;
    }
  }
`

export const SecondBlock = styled.div`
  font-family: 'Roboto-Bold';
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  button {
    .location-icon {
      margin-right: 5px;
    }
  }
`

type LocationButtonProps = {
  white?: boolean
}
export const LocationButton = styled.button`
  ${(props: LocationButtonProps) => (props.white ? `color: ${COLORS.white};` : '')}
  background: none;
  border: none;
  cursor: pointer;
  font-family: 'Roboto-Bold';
  display: flex;
  flex-direction: row;
  align-items: center;

  &:focus,
  &:focus-visible {
    outline: none;
  }
`

export const ModalPanel = styled(Modal)`
  border: 1px solid ${COLORS.white};
  margin: 0 -50% 0 auto;
  display: flex;
  justify-content: center;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 10px;
  position: absolute;
  background-color: ${COLORS.white};
  width: 345px;
  @media ${media.md} {
    padding: 20px 30px;
    width: 420px;
  }
`

export const ModalContainer = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  .close-btn-row {
    display: flex;
    justify-content: flex-end;

    button {
      background: none;
      border: none;
      width: 20px;
      height: 20px;
      cursor: pointer;

      img {
        width: 100%;
      }
    }
  }
`

export const ModalText = styled.p`
  text-align: center;
  margin: 0 0 20px;
  @media ${media.md} {
    padding: 0 40px;
  }

  &.announcement {
    font-family: 'Roboto-Medium';
    font-size: 25px;
    color: ${COLORS.dark};

    span {
      color: ${COLORS.camel};
    }
  }

  &.select-txt {
    font-family: 'Roboto-Light';
    color: ${COLORS.purpleBrown};
    font-size: 16px;
  }
`

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  color: ${COLORS.black};
  font-size: 15px;
  width: 20px;
  height: 20px;
  margin-right: 10px;
`
