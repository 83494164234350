import { createContext } from "react";

export type Response = {
  message: string;
  title: string;
  body: string[];
  image: string;
  analyticId?: string;
  requestId: string;
  signature: string;
  status: string;
  orderId: string;
  orderStatus: string;
  reference: string;
  emailCustomer: string;
};

export type PaymentResponse = {
  response: Response;
  setResponse?: (response: Response) => void;
};

const PaymentResponseContext = createContext<PaymentResponse>({
  response: {
    message: "",
    title: "",
    body: [""],
    image: "",
    analyticId: "",
    requestId: "",
    signature: "",
    status: "",
    orderId: "",
    orderStatus: "",
    reference: "",
    emailCustomer: ""
  }
});

export default PaymentResponseContext;
