import React from "react";
import { StyledImage, StyledLoading, StyledContainer } from './style'

import loadingGif from "../../assets/images/image-loader.gif"

interface PropsLazyImage {
  src: string
  alt?: string
}

class LazyImage extends React.Component<PropsLazyImage> {
  state = { imageStatus: 'loading' }

  handleImageLoaded = () => {
    this.setState({ imageStatus: 'loaded' })
  }

  handleImageErrored = () => {
    this.setState({ imageStatus: 'failed' })
  }

  render() {
    const { alt, src } = this.props
    const { imageStatus } = this.state
    return (
      <StyledContainer className={`status-${imageStatus}`}>
        <StyledImage
          status={imageStatus}
          src={src}
          onLoad={this.handleImageLoaded}
          onError={this.handleImageErrored}
          alt={alt}
        />
        <StyledLoading status={imageStatus} src={loadingGif} alt={alt} />
      </StyledContainer>
    )
  }
}

export default LazyImage