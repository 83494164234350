import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Variation from '../../model/Variation'
import * as Grid from '../Grid'
import Icon from '../Icon'
import {
  Img,
  ActionLinksContainer,
  Cart,
  TextCart,
  Oval,
  BurguerContainer,
  CartContainer,
  LogoContainer,
  StyledLogoLinkContainer,
  HeaderLinks,
  StyledIcon,
  CustomColumn,
} from './style'
import CartContext from '../../context/CartContext'
import CartIcon from '../../assets/header/cart.svg'
import logoGt from '../../assets/header/logo-gt.svg'
import searchIcon from '../../assets/header/search.svg'

type Props = {
  sidebarOpen: boolean
  onSetSidebarOpen: () => void
  updateItems?: () => void
  showBanner?: boolean
  setSearchOpen?: any
  mcn?: boolean
}

function Header({ onSetSidebarOpen, sidebarOpen, setSearchOpen }: Props) {
  const { items, onSetLoaded } = useContext(CartContext)

  let totalItems = 0
  items
    ? items.map((item: Variation) => {
        const { quantity } = item
        totalItems = totalItems + quantity
      })
    : (totalItems = 0)
  const redirect = totalItems == 0 ? '/empty-cart' : '/cart'

  let incon_sidebar = sidebarOpen ? 'cancel' : 'menu-1'

  return (
    <Grid.ContainerFluid>
      <Grid.Container>
        <CustomColumn>
          <Grid.Row className="row-no-wrap">
            <BurguerContainer>
              <Link to="#" onClick={onSetSidebarOpen}>
                <Icon
                  color="white"
                  size="23px"
                  name={incon_sidebar}
                  className="kronos-icon"
                />
              </Link>
            </BurguerContainer>
            <LogoContainer>
              <StyledLogoLinkContainer to="/">
                <Img src={logoGt} />
              </StyledLogoLinkContainer>
            </LogoContainer>
            <ActionLinksContainer>
              <HeaderLinks to={"/faqs"}>
                <span>Preguntas frecuentes</span>
              </HeaderLinks>
              <HeaderLinks to={"/contact"}>
                <span>Contáctenos</span>
              </HeaderLinks>
              <img
                src={searchIcon}
                className="search"
                onClick={() => setSearchOpen(true)}
              />
              <CartContainer
                to={redirect}
                onClick={() => onSetLoaded && onSetLoaded(false)}
                className={"MKTPL_HOME_CAR"}
              >
                <img src={CartIcon} className="cart" />
                <Cart className={"MKTPL_HOME_CAR"}>
                  <TextCart className={"MKTPL_HOME_CAR"}>Carrito</TextCart>
                  <Oval className={"MKTPL_HOME_CAR"}>{totalItems}</Oval>
                </Cart>
              </CartContainer>
            </ActionLinksContainer>
          </Grid.Row>
        </CustomColumn>
      </Grid.Container>
    </Grid.ContainerFluid>
  );
}

export default Header
