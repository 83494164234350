import { SpecialtyRepresentation } from '../components/Specialties/components/SpecialtyItem'
import { ImageUrl } from '../model/ImageUrl'
import { Specialty, SpecialtyImages } from '../model/Specialty'
import { getAssetData } from './assetDataHelper'

export const toRepresentation = (specialty: Specialty): SpecialtyRepresentation => ({
  icon: specialty.icon,
  title: specialty.name,
  description: specialty.description,
  longDescription: specialty.summary,
  priority: specialty.order,
  url: specialty.type == 'external' ? specialty.url : specialty.slug,
  withRedirect: specialty.type == 'external',
  restaurants: specialty.suppliers,
  specialtyBanners: getImages(specialty.assets),
})

export const toRepresentations = (specialties: Specialty[]): SpecialtyRepresentation[] => specialties.map((specialty: Specialty) => toRepresentation(specialty))

const getImages = (specialtyImages: SpecialtyImages[]): ImageUrl => {
  if (!specialtyImages || specialtyImages.length == 0) {
    return { desktop: '', mobile: '' }
  }
  const desktopAsset = getAssetData(specialtyImages, 'desktop')
  const mobileAsset = getAssetData(specialtyImages, 'mobile')

  return { desktop: desktopAsset.url, mobile: mobileAsset.url }
}
