import { createContext } from 'react'

type Location = {
  city: string
  loadCity?: (city: string) => void
  withCoverage: boolean
  hasCoverage?: (withCoverage: boolean) => void
  navigated: boolean
  hasNavigated?: (navigated: boolean) => void
}

const LocationContext = createContext<Location>({ city: '', withCoverage: false, navigated: false })

export default LocationContext
