export type TypeStep = {
  pathname: string
  name: string
  order: number
}

export const Steps: TypeStep[] = [
  {
    pathname: '/shipping',
    name: 'Datos de envío',
    order: 0
  },
  {
    pathname: '/billing',
    name: 'Datos de facturación',
    order: 1
  },
  {
    pathname: '/payment',
    name: 'Resumen de compra',
    order: 2
  }
]
