import React from 'react'
import CartViewHandler from '../views/CartViewHandler'

type SwitchProps = {
  Component: React.ComponentType
  FallbackComponent: React.ComponentType
}

type CartFallBackProps = {
  Component: React.ComponentType
  FallbackComponent: React.ComponentType
}

const Switch = (props: SwitchProps) => {
  const { Component, FallbackComponent, ...rest } = props

  return (
    <CartViewHandler>
      {(hasCart: boolean) => {
        if (!hasCart) {
          return <FallbackComponent {...rest} />
        }
        return <Component {...rest} />
      }}
    </CartViewHandler>
  )
}

const cartFallback = (Component: React.ComponentType, FallbackComponent: React.ComponentType) => {
  return (props: CartFallBackProps) => <Switch {...props} Component={Component} FallbackComponent={FallbackComponent} />
}

export default cartFallback
