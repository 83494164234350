import React from 'react'
import Modal from 'react-modal'

import ModalContainer from '../ModalConfirmContainer'

import { modalStyles, Title, DescriptionContainer } from './style'

interface ModalProps {
  isOpen: boolean
  icon?: string
  backgroundIcon?: string
  title: string
  close: () => void
  description: string
  functionOK?: () => void
  functionClose?: () => void
}

export const ConfirmModal = ({
  isOpen,
  icon,
  backgroundIcon,
  title,
  close,
  description,
  functionOK,
  functionClose
}: ModalProps) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={close}
    style={modalStyles}
    ariaHideApp={false}
    contentLabel="Confirmar accion"
    closeTimeoutMS={330}
    shouldCloseOnOverlayClick={false}
  >
    <ModalContainer
      icon={icon}
      backgroundIcon={backgroundIcon}
      functionOK={functionOK}
      functionClose={functionClose}
    >
      {title ? <Title>{title}</Title> : ''}
      <DescriptionContainer>{description}</DescriptionContainer>
    </ModalContainer>
  </Modal>
)
