import styled from 'styled-components'
import { COLORS } from '../../styles/colors'
import sizes from '../../styles/media'

export const SpecialtyDescription = styled.div`
  text-align: center;
  color: ${COLORS.greyishBrownTwo};

  h2 {
    font-family: 'Roboto-Medium';
  }

  p {
    font-family: 'Roboto-Light';
    @media ${sizes.md} {
      max-width: 75%;
      margin: 14px auto;
    }
    @media ${sizes.lg} {
      max-width: 65%;
    }
  }
`
export const SpecialtyButton = styled.div`
  height: 54px;
  margin: 0 auto;
  max-width: 298px;
  padding: 8px 16px 8px 35px;
  border-radius: 30px;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.1);
  background-color: ${COLORS.camel};
  img {
    float:right;
    display: block;
    width: 8px;
    height: 16px;
    margin: 11px 0 11px 33px;
    object-fit: contain;
  }
`

export const TextContainer = styled.div`
  max-width: 206px;
  margin: 0 33px 0 0;
  font-family: Roboto;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.white};

  h4 {
    font-weight: bold;
    margin: 0px;
  }

  p {
    font-weight: 300;
    margin: 0px;
  }
`