import styled from 'styled-components'
import { StickyContainer } from 'react-sticky'
import Sidebar from 'react-sidebar'
import { Container } from '../../components/Grid'
import sizes from '../../styles/media'
import { ToastContainer as TC } from 'react-toastify'
import { COLORS } from '../../styles/colors'
interface Props {
  isHome?: boolean
  backgroundcolor?: string
  showBanner?: boolean
  isSticky?: boolean
}

export const CustomStickyContainer = styled(StickyContainer)`
  flex: auto;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  .customOverlay {
    background-color: transparent;
  }

  .dummy-space {
    margin-top: 127px;
    @media ${sizes.md} {
      margin-top: 8.17rem;
    }
    @media ${sizes.xl} {
      margin-top: 8.74rem;
    }

    &.no-coverage {
      @media (max-width: 575px) {
        margin-top: 133px;
      }
      @media ${sizes.md} {
        margin-top: 8.46rem;
      }
      @media ${sizes.xl} {
        margin-top: 9.03rem;
      }
    }

    &.coverage-yes {
      @media (max-width: 575px) {
        margin-top: 101px;
      }
    }

    &.no-bar {
      margin-top: 60px;
      @media ${sizes.md} {
        margin-top: 5.32rem;
      }
      @media ${sizes.xl} {
        margin-top: 5.89rem;
      }
    }
  }

  .breadcrumb-margin-top {
    min-height: 35px;
    display: flex;
    align-items: center;
    background-color: #f7f7f7;

    ol {
      margin-bottom: 0;
    }
  }
`

export const FooterWrapper = styled.footer`
  ${(props: Props) => {
    let styles = `
        box-shadow: 0 0 4px 0 rgba(117, 120, 123, 0.5);
        background-color: ${props.backgroundcolor};
        padding: 1rem 0;
    `

    return styles
  }};
`

export const HeaderWrapper = styled.header`
  box-shadow: 0 2px 4px 0 rgba(88, 88, 88, 0.3);
  z-index: 3;
  width: 100%;
  position: fixed;
  top:0px;
  transition: all 200ms ease-in;

  &.active {
    background-color: ${COLORS.white};
    box-shadow: none;
  }

  .header-wrapper {
    background-color: ${(props: Props) => props.backgroundcolor};
    padding: 0.5rem 0;
    @media ${sizes.md} {
      padding: 1rem 0;
    }
  }
`

export const Main = styled.div<Props>`
  display: block;
  flex: auto;
  background-color: ${props => props.backgroundcolor};
  filter: none;
  transition: all 200ms ease-in;

  &.blured {
    filter: blur(7px);
  }
`

export const StyledSideBar = styled(Sidebar)`
  display: flex;
  flex: 1;
`

export const Searcher = styled(Sidebar)`
  display: flex;
  flex: 1;
`

export const StyledContainer = styled(Container)`
  display: flex;
`

export const StyledAnimationLarge = styled.div`
  display: none;
  @media ${sizes.xl} {
    width: 1115px !important;
    max-width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 2rem;
    overflow: hidden;
    z-index: 5;
    display: ${(props: Props) => (props.isSticky === true ? 'none !important' : 'block')};
  }
`

export const StyledAnimationMedium = styled.div`
  display: none;
  @media ${sizes.md} {
    width: 766px !important;
    max-width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 2rem;
    overflow: hidden;
    z-index: 5;
    display: ${(props: Props) => (props.isSticky === true ? 'none !important' : 'block')};
  }

  @media ${sizes.xl} {
    display: none;
  }
`

export const StyledAnimationMobile = styled.div`
  width: 335px !important;
  max-width: 100% !important;
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 2rem;
  overflow: hidden;
  z-index: 5;
  display: ${(props: Props) => (props.isSticky === true ? 'none !important' : 'block')};

  @media ${sizes.md} {
    display: none;
  }
`

const className = 'dark-toast'
const toastClassName = 'toast-container'

export const ToastContainer = styled(TC).attrs<Props>({
  className,
  toastClassName,
})`
  .${toastClassName} {
    height: 60px;
    border-radius: 0.7em;
    padding: 10px;
    font-size: 1.2em;

    &.Toastify__toast {
      min-height: 60px;
      margin: 0.5rem auto;
      width: 85%;
      @media ${sizes.md} {
        width: 100%;
      }
    }
  }
`

type StaticBannerProps = {
  staticBannerUrl: string
}
export const StaticBanner = styled.div`
  min-height: 100px;
  width: 100%;
  background-image: url(${(props: StaticBannerProps) => props.staticBannerUrl});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  background-color: #f7f7f7;
  @media ${sizes.lg} {
    min-height: 150px;
  }
`
