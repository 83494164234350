import gql from 'graphql-tag'

const GET_RESTAURANT_QUERY = gql`
  query getSupplierBySlug($filters: SupplierFilters!) {
    supplier(filters: $filters) {
      id
      slug
      allow_coupons
      metaData {
        horario
        horarios
        sectores
        entrega
        descuento
        categoria
        en_casa
      }
      business_name
      city
      assets {
        url
        order
      }
    }
  }
`

const GET_SUPPLIERS_QUERY = gql`
  query getSuppliersQuery(
    $filters: SuppliersFilters!
    $options: SuppliersOptions!
  ) {
    suppliers(filters: $filters, options: $options) {
      nodes {
        id
        slug
        allow_coupons
        business_name
        assets {
          url
          order
        }
      }
    }
  }
`

export { GET_RESTAURANT_QUERY, GET_SUPPLIERS_QUERY }
