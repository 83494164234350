import moment from "moment";
import "moment/locale/es";

const min_day = 8;
const max_day = 10;

const date_min = addWeekdays(moment(), min_day);
const date_max = addWeekdays(moment(), max_day);

let TextDelivery =
  " Su pedido llegará entre el <b>" +
  date_min.format("D") +
  " de " +
  date_min.format("MMMM") +
  " al " +
  date_max.format("D") +
  " de " +
  date_max.format("MMMM") +
  "</b> del " +
  date_max.format("YYYY") +
  ".";

if (date_min.format("MMMM") == date_max.format("MMMM")) {
  TextDelivery =
    " Su pedido llegará entre el <b>" +
    date_min.format("D") +
    " al " +
    date_max.format("D") +
    " de " +
    date_max.format("MMMM") +
    "</b> del " +
    date_max.format("YYYY") +
    ".";
}

function addWeekdays(date: any, days: any) {
  date = moment(date);
  while (days > 0) {
    date = date.add(1, 'days');
    if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
      days -= 1;
    }
  }
  return date;
}

export default TextDelivery;
