import styled from 'styled-components'
import sizes from '../../styles/media'
import { COLORS } from '../../styles/colors'
import { Column } from '../Grid'
import Slider from 'react-slick'

export const StyledColumn = styled(Column)`
  display: flex;
`
export const StyledTitle = styled.h2`
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.08px;
  color: ${COLORS.purpleBrown};
  text-transform: uppercase;
`

export const SliderWrapper = styled.div`
  width: 100vw;
  margin-left: calc(50% - 50vw);
  padding: 1rem 0;
  @media ${sizes.sm} {
    padding: 0;
  }
  
  .slick-list{
    padding: 0 1rem;
  }

  .slick-slide{
    width: 266px;
    margin: 0;
    padding: 0 0.4rem;
    height: auto;
  }

  .slick-track{
    padding: 1rem 0;
    display: flex;
  }
`

export const StyledCarousel = styled(Slider)`
  .rec-carousel {
    background: ${COLORS.white};
    border: solid 0px #d9d9d9;
    position: relative;

    .rec-item-wrapper {
      align-items: center;
    }
  }
  .rec-slider-container {
    margin: 0;
    overflow: scroll;
  }
  .slick-list {
    padding-left: 15px !important;
  }
  .slick-slide img {
    display: inherit;
  }

  .slick-list {
  }
  .slick-slide {
    display: flex;
    width: unset;
    margin: 0;
    padding: 0;
    height: auto;
  }
  .slick-track {
    padding: 0;
    margin: 1rem 0;
  }
`

export const CardWrapper = styled.div`
  padding-right: 1rem;
  height: 100%;
`

export const StyledSubTitle = styled.h2`
  margin: 0 0 1.14rem 0;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #000000;
  @media ${sizes.sm} {
    margin: 0;
  }
`
export const Line = styled.div`
  background-color: #d8d8d8;
  height: 1px;
  width: 100%;
  margin: 1rem 0 2rem 0;
  @media ${sizes.sm} {
    margin: 0 0 2rem 0;
  }
`
