import { IPageEventSuppliers, PageEventProps } from "./IPageEvent";
import Supplier from "../../model/Supplier";

export class ImpressionEvent implements IPageEventSuppliers {
  initialize(suppliers: Supplier[]): PageEventProps {
    let impressionSuppliers: any[] = [];

    impressionSuppliers = suppliers.map((supplier: Supplier, index) => {
      return {
        name: supplier.business_name,
        city: supplier.city,
        categoria: supplier.metaData.categoria,
        recomendado: supplier.metaData.recomendado,
        position: index,
      };
    });

    return {
      event: "impressions",
      ecommerce: {
        impressions: impressionSuppliers,
      },
    };
  }
}
