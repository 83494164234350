import styled from 'styled-components'
import sizes from '../../styles/media'

export const MainBanner = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  padding-bottom: 15px;
  @media ${sizes.xl} {
    padding-bottom: 5px;
  }

  .slick-slider {
    .slick-arrow {
      display: none !important;
    }
  }
`

type ItemSliderProps = {
  image: any
}
export const ItemSlider = styled.div`
  width: 100%;
  height: 200px;
  background-image: url(${(props: ItemSliderProps) => props.image.mobile});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media ${sizes.md} {
    background-image: url(${(props: ItemSliderProps) => props.image.desktop});
    height: 250px;
  }
  @media ${sizes.lg} {
    height: 290px;
  }
  @media ${sizes.xl} {
    height: 320px;
  }
`
