import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import { ButtonContainer, NavButtonContainer, StyledNavIconButton, StyledIconButton, Title, ExternalLinkBox } from './style'
import Icon from '../Icon'

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  backgroundColor?: string
  bordercolor?: string
  color: string
  sizeicon?: string
  fontSize?: string
  mcn?: boolean
}

export const Button = ({ ref, as, mcn, ...props }: ButtonProps) => {
  const { children, backgroundColor, bordercolor, color, fontSize } = props
  return (
    <ButtonContainer {...props} backgroundcolor={backgroundColor} bordercolor={bordercolor} mcn={mcn} color={color} fontSize={fontSize}>
      <span>{children}</span>
    </ButtonContainer>
  )
}

interface NavButtonProps extends RouteComponentProps<any> {
  to: string
  children: React.ReactNode
  backgroundColor: string
  color: string
  bordercolor?: string
  third?: string
  onClick?: () => void
  id?: string
  className?: string
  fontSize?: string
  mcn? :boolean
}

export const NavButton = withRouter(({ ...props }: NavButtonProps) => {
  const { to, children, backgroundColor, color, bordercolor, third, onClick, id, className, fontSize, mcn } = props

  return (
    <NavButtonContainer
      to={to}
      backgroundcolor={backgroundColor}
      color={color}
      bordercolor={bordercolor}
      third={third}
      fontSize={fontSize}
      mcn={mcn}
      onClick={onClick}
      id={id}
      className={className}
    >
      {children}
    </NavButtonContainer>
  )
})

export const NavIconButton = withRouter(({ to, stepNumber, ...props }: any) => {
  return (
    <StyledNavIconButton {...props} to={to ? to : '#'}>
      <span>{stepNumber}</span>
    </StyledNavIconButton>
  )
})

export const IconButton = ({ ref, icon, ...props }: any) => <StyledIconButton {...props}>{icon && <Icon name={icon} />}</StyledIconButton>

type ExternalLinkProps = {
  externalURL: string
  text: string
  target: string
  backgroundColor: string
  textColor: string
  className?: string
  onClick?: () => void
}
export const ExternalLink = ({ target, externalURL, text, backgroundColor, textColor, className, onClick }: ExternalLinkProps) => (
  <ExternalLinkBox href={externalURL} target={target} backgroundColor={backgroundColor} textColor={textColor} className={className} onClick={onClick}>
    <span>{text}</span>
  </ExternalLinkBox>
)
