import React from 'react'

export type Response = {
  message: string
  title: string
  body: string[]
  image: string
  analyticId?: string
  requestId: string
  signature: string
  status: string
}

type Props = {
  children: (hasResume: boolean) => React.ReactNode
}

class ResumeViewHandler extends React.Component<Props> {
  validateURL = (str: string) => {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    ) // fragment locator
    return !!pattern.test(str)
  }

  render() {
    const { children } = this.props
    const paymentURL: string = window.localStorage.getItem('paymentURL') || '{}'

    return children(this.validateURL(paymentURL))
  }
}

export default ResumeViewHandler
