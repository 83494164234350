import styled from 'styled-components'
import { Link } from 'react-router-dom'
import sizes from '../../styles/media'
import { COLORS } from '../../styles/colors'

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-shadow: 0 2px 4px 0 rgba(137, 137, 137, 0.3);
  background-color: ${COLORS.white};
  padding: 0.5rem;
  border: 2px solid;
  border-color: transparent;
  margin: 1rem 0;
  width: 100%;
  flex: 1;
  &:hover {
    border-color: ${COLORS.camel};
    box-shadow: 0 4px 8px 0 rgba(117, 120, 123, 0.6);
    cursor: pointer;
  }

  @media ${sizes.sm} {
    padding: 1.5rem;
  }
`

export const ImgContainer = styled.div`
  margin-bottom: 1.5rem;
  display: none;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  @media ${sizes.sm} {
    display: flex;
  }
`

export const ImgContainerMobile = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  @media ${sizes.sm} {
    display: none;
  }
`

export const Img = styled.img`
  width: 100%;
  max-width: 100%;
`

export const ProductInfoContainer = styled.div`
  display: block;
  margin-bottom: 0.7rem;
  max-height: 8rem;
  overflow: hidden;
`

export const ProductPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 0.7rem;
`

export const ButtonContainer = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  letter-spacing: 1px;
  margin-bottom: 12px;
  @media ${sizes.sm} {
    display: flex;
  }
`
export const Quantity = styled.div`
  ${(props: Props) => {
    return `
      border: solid 1px ${props.backgroundcolor};
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      padding: 0 15px;
      font-weight: bold;
      `
  }};
`

interface Props {
  backgroundcolor?: string
  fontcolor?: string
}

export const QuantityButton = styled.button`
  ${(props: Props) => {
    return `
      background-color: ${props.backgroundcolor};
      color: ${props.fontcolor};
      border: solid 1px ${props.backgroundcolor};
      outline: none;
      cursor: pointer;
      width: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 4px;
      height: 100%;
    `
  }};
`

export const QuantityContainer = styled.div`
  display: flex;
  height: 28px;
  width: 75px;
`

export const Title = styled.span`
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  display: inline-block;
`

export const ItemContainer = styled.div`
  margin-bottom: 0.5rem;
  i {
    margin-right: 4px;
  }
`

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0;
  @media ${sizes.md} {
    margin-bottom: 1rem;
    flex-direction: column;
  }
`

export const Price = styled.p`
  font-family: 'Roboto-Bold';
  font-size: 13px;
  margin: 0;
  color: ${COLORS.camel};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  @media ${sizes.sm} {
    font-size: 1.6rem;
  }
`

export const TitleCenter = styled.h2`
  margin-top: 21px;
  font-size: 18px;
  text-align: center;
  @media ${sizes.xs} {
    margin-bottom: 10px;
  }
  @media ${sizes.md} {
    font-size: 24px;
    text-align: left;
    margin-bottom: 24px;
  }
`

export const CustomTitle = styled.p`
  font-weight: bold;
  word-break: break-word;
  letter-spacing: 0.5px;
  margin: 0;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: ${COLORS.purpleBrown};
`

export const ProductContainerResponsive = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  grid-auto-rows: minmax(min-content, max-content);
`

export const ProductResponsive = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(137, 137, 137, 0.3);
  background-color: white;
  padding: 0.5rem;
  height: 100%;
`

export const LinkResponsive = styled(Link)`
  color: black;
`

export const PriceContainer = styled.div`
  display: flex-end;
`

export const StyledTitle = styled.h2`
  margin: 0;
  margin-top: 1.5rem;
`
export const PriceInfo = styled.p`
  margin: 0 0 10px 0;
  color: #808083;
  font-size: 0.7rem;
  @media only screen and (max-width: 320px) {
    font-size: 0.5rem;
  }
  @media ${sizes.sm} {
    font-size: 0.8rem;
  }
`
