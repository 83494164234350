import styled from 'styled-components'
import sizes from '../../styles/media'

export const EmptyCartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.color};
  box-shadow: 0 2px 4px 0 #d9d9d9;
  margin: auto;
  min-height: 40vh;
  @media ${sizes.md} {
    min-height: 35vh;
  }
  @media ${sizes.lg} {
    min-height: 50vh;
  }
`

export const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
`

export const ImgEmptyCart = styled.img`
  margin: auto;
  width: 40%;
  height: 40%;
  @media ${sizes.md} {
    width: 50%;
    height: 50%;
  }
  @media ${sizes.lg} {
    width: 70%;
    height: 70%;
  }
`

export const ButtonContainer = styled.div`
  margin: 2rem auto;
  width: 100%;
  font-size: 10px;
  @media ${sizes.md} {
    margin: 2rem 0 2rem auto;
    font-size: 12px;
    width: 25%;
  }
  @media ${sizes.lg} {
    font-size: 14px;
  }
`

export const Title = styled.span`
  display: flex;
  margin: 1rem auto 0;
  font-weight: bold;
  font-size: 14px;
  color: #000000;
  @media ${sizes.md} {
    font-size: 18px;
  }
  @media ${sizes.lg} {
    font-size: 24px;
  }
`
