import { createClient } from '../client'
import { REDEEM_COUPON_QUERY } from '../components/ProductsList/query'
import moment from 'moment'

const client = createClient()

export async function validateCoupon(coupon: string, document: string) {
  const { data } = await client.query({
    query: REDEEM_COUPON_QUERY,
    variables: {
      filters: {
        code: coupon
      }
    },
    fetchPolicy: 'network-only'
  })
  if (!data) {
    return {
      valid: false,
      message: 'Código no existe'
    }
  }

  if (data.coupon.identification === document) {
    if (data.coupon.expires) {
      const dateCoupon = moment(data.coupon.expires_to, 'YYYY-MM-DD')
      const now = moment()
      const numberDays = dateCoupon.diff(now, 'days')

      if (numberDays < 0) {
        return {
          valid: false,
          message:
            'El código ingresado expiró el ' + dateCoupon.format('DD/MM/YYYY'),
          redeem: data.coupon.redeem,
          expires_to: data.coupon.expires_to,
          value: data.coupon.value
        }
      }
    }

    if (data.coupon.redeem) {
      return {
        valid: false,
        message: 'El código ya fue utilizado',
        redeem: data.coupon.redeem
      }
    }

    return {
      valid: true,
      message: 'Código válido',
      value: data.coupon.value,
      id: data.coupon.id,
      redeem: data.coupon.redeem
    }
  }

  return {
    valid: false,
    message:
      'El código no coincide con la cédula asignada del Titular de la tarjeta',
    redeem: data.coupon.redeem
  }
}
