import React, { useContext, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { Query, QueryResult } from 'react-apollo'
import gql from 'graphql-tag'
import { SpecialtyData } from '../../model/Specialty'
import Layout from '../../components/Layout'
import { Hr } from '../../components/Footer/style'
import RelatedRestaurants from '../../components/RelatedRestaurants'
import { SpecialtyRepresentation } from '../../components/Specialties/components/SpecialtyItem'
import Loader from '../../components/Loader'
import { useIsMobile } from '../../helpers/resize'
import { toRepresentation } from '../../helpers/transformSpecialtyIntoRepresentation'
import { SpecialtyButton, SpecialtyDescription, TextContainer } from './style'
import LocationContext from '../../context/LocationContext'
import iconPath from '../../assets/specialty/icon-path.svg'
import { Link } from 'react-router-dom'

interface RouterParams extends RouteComponentProps<{ slug?: string }> {}

const GET_SPECIALTY_BY_SLUG = gql`
  query getSpecialtyBySlug($slug: String!) {
    specialty(slug: $slug) {
      name
      status
      order
      type
      summary
      description
      slug
      icon
      url
      meta_data {
        cta {
          cta_main_text
          cta_second_text
          cta_url
        }
      }
      suppliers {
        id
        slug
        allow_coupons
        business_name
        legal_name
        city
        ruc
        metaData {
          categoria
          recomendado
          descuento
          en_casa
          descripcion
          horarios
        }
        assets {
          url
          order
        }
      }
      assets {
        type
        platform_type
        url
      }
    }
  }
`

export default function SpecialtyView({
  match: {
    params: { slug },
  },
}: RouterParams) {
  const { city } = useContext(LocationContext)
  const initialMql = window.matchMedia(`(max-width: 767px)`)
  const isMobile = useIsMobile(initialMql.matches)

  return (
    <Query<SpecialtyData> query={GET_SPECIALTY_BY_SLUG} variables={{ slug }}>
      {({ loading, error, data }: QueryResult<SpecialtyData>) => {
        if (loading) {
          return <Loader />
        }

        if (error) {
          return <p>Error</p>
        }

        if (!data) {
          return <p>No se encuentra la especialidad solicitada.</p>
        }

        const { specialty } = data

        const callToAction = specialty.meta_data && specialty.meta_data.cta

        const specialtyRepresentation: SpecialtyRepresentation = toRepresentation(specialty)

        const restaurants = city ? specialtyRepresentation.restaurants.filter(restaurant => restaurant.city == city) : specialtyRepresentation.restaurants

        return (
          <Layout
            staticBannerUrl={isMobile ? specialtyRepresentation.specialtyBanners.mobile : specialtyRepresentation.specialtyBanners.desktop}
            breadcrumbLinks={[{ label: 'Inicio', to: '/' }, { label: specialtyRepresentation.title }]}
          >
            <SpecialtyDescription>
              <h1>{specialtyRepresentation.title}</h1>
              <p dangerouslySetInnerHTML={{ __html: specialtyRepresentation.longDescription }} />
            </SpecialtyDescription>
            {callToAction && (
              <Link to={{ pathname: callToAction.cta_url }} target="_blank">
                <SpecialtyButton>
                  <img src={iconPath} />
                  <TextContainer>
                    <h4>{callToAction.cta_main_text}</h4>
                    <p>{callToAction.cta_second_text}</p>
                  </TextContainer>
                </SpecialtyButton>
              </Link>
            )}
            <Hr />
            <RelatedRestaurants restaurants={restaurants} title="Restaurantes participantes" />
          </Layout>
        )
      }}
    </Query>
  )
}
