import { COLORS } from '../styles/colors'

export function Validate(value: string, type?: string, len?: number, hide?: string, required = true) {
  if (value.length > (len ? len : 60)) {
    return {
      error: true,
      message: 'Ha excedido el número de caracteres.',
      hide: false,
      value: value,
      fillColor: COLORS.white
    }
  }

  if (value.length == 0 && required) {
    return {
      error: true,
      message: 'Campo obligatorio, no puede estar vacío.',
      hide: false,
      value: value,
      fillColor: COLORS.white
    }
  }

  if (type == 'RUC') {
    const is_numeric = validateOnlyNumeric(value)
    if (is_numeric.error) {
      return is_numeric
    }

    if (value.length != 13) {
      return {
        error: true,
        message: 'No cumple con el número de dígitos requeridos (13).',
        hide: false,
        value: value,
        fillColor: COLORS.white
      }
    }
  }

  if (type == 'CI') {
    const is_numeric = validateOnlyNumeric(value)
    if (is_numeric.error) {
      return is_numeric
    }

    if (value.length != 10) {
      return {
        error: true,
        message: 'No cumple con el número de dígitos requeridos (10).',
        hide: false,
        value: value,
        fillColor: COLORS.white
      }
    }

    if (!isValid(value)) {
      return {
        error: true,
        message: 'La identificación no es válida',
        hide: false,
        value: value,
        fillColor: COLORS.white,
      }
    }
  }

  if (type === 'email') {
    value.toLowerCase()
    if (
      !/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([arpa|root|aero|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|um|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw]{2,4})(\]?)$/.test(
        value
      )
    ) {
      return {
        error: true,
        message: 'Email inválido',
        hide: false,
        value: value,
        fillColor: COLORS.white
      }
    }
  }

  if (type === 'characters') {
    if (!/^[ñÑáéíóúÁÉÍa-zA-Z ]*$/g.test(value)) {
      return {
        error: true,
        message: 'Solo se permiten letras en este campo',
        hide: false,
        value: value,
        fillColor: COLORS.white
      }
    }
  }

  if (type === 'numeric') {
    return validateOnlyNumeric(value)
  }

  if (type == 'phone') {
    const validateString = validateOnlyNumeric(value)
    if (validateString.error) return validateString

    if (value.length > 0 && value.length != 9) {
      return {
        error: true,
        message: 'No cumple con el número de dígitos requeridos (9).',
        hide: false,
        value: value,
        fillColor: COLORS.white
      }
    }
  }

  if (type == 'mobile') {
    const validateString = validateOnlyNumeric(value)
    if (validateString.error) return validateString

    if (value.length > 0 && value.length != 10) {
      return {
        error: true,
        message: 'No cumple con el número de dígitos requeridos (10).',
        hide: false,
        value: value,
        fillColor: COLORS.white
      }
    }
  }
  return {
    error: false,
    message: '',
    value: value,
    hide: false,
    fillColor: COLORS.white
  }
}

function validateOnlyNumeric(value: string) {
  if (/[^0-9]/g.test(value)) {
    return {
      error: true,
      message: 'Solo se permiten números en este campo',
      hide: false,
      value: value,
      fillColor: COLORS.white
    }
  }

  return {
    error: false,
    message: '',
    value: value,
    hide: false,
    fillColor: COLORS.white
  }
}


function isValid(identification: string) {
  let total = 0
  const idLength = identification.length
  const longcheck = idLength - 1

  if (idLength > 0 && idLength === 10) {
    for (let i = 0; i < longcheck; i++) {
      if (i % 2 === 0) {
        let aux = parseInt(identification.charAt(i)) * 2
        if (aux > 9) {
          aux -= 9
        }
        total += aux
      } else {
        total += parseInt(identification.charAt(i))
      }
    }

    total = total % 10 ? 10 - (total % 10) : 0

    if (parseInt(identification.charAt(idLength - 1)) == total) {
      return true
    }
    return false
  }

  return false
}
