import styled from 'styled-components'
import { COLORS } from '../../styles/colors'
import Icon from '../Icon'

interface Props {
  backgroundColor?: string
}

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 15px 5px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
`

export const Body = styled.div``

export const CloseButton = styled.button`
  background-color: #ff2d2d;
  border-radius: 15px;
  border: 0;
  -webkit-appearance: none;
  cursor: pointer;
  padding: 2px 0;
  left: -8px;
  position: absolute;
`

export const CloseIcon = styled(Icon)`
  color: white;
  font-size: 20px;
`

export const Wrapper = styled.div`
  background: white;
  padding-top: 1rem;
`

export const ConfirmContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  background: #f5f5f5;
  position: relative;
  padding: 1rem;

  &:before {
    content: '';
    position: absolute;
    top: -10px;
    width: 22px;
    height: 22px;
    transform: rotate(-45deg);
    background-color: ${COLORS.white};
  }
`
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
`

export const CircleIcon = styled.div<Props>`
  height: 50px;
  width: 50px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : COLORS.primary};
  border-radius: 50px;
  padding: 1rem;
  display: flex;
  justify-content: center;
`
export const Spacer = styled.span`
  width: 30px;
  height: 30px;
  display: block;
`
