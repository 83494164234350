export const customStyle = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: "2px",
    height: "40px",
    "min-height": "40px"
  }),
  container: (provided: any, state: any) => ({
    ...provided
  }),
  valueContainer: (provided: any) => ({
    ...provided
  })
};

export const errorStyle = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: "2px",
    height: "40px",
    "min-height": "40px",
    border: "solid 1px red"
  }),
  container: (provided: any, state: any) => ({
    ...provided
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    overflowX: "hidden",
    overflowY: "hidden"
  })
};

export const identificationTypeOptions = [
  { value: "CI", label: "Cédula de Identidad" },
  { value: "RUC", label: "RUC" },
  { value: "PPN", label: "Pasaporte" }
];
