import React from 'react'

import { StyledContainer, Title, SocialNetworks, SocialButton } from './style'

import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'
import LazyImage from '../LazyImage'

import fbLogo from '../../assets/social/facebook/logo.svg'
import twLogo from '../../assets/social/twitter/logo.svg'
import ldLogo from '../../assets/social/linkedin/logo.svg'
import wtLogo from '../../assets/social/whatsapp/logo.svg'

type SocialContainerProps = {
  textMessage: string
}
const SocialContainer = (props: SocialContainerProps) => {
  const textToShare = `Tu también puedes disfrutar de tus restaurantes favoritos en casa. Juntos podemos apoyar a nuestros restaurantes.`
  return (
    <StyledContainer>
      <Title>{props.textMessage}</Title>
      <SocialNetworks>
        <FacebookShareButton url={'https://delivery.grandetable.com.ec/'} quote={textToShare} className="share-button-container">
          <SocialButton>
            <LazyImage src={fbLogo}></LazyImage>
          </SocialButton>
        </FacebookShareButton>
        <TwitterShareButton url={'https://delivery.grandetable.com.ec/'} title={textToShare} className="share-button-container">
          <SocialButton>
            <LazyImage src={twLogo}></LazyImage>
          </SocialButton>
        </TwitterShareButton>
        <LinkedinShareButton url={'https://delivery.grandetable.com.ec/'} title={textToShare} source={textToShare} summary={textToShare} className="share-button-container">
          <SocialButton>
            <LazyImage src={ldLogo}></LazyImage>
          </SocialButton>
        </LinkedinShareButton>
        <WhatsappShareButton url={'https://delivery.grandetable.com.ec/'} title={textToShare} className="share-button-container">
          <SocialButton>
            <LazyImage src={wtLogo}></LazyImage>
          </SocialButton>
        </WhatsappShareButton>
      </SocialNetworks>
    </StyledContainer>
  )
}

export default SocialContainer
