import styled from 'styled-components'
import sizes from '../../styles/media'
import { Link } from 'react-router-dom'
import { Column } from '../../components/Grid'
import { COLORS } from '../../styles/colors'

export const Hr = styled.hr`
  width: 100%;
  border: none;
  border-top: solid 1px rgba(151, 151, 151, 0.5);
  margin: 1rem 0;
`

export const ContainerLeft = styled.div`
  width: 80%;
`

export const FlexCol = styled.div`
  display: none;
  @media ${sizes.md} {
    display: contents;
    flex-direction: column;
    flex: auto;
    justify-content: center;
  }
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const FlexResponsiveRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media ${sizes.md} {
    display: none;
  }
`

export const ContainerContactUs = styled(Column)`
  display: none;
  @media ${sizes.md} {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
  }
`

export const StyledResponsiveContactUs = styled(Column)`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 15px auto;

  @media ${sizes.md} {
    margin: 0;
  }
  .contactus {
    display: inline-block;
  }
`

export const ContainerContactUsResponsive = styled.div`
  display: none;
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  @media ${sizes.xs} {
    width: 15rem;
  }
  @media ${sizes.sm} {
    width: 50%;
  }
  @media ${sizes.md} {
    display: none;
  }
`

export const ImgCommu = styled.img`
  /* margin: auto 0; */
  width: 24.9px;
  height: 27.8px;
  @media ${sizes.md} {
    margin-left: 2px;
  }
`

export const ImgEstacionCompra = styled.img`
  max-width: 11rem;
  width: 100%;
  margin-top: 10px;
  @media ${sizes.md} {
    margin-top: 0;
  }
`

export const BlueContainerText = styled.div`
  color: white;
  display: flex;
`

export const SpanRight = styled.span`
  font-size: 12px;
  text-align: right;
  margin: auto 0 0;
`

export const SpanLeft = styled.span`
  font-size: 12px;
  text-align: left;
`

export const SpanConditionUp = styled.div`
  display: flex;
  flex: 1;
  font-size: 0.8125rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  text-align: right;
  flex-direction: column;
  font-size: 0.8rem;
  justify-content: center;
  align-items: center;
  @media ${sizes.md} {
    &.mobile {
      display: none;
    }
    align-items: flex-end;
    justify-content: flex-end;
  }
`

export const SpanConditionUDown = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  text-align: right;
  @media ${sizes.md} {
    display: none;
  }
`

export const SpanCopyright = styled.span`
  color: ${COLORS.white};
  font-size: 0.8rem;
  text-decoration: none;
  margin: 0 1rem;
  text-align: center;
  @media ${sizes.md} {
    text-align: right;
  }
`

export const ContainerLinks = styled(Link)`
  color: ${COLORS.white};
  font-size: 0.8rem;
  text-decoration: none;
  margin: 0.5rem;
`

export const ContainerTerms = styled.a`
  color: ${COLORS.white};
  font-size: 0.8rem;
  text-decoration: none;
  margin: 0 1rem;
  margin-top: 10px;
  text-decoration: underline;
  @media ${sizes.md} {
    margin-top: 0;
  }
`

export const StyledItemContainer = styled.div`
  flex: auto;
  display: flex;
  align-items: center;
  margin: 0.5rem;
  justify-content: center;
  @media ${sizes.xs} {
    justify-content: left;
    margin: 0;

    &:first-child {
      flex: 1;
    }

    &:nth-child(2) {
      flex: 4;
    }
  }

  @media ${sizes.sm} {
    &:first-child {
      flex: 2;
    }

    &:nth-child(2) {
      flex: 4;
    }
  }
`

export const LineSpacer = styled(Hr)`
  display: none;
  @media ${sizes.md} {
    display: block;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  @media ${sizes.md} {
    justify-content: space-between;
  }
`
