import React from 'react'
import { ExternalLink } from '../../../../components/Buttons'
import { GRANDE_TABLE_PORTAL_URL } from '../../../../config'
import { COLORS } from '../../../../styles/colors'
import { ContainerForm, Hr } from '../../style'
import { BenefitForm, TwoColorMessage } from './style'

type TestBenefitProps = {
  chance: number
  mcn?: boolean
}
type ExcessMessageProps  = {
  mcn?: boolean
}

function TestMessage(props: TestBenefitProps) {
  const { chance, mcn } = props
  return (
    <>
      <p className="counter-title compact">Bienvenido a Grande Table Delivery</p>
      {chance == 1 && (
        <TwoColorMessage className="remember-msg compact">
          Por ser socio Diners Club tienes <span>2 accesos de cortesía</span> para hacer {!mcn ? 'tus' : 'sus'} pedidos con descuentos y delivery gratuito.
        </TwoColorMessage>
      )}
      {chance == 2 && (
        <>
          <TwoColorMessage className="remember-msg compact">
            Recuerda que este es tu último <span>acceso de cortesía.</span>
          </TwoColorMessage>
          <div className="access-note">
            Si quieres seguir recibiendo este y más beneficios adquiere tu membresía <b>Grande Table.</b>
          </div>
        </>
      )}
    </>
  )
}

function ExcessMessage(props: ExcessMessageProps) {
  const { mcn } = props

  return (
    <>
      <p className="counter-title excess">{!mcn ? ' Has excedido de número de compras que tenías disponibles':
       ' Ha excedido el número de compras disponibles'}</p>
      <Hr />
      <TwoColorMessage className="remember-msg">
        Para poder finalizar tu compra adquiere
        <br />
        tu membresía <span>Grande Table</span> ahora.
      </TwoColorMessage>
      <ExternalLink
        target="_blank"
        externalURL={`${GRANDE_TABLE_PORTAL_URL}/membership`}
        text="Adquirir Membresía"
        backgroundColor="#000000"
        textColor={COLORS.teeth}
        className="fixed-width"
      />
    </>
  )
}

export default function TestBenefitMessage(props: TestBenefitProps) {
  const { chance, mcn } = props
  return (
    <BenefitForm>
      <ContainerForm backgroundcolor={COLORS.white} className={`content " ${chance < 3 ? 'compact' : ''}`}>
        {chance < 3 && <TestMessage chance={chance} mcn={mcn} />}
        {chance >= 3 && (<ExcessMessage mcn={mcn} />)}
      </ContainerForm>
    </BenefitForm>
  )
}
