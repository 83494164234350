import styled from 'styled-components'
import { COLORS } from '../../styles/colors'

export const Comments = styled.div`
  background: none;
  cursor: default;
  color: ${COLORS.black};
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  margin-top: 25px;
  margin-bottom: 10px;
`
