import gql from 'graphql-tag'

const GET_VARIATIONS_QUERY = gql`
  query getVariationsAtHome($supplierId: String!) {
    variationsAtHome(supplierId: $supplierId) {
      id
      reference
      supplierCost
      price
      options {
        name
        feature {
          name
        }
      }
    }
  }
`

export { GET_VARIATIONS_QUERY }
