import styled from 'styled-components'
import sizes from '../../../../styles/media'
import { Column } from '../../../../components/Grid'
import { COLORS } from '../../../../styles/colors'

export const ExperienceWrapper = styled.div`
  opacity: 0;
  max-height: 0;
  transition: all 300ms ease-in-out;
  .terms-container {
    transition: all 300ms ease-in-out;
    margin: 0 4.5rem 0;
  }
  &.active {
    opacity: 1;
    max-height: 1000px;
    .terms-container {
      transition: all 300ms ease-in-out;
      margin: 1rem 4.5rem 0;
    }
    label {
      display: flex;
      align-items: center;
      input {
        margin-right: 1rem;
      }
      span {
        padding: 1px 0 0;
      }
    }
  }
`

export const ExperienceTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header {
    display: flex;
    align-items: flex-start;
    img {
      width: 2.9;
      margin-right: 1.6rem;
    }
  }
`

export const TermsContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  label {
    color: #353535;
    font-size: 14px;
  }
`

export const ExperienceInfo = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    margin: 0 0 0.3rem;
    font-size: 1.1rem;
    font-family: 'Roboto-Bold';
    line-height: 1.25;
    letter-spacing: normal;
    color: #000000;
  }

  p {
    margin: 0 0 22px;
    font-size: 1rem;
    line-height: 1.43;
    letter-spacing: normal;
    font-family: 'Roboto-Light';
  }
`

export const Checkbox = styled.div`
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  border-radius: 2px;
  border: solid 1px;
  border-color: #979797;
  display: block;
  margin-right: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  &.checked {
    background-color: ${COLORS.camel};
    border-color: ${COLORS.camel};
  }
`

export const DateContainer = styled.div`
  width: 100%;
  display: flex;
  opacity: 0;
  max-height: 0px;
  flex-direction: column;
  border-top: 1px solid #d2d2d0;
  transition: all 300ms ease-in-out;
  @media ${sizes.md} {
    flex-direction: row;
  }
  &.active {
    margin-top: 1.5rem;
    padding-top: 2rem;
    opacity: 1;
    max-height: 1000px;
  }
`

export const StyledColumn = styled(Column)`
  padding-left: 0;
  padding-right: 0;

  @media ${sizes.md} {
    &.right {
      padding-left: 15px;
    }
    &.left {
      padding-right: 15px;
    }
  }
`

export const TimePicker = styled.div`
  position: relative;
  /*datepicker */
  .react-datepicker__time-container {
    width: 100%;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 100%;
  }
  .react-datepicker__time-container .react-datepicker__time {
    background: transparent;
  }
  .react-datepicker {
    font-family: 'Roboto';
    width: 100%;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    font-size: 0.9rem;
  }
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    display: block;
    width: 100%;
  }
  .react-datepicker__time {
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__header {
    display: none;
  }

  .react-datepicker-popper {
    width: 100%;
    @media ${sizes.md} {
      width: 96%;
    }
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list {
    height: auto;
    max-height: calc(195px + (1.7rem / 2));
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: baseline;
    &:hover {
      background-color: #deebff;
    }
    padding-left: 1rem;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--disabled {
    display: none !important;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: #2784ff;
    &:hover {
      background-color: #2784ff;
    }
  }
`
export const Placeholder = styled.div`
  position: absolute;
  bottom: 5px;
  right: 0;
  height: 38px;
  display: flex;
  width: 40px;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #e6e6e6;
  pointer-events: none;
`
