import styled from 'styled-components'
import { COLORS } from '../../../../styles/colors'
import sizes from '../../../../styles/media'

export const TransactionBox = styled.div`
  margin-left: -25px;
  margin-right: -25px;
  color: ${COLORS.dark};
  @media ${sizes.md} {
    margin: -30px 75px;
  }
  @media ${sizes.xl} {
    hr {
      width: 70%;
    }
  }

  a.single-bottom {
    margin-bottom: 30px;
    @media ${sizes.md} {
      width: 60%;
      margin: 0 auto 30px;
    }
    @media ${sizes.lg} {
      width: 50%;
    }
    @media ${sizes.xl} {
      width: 40%;
    }
  }
`

export const Name = styled.div`
  font-family: 'Roboto-Bold';
  font-size: 20px;
  margin-bottom: 10px;
  @media ${sizes.md} {
    font-size: 25px;
  }
`

export const TransactionMessage = styled.div`
  font-family: 'Roboto-Bold';
  font-size: 15px;
  line-height: 1.25;
  margin-bottom: 30px;
  @media ${sizes.md} {
    font-size: 20px;
  }
`

export const OrderMessage = styled.div`
  margin-bottom: 20px;
  border-radius: 12px;
  background-color: rgba(255, 240, 217, 0.2);
  padding: 10px;
  font-size: 12.5px;
  line-height: 1.56;
  @media ${sizes.md} {
    font-size: 16px;
    padding: 20px 0;
    margin: 0 -50px 20px;
  }
  @media ${sizes.lg} {
    margin: 0 10px 20px;
  }
  @media ${sizes.xl} {
    margin: 0 80px 20px;
  }

  .colored {
    font-size: 15px;
    color: ${COLORS.camel};
    @media ${sizes.md} {
      font-size: 22px;
    }
  }

  div.resume {
    margin-bottom: 0;
    padding-bottom: 0;

    @media ${sizes.md} {
      padding: 1rem 5rem;
    }
    @media ${sizes.lg} {
      padding: 0 10rem;
    }
    @media ${sizes.xl} {
      margin: 10px auto 0;
    }

    span.resume {
      font-size: 10.5px;
      @media ${sizes.md} {
        font-size: 14px;
      }
    }
  }
`

export const ConfirmationMailMessage = styled.div`
  font-size: 12px;
  padding-bottom: 30px;
  @media ${sizes.md} {
    font-size: 14px;
  }

  span {
    font-weight: 500;
  }
`

export const MembershipBox = styled.div`
  margin-bottom: 30px;
  @media ${sizes.xl} {
    width: 70%;
    margin: 0 auto 30px;
  }

  .enjoy-text {
    margin-top: 0;
    line-height: 1.79;
    font-size: 12px;
    @media ${sizes.md} {
      font-size: 13.5px;
    }
    @media ${sizes.xl} {
      padding-bottom: 20px;
    }
  }

  .fixed-width {
    @media ${sizes.md} {
      width: 75%;
      margin: 0 auto;
    }
    @media ${sizes.lg} {
      width: 50%;
    }
  }
`

export const Invitation = styled.div`
  font-family: 'Roboto-Bold';
  line-height: 1.39;
  @media ${sizes.md} {
    font-size: 16px;
  }
`

export const BenefitsBox = styled.ul`
  padding: 0 0 20px;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media ${sizes.xl} {
    padding-bottom: 30px;
  }

  li {
    border-left: 1px solid ${COLORS.brownGray};
    flex-basis: 33%;

    &:first-child {
      border-left: none;
    }
  }
`

export const Benefit = styled.div`
  padding: 0 18px;
  font-size: 11px;
  line-height: 1;
  @media ${sizes.md} {
    font-size: 12px;
  }
  @media ${sizes.xl} {
    font-size: 14px;
  }

  img {
    width: 50px;
    margin-bottom: 6px;
    @media ${sizes.md} {
      width: 54px;
    }
    @media ${sizes.xl} {
      width: 74px;
    }
  }
`

export const ExtraInfo = styled.div`
  font-size: 11.5px;
  line-height: 1.25;
  @media ${sizes.md} {
    font-size: 12px;
  }
  @media ${sizes.xl} {
    font-size: 14px;
  }
`
