import React, { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import LazyImage from '../LazyImage'
import ReactTooltip from 'react-tooltip'
import {
  SupplierContainer,
  ImgContainer,
  SupplierInfoContainer,
  CustomTitle,
  CoverContainer,
  HomeTag,
  DiscountLabel,
  //Spacer,
  Layer
  //CloseTag,
  //ScheduleTag
} from './style'
import {
  isOpenSoonToday,
  obtainSchedule,
  getMessage,
  isOpenTomorrow,
  openRestaurant
} from '../../helpers/openRestaurant'
import Supplier from '../../model/Supplier'
import homeIcon from '../../assets/at-home/tag.svg'
import scheduleIcon from '../../assets/images/schedule-gray.svg'

interface Props extends RouteComponentProps<any> {
  supplier: Supplier
  type?: string
  mcn?: boolean
}

export const SupplierCard = ({ history, supplier, type, mcn }: Props) => {
  const redirectSupplier = (slug: string) => history.push(`/restaurant/${slug}`)
  const [open, setOpen] = useState(true)
  const [openSoon, setOpenSoon] = useState(false)
  const [openTime, setOpenTime] = useState('')
  const { business_name, assets, slug } = supplier
  const IN_HOUSE_RESTAURANT="Restaurante en casa";
  const ORDER_TOMORROW="Pídelo para mañana";
  const displayAssets =
    assets && assets.length > 1
      ? assets.sort((a: any, b: any) => {
          return a.order > b.order ? 1 : -1
        })
      : assets
  const whenDidItOpen = (schedule: string) => {
    const scheduleData = schedule ? JSON.parse(schedule.toString()) : ''
    let isTomorrow: Boolean = false
    const today = new Date()
    let message = ''
    let dayNumber = today.getDay()
    let evaluatedDaysCounter = 0
    while (!isTomorrow && evaluatedDaysCounter <= 6) {
      let currentSchedule = obtainSchedule(scheduleData, dayNumber);
      message = ORDER_TOMORROW
      isTomorrow = true
      if( currentSchedule && currentSchedule.active  && currentSchedule.from.label !== undefined && currentSchedule.to.label  !== undefined){
        let isOpenSoon = isOpenSoonToday(currentSchedule);
        if (dayNumber == today.getDay() && isOpenTomorrow(currentSchedule)) {
          break
        }
        setOpenSoon(isOpenSoon);
        message = getMessage(dayNumber, isOpenSoon, currentSchedule);
      }else{
        dayNumber = dayNumber === 6 ? 0 : dayNumber + 1;
        isTomorrow = false;
        evaluatedDaysCounter++;
      }
    }
    return evaluatedDaysCounter > 6 ? 'No Disponible': message
  }
  useEffect(() => {
    setOpen(
      openRestaurant(
        supplier.metaData && supplier.metaData.horarios
          ? supplier.metaData.horarios
          : null
      )
    )
    setOpenTime(
      whenDidItOpen(
        supplier.metaData && supplier.metaData.horarios
          ? supplier.metaData.horarios
          : null
      )
    )
  }, [])
  return (
    <SupplierContainer
      onClick={() => redirectSupplier(slug)}
      className={
        type && type === 'slider' ? 'MKTPL_HOME_SELEC mt' : 'MKTPL_HOME_SELEC'
      }
      data-supplier={business_name}
    >
      <CoverContainer
        className={open || openSoon ? '' : 'closed'}
      >
        <LazyImage
          src={
            displayAssets && displayAssets.length > 1
              ? displayAssets[1].url
              : 'https://storage.googleapis.com/gaia-suppliers-points-staging-bucket/f077a0d8-9778-4927-bf2d-8accbcd408a4/617702e8-c464-42ce-a588-786bc8a67e44.jpg'
          }
          alt="Resstaurant"
        />
        {!open && <Layer />}
      </CoverContainer>
      {supplier.metaData &&
        supplier.metaData.descuento &&
        supplier.metaData.descuento > 0 && (
          <DiscountLabel className={open ? '' : 'closed'}>{`-${supplier.metaData.descuento}%`}</DiscountLabel>
        )}
      <ImgContainer
        className={open || openSoon ? '' : 'closed'}
      >
        <LazyImage
          src={
            displayAssets && displayAssets.length > 0
              ? displayAssets[0].url
              : 'https://storage.googleapis.com/gaia-assets-bucket/dba24fe0-3151-4063-927d-178b881ef7aa/759df298-d1d5-41bf-9a68-24395e9089df.jpg'
          }
          alt="Resstaurant"
        />
      </ImgContainer>
      <SupplierInfoContainer>
        <CustomTitle>{business_name}</CustomTitle>
        {!open ? (
          <HomeTag className="closed">
            {openTime} <img src={scheduleIcon} />
          </HomeTag>
        ) : supplier.metaData &&
          supplier.metaData.en_casa &&
          supplier.metaData.en_casa === 'si' ? (
          <>
            <HomeTag
              data-tip={`${!mcn? 'Pide': 'Pida'} esta experiencia con 24 horas <br />de anticipación`}
              data-for="coupon"
            >

              {openTime == '' ? (
                <> {IN_HOUSE_RESTAURANT} <img src={homeIcon} /></>
              ) : (
                <>{openTime}<img src={scheduleIcon} /></>
              )}
            </HomeTag>
            <ReactTooltip
              id="coupon"
              place="top"
              backgroundColor={'#000000'}
              effect="solid"
              multiline={true}
            ></ReactTooltip>
          </>
        ) : (
          <></>
        )}
      </SupplierInfoContainer>
    </SupplierContainer>
  )
}

export default withRouter(SupplierCard)
