import { useState, useEffect } from "react";

export function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return width;
}

export function useIsMobile(initialIsMobile: boolean) {
  const [isMobile, setIsMobile] = useState(initialIsMobile);

  useEffect(() => {
    const handleResize = () => {
      const mql = window.matchMedia(`(max-width: 767px)`);
      setIsMobile(mql.matches);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return isMobile;
}

export function useIsIpad(initialIsIpad: boolean) {
  const [isIpad, setIsIpad] = useState(initialIsIpad);

  useEffect(() => {
    const handleResize = () => {
      const mql = window.matchMedia(`(max-width: 1024px)`);
      setIsIpad(mql.matches);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return isIpad;
}