import gql from 'graphql-tag'

const GET_PRODUCTS_QUERY = gql`
  query getProductsQuery(
    $filters: ProductsFilters!
    $options: ProductsOptions!
  ) {
    products(filters: $filters, options: $options) {
      nodes {
        id
        name
        slug
        assets {
          url
          order
        }
        categories {
          id
          slug
          name
          priority
        }
        variations {
          id
          price
          regularPrice
        }
        supplier {
          business_name
        }
        minPrice
        maxRegularPrice
        priority
      }
    }
  }
`

const GET_CITIES = gql`
  query getCities($filters: CategoriesFilters!, $options: CategoriesOptions!) {
    categories(filters: $filters, options: $options) {
      nodes {
        id
        name
      }
    }
  }
`

export { GET_PRODUCTS_QUERY, GET_CITIES }
