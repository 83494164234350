import styled from 'styled-components'
import { COLORS } from '../../../../styles/colors'

export const CounterBox = styled.div`
  text-align: center;
  color: #ffffff;
  margin: -32px auto 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  span {
    z-index: 2;
    font-family: 'Roboto-Bold';
    &.colored {
      color: ${COLORS.camel};
      padding-right: 2px;
    }
  }
`
