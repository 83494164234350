export const COLORS = {
  primary: '#1428a0',
  ligthGray: '#f4f4f4',
  ligthGray_1: '#d8d8d8',
  purpleBrown: '#231f20',
  gray: '#bababa',
  white: '#ffffff',
  camel: '#c59d5f',
  dark: '#242424',
  greyishBrownTwo: '#414141',
  grayBold: '#414141',
  deepGray: '#818181',
  veryLightPink: '#d8d8d8',
  veryLightPinkTwo: '#bbbbbb',
  veryLightPinkThree: '#eaeaea',
  white_1: '#fff5f5',
  teeth: '#f7f7f7',
  brownGray: '#8c8c8c',
  red: '#FF4237',
  black: '#000000',
  darkSalmon: '#c55f5f',
  armyGreen: '#2a1e0b',
}
