import styled from 'styled-components'
import { COLORS } from '../../../../styles/colors'
import sizes from '../../../../styles/media'

export const ValidatorForm = styled.div`
  .content {
    padding: 30px 32px 20px 33px;
    margin-bottom: 0.2rem;

    p.validator-message {
      font-size: 14px;
      font-family: 'Roboto-Light';
      margin: 0;
      padding-bottom: 30px;
    }

    &:focus {
      border: solid 1px #2684ff;
    }
  }
`

export const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  @media ${sizes.md} {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: space-between;
  }

  .input-side {
    flex-basis: 100%;
    @media ${sizes.md} {
      flex-basis: 50%;
    }

    label {
      font-family: 'Roboto-Bold';
      padding-bottom: 10px;
    }

    input {
      margin-bottom: 0;
    }

    .column-identification {
      @media (max-width: 767px) {
        margin-top: 5%;
      }
    }
  }

  .button-side {
    flex-basis: 100%;
    justify-content: center;
    @media ${sizes.md} {
      flex-basis: 50%;
    }

    .validate-btn {
      margin: 0;
      padding: 13px 0;
      max-height: 40px;
      @media ${sizes.md} {
        width: 80%;
      }
      @media ${sizes.lg} {
        width: 60%;
      }
      @media ${sizes.xl} {
        width: 50%;
      }

      span {
        letter-spacing: 1px;
      }

      &:disabled {
        background-color: ${COLORS.veryLightPinkTwo};
        color: ${COLORS.white};
        &:hover {
          border: 1px solid ${COLORS.veryLightPinkTwo};
          background-color: ${COLORS.white};
          color: ${COLORS.veryLightPinkTwo};
        }
      }
    }
  }
`

export const InvitationMessageBox = styled.div`
  p {
    margin: 0 auto;
    text-align: center;

    &.not-registered-main-message {
      margin-bottom: 15px;
      font-family: 'Roboto-Bold';
      letter-spacing: 1.23px;
      font-size: 16px;
      @media ${sizes.lg} {
        font-size: 18px;
      }
    }

    &.bottom-space {
      padding-bottom: 40px;
      @media ${sizes.md} {
        padding-bottom: 20px;
      }
    }
  }

  hr.width {
    width: 100%;
    margin-bottom: 20px;
    @media ${sizes.md} {
      width: 60%;
    }
  }

  .command-btns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media ${sizes.md} {
      width: 45%;
      margin: 0 auto;
    }
    @media ${sizes.lg} {
      width: 35%;
    }
    @media ${sizes.xl} {
      width: 40%;
    }

    button {
      max-height: 40px;
      margin-bottom: 20px;
    }
  }
`
