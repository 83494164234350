import React from 'react'
import { Specialty } from './style'
import rightArrowIcon from '../../../../assets/home/right-arrow.svg'
import { Link } from 'react-router-dom'
import Supplier from '../../../../model/Supplier'
import { ImageUrl } from '../../../../model/ImageUrl'

export type SpecialtyRepresentation = {
  icon: string
  title: string
  description: string
  longDescription: string
  priority: number
  url: string
  withRedirect: boolean
  restaurants: Supplier[]
  specialtyBanners: ImageUrl
}

type SpecialtyItemProps = {
  specialty: SpecialtyRepresentation
}
export default function SpecialtyItem(props: SpecialtyItemProps) {
  const { specialty } = props

  return (
    <Link to={specialty.withRedirect ? specialty.url : `/especialidades/${specialty.url}`}>
      <Specialty>
        <div className="circle">
          <i className={`icon-${specialty.icon}`} />
        </div>
        <div className="center">
          <div className="title">{specialty.title}</div>
          <div className="description">{specialty.description}</div>
        </div>
        <img src={rightArrowIcon} />
      </Specialty>
    </Link>
  )
}
