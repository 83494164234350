import React, { useContext, useEffect } from 'react'
import { withRouter } from 'react-router'
import Checkout from '../../components/Checkout'
import ThemeContext from '../../context/ThemeContext'
import {
  EmptyCartContainer,
  ImgEmptyCart,
  ImgContainer,
  Title,
  ButtonContainer,
} from './style'
import { NavButton } from '../../components/Buttons'
import EmptyCart from '../../assets/EmptyCart/empty-cart.svg'
import { removeFromStorage } from '../../helpers/shippingDataInLocalStorage'

function CartView({ history }: any) {
  const { primary, secondary } = useContext(ThemeContext)
  const response = window.localStorage.getItem('response') || ''

  useEffect(() => {
    removeFromStorage()
  }, [])

  if (response) {
    history.push('/resume')
    return null
  }
  return (
    <Checkout>
      <EmptyCartContainer color={secondary}>
        <ImgContainer>
          <ImgEmptyCart src={EmptyCart} />
          <Title> El carrito está vacío</Title>
        </ImgContainer>
      </EmptyCartContainer>
      <ButtonContainer>
        <NavButton to="/" backgroundColor={primary} color={secondary}>
          Continuar Comprando
        </NavButton>
      </ButtonContainer>
    </Checkout>
  )
}

export default withRouter(CartView)
