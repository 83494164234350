import styled from 'styled-components'
import { COLORS } from '../../../../styles/colors'
import media from '../../../../styles/media'

type PromotionProps = {
  background: string
}
export const Promotion = styled.div`
  width: 240px;
  margin: 0 15px;
  @media ${media.xs} {
    width: 290px;
  }
  @media ${media.sm} {
    margin: 0;
  }
  @media ${media.lg} {
    width: 310px;
  }
  @media ${media.xl} {
    width: 360px;
  }

  .title {
    height: 230px;
    color: ${COLORS.white};
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, #000000 116%), url(${(props: PromotionProps) => props.background}) no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 15px;
    font-family: 'Roboto-Bold';

    span {
      margin-bottom: 3.5px;
      @media ${media.lg} {
        font-size: 16px;
      }
      @media ${media.xl} {
        font-size: 18px;
      }
    }

    .img {
      width: 50px;
    }
  }

  .comment {
    font-family: 'Roboto-Light';
    line-height: 1.38;
  }
`
