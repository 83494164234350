export type TypeItems = {
  pathname: string
  name: string
  order: number
  sub_item?: [TypeItems]
}

export const Items: TypeItems[] = [
  {
    pathname: '/',
    name: 'Inicio',
    order: 0
  },
  {
    pathname: '/cart',
    name: 'Carrito de compras',
    order: 1
  },
  {
    pathname: '/contact',
    name: 'Contáctanos',
    order: 3
  },
  {
    pathname: '/faqs',
    name: 'Preguntas frecuentes',
    order: 4
  },
  // {
  //   pathname: '/en-casa',
  //   name: 'Restaurante en casa',
  //   order: 5
  // }
]

export const FilteredItems: TypeItems[] = [
  {
    pathname: '/',
    name: 'Inicio',
    order: 0
  },
  {
    pathname: '/cart',
    name: 'Carrito de compras',
    order: 1
  }
]
