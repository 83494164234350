import React, { useContext } from 'react'
import {
  CustomButtonContainer,
  NavButtonContainer,
  TotalSpan,
  Wrapper,
  Quantity
} from './style'
import { useIsMobile } from '../../helpers/resize'
import Variation from '../../model/Variation'
import ThemeContext from '../../context/ThemeContext'
import CartContext from '../../context/CartContext'
//import cartImg from '../../assets/header/cart.svg'

const CartButton = () => {
  const { primary, secondary } = useContext(ThemeContext)
  const { items, onSetLoaded } = useContext(CartContext)
  let totalPrice = 0
  let totalItems = 0
  items
    ? items.map((item: Variation) => {
        const { quantity, price } = item
        totalPrice = totalPrice + quantity * price
        totalItems = totalItems + quantity
      })
    : (totalItems = 0)
  const redirect = totalItems == 0 ? '/empty-cart' : '/cart'

  const initialMql = window.matchMedia(`(max-width: 576px)`)
  const isMobile = useIsMobile(initialMql.matches)
  return (
    <CustomButtonContainer total={totalItems}>
      <div className="container">
        <NavButtonContainer
          to={redirect}
          backgroundcolor={primary}
          color={secondary}
          onClick={() => {
            onSetLoaded && onSetLoaded(false)
          }}
        >
          {isMobile && (
            <Wrapper>
              <Quantity>{totalItems}</Quantity>
              <>Ver Carrito</>
              <TotalSpan>${totalPrice.toFixed(2)}</TotalSpan>
            </Wrapper>
          )}
        </NavButtonContainer>
      </div>
    </CustomButtonContainer>
  )
}

export default CartButton
