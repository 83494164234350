import React, { useRef, useContext, useState, useEffect, Fragment } from 'react'
import ReactTooltip from 'react-tooltip'
import queryString from 'query-string'
import CartContext from '../../context/CartContext'
import Layout from '../../components/Layout'
import { Column, Row } from '../../components/Grid'
import Suppliers from '../../components/Suppliers'
import Specialties from '../../components/Specialties'
import Promotions from '../../components/Promotions'
import { validateCoupon } from '../../helpers/validateCoupon'
import { BellContainer, StyledIcon, Oval } from './style'
import { COLORS } from '../../styles/colors'

function Home(props: any) {
  const { forceShowNotification, onSetLoaded, onSetProductScroll } = useContext(CartContext)

  const CatalogRef = useRef<HTMLDivElement>(null)

  const Initialcoupon: any = {}
  const [coupon, setCoupon] = useState(Initialcoupon)
  const [showAlert, setShowAlert] = useState(true)
  const [mcn, setMcn] = useState(false)

  const retrieveCouponDataFromURL = (): any | undefined => {
    const parsedData = queryString.parse(location.search)
    if (Object.keys(parsedData).length === 0) {
      return
    }

    let token = (parsedData.token as string) || ''
    token = atob(token)

    const dataUrl = token.split('/')
    if (dataUrl.length == 2) {
      return {
        dni: dataUrl[0],
        coupon: dataUrl[1],
      }
    } else {
      return
    }
  }

  const urlParams = () => {
    const parsedData = queryString.parse(location.search)
    if (Object.keys(parsedData).length === 0) {
      return false
    }
    let hideParam = (parsedData.hb as string) || 'false'
    return hideParam === 'true' ? true : false
  }

  useEffect(() => {
    const strmcn = window.sessionStorage.getItem('mcn')
    if (strmcn === '1') {
      setMcn(true)
    }
    onSetProductScroll && onSetProductScroll('')
    onSetLoaded && onSetLoaded(true)
    const couponData = retrieveCouponDataFromURL()
    const isHidden = urlParams()
    if (isHidden) {
      window.localStorage.setItem('hb', JSON.stringify(isHidden))
    }

    if (couponData !== undefined) {
      const validFields = validateCoupon(couponData.coupon, couponData.dni)

      validFields.then((response: any) => {
        if (response.valid) {
          let couponInfo = {
            id: response.id || '',
            value: response.value || '',
            identification: couponData.dni,
            code: couponData.coupon,
            valid: response.valid,
            expiration: response.expiration || '',
            message: '',
            redeem: response.redeem,
          }
          couponInfo.message = `Grande Table te entrega un cupón por $${response.value} de cortesía para usarlo en tu restaurante favorito`
          forceShowNotification &&
            forceShowNotification({
              type: 'ok',
              message: `Tienes un cupón disponible`,
            })
          window.localStorage.setItem('coupon', JSON.stringify(couponInfo))
          setCoupon(couponInfo)
        } else {
          if (response.expires_to) {
            forceShowNotification &&
              forceShowNotification({
                type: 'fail',
                message: `La vigencia del cupón de $${response.value} ha finalizado.`,
              })

            let couponInfoDate = {
              message: `La vigencia del cupón de $${response.value} ha finalizado.`,
              valid: response.valid,
              redeem: response.redeem,
            }

            setCoupon(couponInfoDate)
            window.localStorage.setItem('coupon', JSON.stringify(couponInfoDate))
          } else {
            let couponInfoFalse = {
              message: response.message,
              valid: response.valid,
              redeem: response.redeem,
            }

            forceShowNotification &&
              forceShowNotification({
                type: 'fail',
                message: response.message,
              })

            setCoupon(couponInfoFalse)
            window.localStorage.setItem('coupon', JSON.stringify(couponInfoFalse))
          }
        }
      })
    }
  }, [])

  const hasKey = (state: any) => state && state.scrollToRestaurants

  const scrollToRestaurants = () => {
    const { state } = props.location
    if (hasKey(state) && CatalogRef && CatalogRef.current) {
      CatalogRef.current.scrollIntoView(true)
    }
  }

  useEffect(scrollToRestaurants, [props.location.state])

  const showAlertOnCuponDetected = () => {
    setShowAlert(false)
    forceShowNotification &&
      forceShowNotification({
        type: 'alert',
        message: coupon.message,
      })
  }

  return (
    <Layout showCart={true} isHome hasLocationBar>
      {coupon.message && (
        <Fragment>
          <BellContainer data-tip data-for="coupon" hasCoupon={true} onClick={() => showAlertOnCuponDetected()}>
            <StyledIcon color={true ? COLORS.camel : COLORS.white} size="25px" name="bell" className="kronos-icon" />
            {showAlert && coupon.valid && <Oval className={'MKTPL_HOME_CAR'}>1</Oval>}
          </BellContainer>
          {coupon.valid ? (
            <ReactTooltip id="coupon" place="bottom" className="customeTip" effect="solid" clickable={true} delayHide={125}>
              <div className="message">TIENES UN CUPÓN DISPONIBLE</div>
            </ReactTooltip>
          ) : (
            ''
          )}
        </Fragment>
      )}
      {!mcn && <Specialties />}
      {!mcn && <Promotions />}
      <Row>
        <div style={{ width: '100%' }} ref={CatalogRef}>
          <Column md={12}>
            <Suppliers mcn={mcn} categoryName={props.location.state ? props.location.state.categoryName : 'Gourmet'} />
          </Column>
        </div>
      </Row>
    </Layout>
  )
}

export default Home
