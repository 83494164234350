import { IPageEvent, PageEventProps } from "./IPageEvent";
import Variation from "../../model/Variation";
import Customer from "../../model/Customer";
import Address from "../../model/Address";

export class CheckoutEvent implements IPageEvent {
  private readonly StepNumber: number;
  private readonly TypeEvent: string;
  private readonly ActionField: string;
  private readonly GroupActionEvent: string;

  constructor(stepNumber: number, typeEvent: string, actionField: string, groupActionEvent: string) {
    this.StepNumber = stepNumber;
    this.TypeEvent = typeEvent;
    this.ActionField = actionField;
    this.GroupActionEvent = groupActionEvent;
  }

  initialize(items: Variation[]): PageEventProps {
    let stepOneProducts: any[] = [];
    const address: Address = JSON.parse(window.localStorage.getItem('address') || '{}')
    const customer: Customer = JSON.parse(window.localStorage.getItem('customer') || '{}')
    const enCasa = JSON.parse(window.localStorage.getItem('encasa') || '{}')
    let variationProduct = items.map((variation: Variation) => {
      const category = variation.product.categories !== undefined && variation.product.categories.length > 0 && variation.product.categories.map((x) => x.slug);
      return {
        name: variation.product.name,
        id: variation.reference,
        price: variation.price,
        category: category,
        supplier: variation.product.supplier.business_name,
        quantity: variation.quantity,
      };
    });
    stepOneProducts.push(...variationProduct);

    return {
      event: this.TypeEvent,
      ecommerce: {
        [this.GroupActionEvent]: {
          actionField: {list: this.ActionField},
          checkout: {
            step: this.StepNumber,
            address: address,
            customer: customer,
            enCasa: enCasa,
            products: stepOneProducts,
          },
        },
      },
    };
  }
}
