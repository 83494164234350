import React from 'react'
import { removeFromStorageExcluding } from '../../helpers/shippingDataInLocalStorage'

type Props = {
  children: (hasCart: boolean) => React.ReactNode
}

class CartViewHandler extends React.Component<Props> {


  render() {
    const { children } = this.props

    const  validateExpireMemberData=()=>{
      let expireDateMemberData:Number = Number(window.localStorage.getItem('expireMemberData')) || 0;

      if ( expireDateMemberData  > new Date().getTime()){
       return ['member-data', 'isMember', 'invoiceData', 'expireMemberData', 'identification', 'isTestBenefit', 'showMessage', 'billing-back'];
      }
      return [];
    }

    removeFromStorageExcluding(['response',...validateExpireMemberData()])

    const cart = JSON.parse(window.localStorage.getItem('items') || '[]')

    if (cart && cart.length > 0) {
      return children(true)
    }

    return children(false)
  }
}

export default CartViewHandler
