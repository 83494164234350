import React from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import {
  ExperienceWrapper,
  ExperienceTitle,
  TermsContainer,
  Checkbox,
  DateContainer,
  TimePicker,
  StyledColumn,
  Placeholder,
  ExperienceInfo
} from './style'
import { ContainerForm, FlexCol, Hr } from '../../style'
import * as Grid from '../../../../components/Grid'
import Icon from '../../../../components/Icon'
import { Input, Error } from '../../../../components/FormElements'
import { Spacer } from '../../../../components/Global/style'
import timerIcon from '../../../../assets/shipping/timer.svg'
import { COLORS } from '../../../../styles/colors'

export type SchedulerProps = {
  collapsed: boolean
  programmed: boolean
  handleScheduled: (value: boolean) => void
  startDate: Date | null
  handleSelectedDate: (date: Date) => void
  minDate: Date
  dateError: boolean
  startTime: Date | null
  handleSelectedTime: (date: Date) => void
  timeMessage: string
  minTime: Date
  maxTime: Date
  disabledTime: boolean
  timeError: boolean
  isMobile: boolean
  mcn?: boolean
  isDisabled: () => boolean
}
function Scheduler(props: SchedulerProps) {
  const {
    collapsed,
    programmed,
    handleScheduled,
    startDate,
    handleSelectedDate,
    minDate,
    dateError,
    startTime,
    handleSelectedTime,
    timeMessage,
    minTime,
    maxTime,
    disabledTime,
    timeError,
    isMobile,
    mcn,
    isDisabled
  } = props

  const now: Date = moment().toDate();
  const twoHoursToNow = moment().add(2, 'h').toDate();
  const checkIsToday = startDate && (moment(startDate.getDate()).isSame(now.getDate()));

  return isDisabled() && isMobile ? (
    <Hr />
  ) : (
    <ContainerForm
      backgroundcolor={COLORS.white}
      className="bp"
      isDisabled={isDisabled()}
    >
      <Grid.Row>
        <Grid.Column>
          <ExperienceTitle>
            <div className="header">
              <img src={timerIcon} />
              <ExperienceInfo>
                <h3>{!mcn ? '¿Deseas programar tu envío?' : '¿Desea programar su envío?'} </h3>
                <p>{!mcn ? 'Escoge la fecha y hora que quieres recibir tu pedido' : 'Seleccione la fecha y hora para recibir su pedido'}</p>
                <TermsContainer
                  onClick={() =>
                    isDisabled() ? null : handleScheduled(!programmed)
                  }
                  className="terms-container"
                >
                  <Checkbox className={programmed ? 'checked' : ''}>
                    {programmed ? (
                      <Icon
                        color="white"
                        size="9px"
                        name="ok"
                        className="kronos-icon"
                      />
                    ) : (
                      <div />
                    )}
                  </Checkbox>
                  <label>
                    <span>Programar envío</span>
                  </label>
                </TermsContainer>
              </ExperienceInfo>
            </div>
          </ExperienceTitle>
          <ExperienceWrapper className={collapsed ? 'active' : ''}>
            <DateContainer className={programmed ? 'active' : ''}>
              <StyledColumn lg={6} md={6} sm={12} className="left">
                <FlexCol>
                  <DatePicker
                    disabled={isDisabled()}
                    selected={startDate}
                    onChange={(date: Date) => date && handleSelectedDate(date)}
                    minDate={minDate}
                    placeholderText="Seleccione la fecha de entrega"
                    customInput={
                      <Input
                        id="programmedDate"
                        type="input"
                        label="Fecha de entrega"
                        borderColor="#2684FF"
                        error={dateError}
                      />
                    }
                  />
                  {dateError ? <Error>Campo requerido.</Error> : <Spacer />}
                </FlexCol>
              </StyledColumn>
              <StyledColumn lg={6} md={6} sm={12} className="right">
                <FlexCol>
                  <TimePicker>
                    <DatePicker
                      selected={startTime}
                      onKeyDown={e => {
                        if (e.keyCode >=48  && e.keyCode <= 57 ) e.preventDefault();
                      }}
                      onChange={(date: Date) =>
                        date && handleSelectedTime(date)
                      }
                      placeholderText={timeMessage}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      dateFormat="h:mm aa"
                      timeCaption="Hora de entrega programada"
                      minTime={checkIsToday ? twoHoursToNow : minTime}
                      maxTime={maxTime}
                      disabled={disabledTime}
                      customInput={
                        <Input
                          id="programmedTime"
                          type="input"
                          label="Hora de entrega"
                          borderColor="#2684FF"
                          error={timeError}
                        />
                      }
                    />
                    <Placeholder>
                      <Icon
                        color="#e6e6e6"
                        size="14px"
                        name={'down-open'}
                        className="kronos-icon"
                      />
                    </Placeholder>
                  </TimePicker>
                  {timeError ? <Error>Campo requerido.</Error> : <Spacer />}
                </FlexCol>
              </StyledColumn>
            </DateContainer>
          </ExperienceWrapper>
        </Grid.Column>
      </Grid.Row>
    </ContainerForm>
  )
}

export default Scheduler
