import React from 'react'
import { Button, NavButton } from '../../../../components/Buttons'
import { COLORS } from '../../../../styles/colors'
import { ContainerElements } from '../../style'
import { ButtonsBox } from './style'

export type ButtonsProps = {
  isDisabled: () => boolean
  goToBilling: () => void
  onSetLoaded: ((loaded: boolean) => void) | undefined
}
export default function Buttons(props: ButtonsProps) {
  const { isDisabled, goToBilling, onSetLoaded } = props

  return (
    <ButtonsBox>
      <ContainerElements>
        <Button
          backgroundColor={isDisabled() ? COLORS.veryLightPinkTwo : '#000000'}
          disabled={isDisabled()}
          color={COLORS.white}
          onClick={() => {
            goToBilling()
          }}
          id={'MKTPL_SHIP_NEXT'}
        >
          Siguiente
        </Button>
      </ContainerElements>
      <ContainerElements>
        <NavButton
          to="/cart"
          backgroundColor={COLORS.teeth}
          color="#000000"
          bordercolor="#000000"
          third="#000000"
          onClick={() => onSetLoaded && onSetLoaded(false)}
        >
          Atrás
        </NavButton>
      </ContainerElements>
    </ButtonsBox>
  )
}
