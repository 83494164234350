import styled from 'styled-components'
import { Link } from 'react-router-dom'
import sizes from '../../styles/media'
import { COLORS } from '../../styles/colors'

interface Props extends React.HTMLProps<HTMLButtonElement> {
  backgroundcolor?: string
  color: string
  icon?: string
  sizeicon?: string
  bordercolor?: string
  third?: string
  fontSize?: string
  mcn?: boolean
}

export const NavButtonContainer = styled(Link)<Props>`
  text-transform: uppercase;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem 0rem 0.8rem;
  font-weight: bold;
  line-height: 1.2;
  border-radius:${props => (!props.mcn ? '20px': '5px')};
  font-size: ${props => (props.fontSize ? props.fontSize : '12px')};
  background-color: ${props => props.backgroundcolor};
  color: ${props => props.color};
  border: ${props =>
    props.bordercolor
      ? '1px solid ' + props.bordercolor
      : '1px solid transparent'};
  transition: 0.2s ease-in;
  &:hover {
    background-color: ${props => (props.third ? props.third : props.color)};
    color: ${props => props.backgroundcolor};
    border: 1px solid ${props => props.backgroundcolor};
  }

  @media ${sizes.sm} {
    padding: 1rem 0rem 1rem;
  }
`

export const ButtonContainer = styled.button<Props>`
  font-family: 'Roboto-Bold';
  text-transform: uppercase;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  font-weight: bold;
  border-radius:${props => (!props.mcn ? '20px': '5px')};
  font-size: ${props => (props.fontSize ? props.fontSize : '12px')};
  background-color: ${props => props.backgroundcolor};
  color: ${props => props.color};
  border: ${props =>
    props.bordercolor
      ? '1px solid ' + props.bordercolor
      : '1px solid transparent'};
  transition: 0.2s ease-in;
  outline: none;
  
  &:hover {
    background-color: ${props => props.color};
    color: ${props => props.backgroundcolor};
    border: 1px solid ${props => props.backgroundcolor};
    outline: none;
  }

  span {
    font-weight: bold;
  }
`

export const Title = styled.span`
  margin: auto;
  font-weight: bold;
`

export const StyledNavIconButton = styled(Link)<Props>`
  border-radius: 50%;
  color: ${COLORS.white};
  padding: auto;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 27px;
  z-index: 2;
  transition: background-color 0.25s ease-out, opacity 0.25s ease-out;
  background-color: ${props => props.color};
  ${props => props.disabled && 'opacity: 0.4; cursor: not-allowed;'};

  &:focus {
    outline: 0;
  }

  &:hover {
    background-color: ${props => props.color};
  }

  @media ${sizes.lg} {
    width: 40px;
    height: 40px;
    font-size: ${props => (props.sizeicon ? props.sizeicon : '26px')};
  }

  span {
    font-size: 14.4px;
  }
`

export const StyledIconButton = styled.button<Props>`
  border-radius: 50%;
  background-color: ${props => props.color};
  color: ${COLORS.white};
  padding: auto;
  border: none;
  font-size: 11px;
  line-height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  z-index: 2;
  width: 20px;
  height: 20px;
`

export const Label = styled.span`
  display: block;
  padding: 0 10px;
`

export type ExternalLinkBoxProps = {
  backgroundColor: string
  textColor: string
}
export const ExternalLinkBox = styled.a`
  transition: background-color 0.25s ease-out, opacity 0.25s ease-out;
  text-transform: uppercase;
  border-radius: 20px;
  padding: 13px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 40px;
  background-color: ${(props: ExternalLinkBoxProps) => props.backgroundColor};
  border: 1px solid transparent;
  color: ${(props: ExternalLinkBoxProps) => props.textColor};

  &:hover {
    background-color: ${(props: ExternalLinkBoxProps) => props.textColor};
    color: ${(props: ExternalLinkBoxProps) => props.backgroundColor};
    border-color: ${(props: ExternalLinkBoxProps) => props.backgroundColor};
  }

  &:focus {
    outline: 0;
  }

  span {
    font-size: 12px;
    letter-spacing: 1px;
    font-family: 'Roboto-Bold';
  }
`
