import React, { useContext } from 'react'
import Variation from '../../../../../../model/Variation'
import {
  PriceContainer,
  StyledPrice
} from './style'
import ThemeContext from '../../../../../../context/ThemeContext'
import FormatNumber from '../../../../../../helpers/formatNumber'
import { COLORS } from '../../../../../../styles/colors'

type Props = {
  variation: Variation
}

const Price = ({ variation }: Props) => {
  let { price } = variation
  const { primary } = useContext(ThemeContext)
  
  return (
    <PriceContainer fontcolor={primary}>
      <div>
        <StyledPrice fontcolor={COLORS.camel}>$ {FormatNumber(price)}</StyledPrice>
      </div>
    </PriceContainer>
  )
}

export default Price
