import styled from 'styled-components'
import sizes from '../../../../styles/media'

export const StepperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(178, 178, 178, 0.5);
  @media ${sizes.md} {
    padding: 1rem 2rem;
  }
  @media ${sizes.lg} {
    padding: 2rem;
  }
  @media ${sizes.xl} {
    padding: 2rem 7rem;
  }

  a,
  span {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  a,
  button {
    cursor: default;
  }

  a {
    span {
      font-size: 12px;
      font-weight: bold;
      @media ${sizes.md} {
        font-size: 14px;
      }
      @media ${sizes.lg} {
        font-size: 16px;
      }
      @media ${sizes.xl} {
        font-size: 18px;
      }
    }
  }
`

export const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  padding: 0 13%;
  @media ${sizes.md} {
    padding: 0 10%;
  }
`

export const Step = styled.div`
  width: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  @media ${sizes.md} {
    flex-direction: row;
    flex-wrap: nowrap;
  }
`

export const StepName = styled.span`
  margin-top: 0.5rem;
  color: ${props => props.color};
  font-family: 'Roboto-Bold';
  font-size: 12px;
  @media ${sizes.md} {
    margin-top: 0;
    margin-left: 5px;
  }
  @media ${sizes.lg} {
    font-size: 14px;
  }
  @media ${sizes.xl} {
    font-size: 16px;
  }
`

export const Line = styled.hr`
  position: absolute;
  border: 1px solid #e0e0e0;
  width: 70%;
  top: 21%;
  left: 14%;

  @media ${sizes.md} {
    top: 25%;
  }

  @media ${sizes.lg} {
    width: 80%;
    top: 30%;
    left: 10%;
  }

  @media ${sizes.xl} {
    width: 70%;
    left: 15%;
  }
`
