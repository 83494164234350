import React from 'react'

import { Route, Switch } from 'react-router'
import { BrowserRouter as Router, Redirect } from 'react-router-dom'
import Error from '../views/Error'

import cartFallback from '../helpers/cartFallback'
import shippingFallback from '../helpers/shippingFallback'
import billingFallback from '../helpers/billingFallback'
import paymentFallback from '../helpers/paymentFallback'
import resumeFallBack from '../helpers/resumeFallBack'

import Home from '../views/Home'
import Restaurant from '../views/Restaurant'
import Product from '../views/Product'
import Cart from '../views/Cart'
import Billing from '../views/Billing'
import Resume from '../views/Resume'
import Shipping from '../views/Shipping'
import Payment from '../views/Payment'
import Faqs from '../views/Faqs'
import Contact from '../views/Contact'
import EmptyCart from '../views/EmptyCart'
// import AtHome from '../views/AtHome'
import Specialty from '../views/Specialty'
import Mcn from '../views/Mcn'

const CartFallBack = cartFallback(Cart, () => <Redirect to="/" />)

const ShippingFallBack = shippingFallback(Shipping, () => <Redirect to="/" />)

const BillingFallBack = billingFallback(Billing, () => <Redirect to="/shipping" />)

const PaymentFallBack = paymentFallback(Payment, () => <Redirect to="/billing" />)

const ResumeFallBack = resumeFallBack(Resume, () => <Redirect to="/" />)

function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/restaurant/:restaurantSlug" component={Restaurant} />
        <Route exact path="/resume" component={ResumeFallBack} />
        <Route exact path="/product/:productSlug" component={Product} />
        <Route exact path="/cart" component={CartFallBack} />
        <Route exact path="/shipping" component={ShippingFallBack} />
        <Route exact path="/billing" component={BillingFallBack} />
        <Route exact path="/payment" component={PaymentFallBack} />
        <Route exact path="/faqs" component={Faqs} />
        <Route exact path="/empty-cart" component={EmptyCart} />
        <Route exact path="/contact" component={Contact} />
        {/* <Route exact path="/en-casa" component={AtHome} /> */}
        <Route exact path="/especialidades/:slug" component={Specialty} />
        <Route exact path="/oops" render={props => <Error {...props} errorCode={500} />} />
        <Route render={props => <Error {...props} errorCode={404} />} />
      </Switch>
    </Router>
  )
}

export default Routes
