import { PageEventProps } from "./GTM/IPageEvent";

export const GtmEventsLoad = (pageEventLoad: PageEventProps) => {
  //@ts-ignore
  const pageEvent: PageEventProps = {
    ...pageEventLoad,
  };
  //@ts-ignore
  window && window.dataLayer && window.dataLayer.push(pageEvent);
  return pageEvent;
};
