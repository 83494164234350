import { IPageEvent, PageEventProps } from "./IPageEvent";
import Variation from "../../model/Variation";

export class CartEvent implements IPageEvent {
  private readonly TypeEvent: string;
  private readonly ActionField: string;
  private readonly GroupActionEvent: string;

  constructor(typeEvent: string, actionField: string, groupActionEvent: string) {
    this.TypeEvent = typeEvent;
    this.ActionField = actionField;
    this.GroupActionEvent = groupActionEvent;
  }

  initialize(items: Variation[]): PageEventProps {
    let stepOneProducts: any[] = [];
    let variationProduct = items.map((variation: Variation) => {
      const category = variation.product.categories !== undefined && variation.product.categories.length > 0 && variation.product.categories.map((x) => x.slug);
      return {
        name: variation.product.name,
        id: variation.reference,
        price: variation.price,
        category: category,
        supplier: variation.product.supplier.business_name,
        quantity: variation.quantity,
      };
    });
    stepOneProducts.push(...variationProduct);

    return {
      event: this.TypeEvent,
      ecommerce: {
        [this.GroupActionEvent]: {
          actionField: { list: this.ActionField },
          products: stepOneProducts,
        }
      },
    };
  }
}
