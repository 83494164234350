import React, { useEffect, useState } from 'react'
import ApolloClient from 'apollo-client'
import { ApolloConsumer } from 'react-apollo'
import Select from 'react-select'
import { ValueType } from 'react-select/lib/types'
import { Button, ExternalLink } from '../../../../components/Buttons'
import { Error, Input } from '../../../../components/FormElements'
import { Spacer } from '../../../../components/Global/style'
import * as Grid from '../../../../components/Grid'
import { GRANDE_TABLE_PORTAL_URL } from '../../../../config'
import { Validate } from '../../../../helpers/inputValidations'
import { identificationTypeOptions } from '../../../../helpers/selectProps'
import { COLORS } from '../../../../styles/colors'
import { ContainerForm, Hr } from '../../style'
import { TwoColorMessage } from '../TestBenefitMessage/style'
import { FormBox, InvitationMessageBox, ValidatorForm } from './style'
import { retrieveMemberData, validator } from './validator'

const isValid = (identification: string): boolean => {
  let total = 0
  const idLength = identification.length
  const longcheck = idLength - 1

  if (idLength > 0 && idLength === 10) {
    for (let i = 0; i < longcheck; i++) {
      if (i % 2 === 0) {
        let aux = parseInt(identification.charAt(i)) * 2
        if (aux > 9) {
          aux -= 9
        }
        total += aux
      } else {
        total += parseInt(identification.charAt(i))
      }
    }

    total = total % 10 ? 10 - (total % 10) : 0

    if (parseInt(identification.charAt(idLength - 1)) == total) {
      return true
    }
    return false
  }

  return false
}

type InvitationMessageProps = {
  setInvitationMessage: (invitationMessage: boolean) => void
}
function InvitationMessage(props: InvitationMessageProps) {
  return (
    <InvitationMessageBox>
      <Grid.Row>
        <p className="not-registered-main-message">La cédula ingresada no se encuentra registrada</p>
      </Grid.Row>
      <Grid.Row>
        <Hr className="width" />
      </Grid.Row>
      <Grid.Row>
        <TwoColorMessage className="bottom-space">
          Para acceder a los beneficios <span>Grande Table</span>
          <br />
          deber ser socio <b>Diners Club.</b>
        </TwoColorMessage>
      </Grid.Row>
      <Grid.Row className="command-btns">
        <Button backgroundColor="#000000" color={COLORS.white} onClick={() => props.setInvitationMessage(false)}>
          Volver a validar
        </Button>
        <ExternalLink
          target="_blank"
          externalURL={`${GRANDE_TABLE_PORTAL_URL}/membership`}
          text="Adquiere tu Membresía"
          backgroundColor={COLORS.camel}
          textColor={COLORS.white}
          className="fixed-width"
        />
      </Grid.Row>
    </InvitationMessageBox>
  )
}

type ValidatorBoxProps = {
  handleUpdate: (event: any) => void
  identification: string
  handleChange: (selectedOption: any) => void
  identificationState: IdentificationStateProps
  setIdentificationState: (identificationState: IdentificationStateProps) => void
  errorMessage: string
  mcn?: boolean
  handleClick: (client: ApolloClient<any>) => void
}
function ValidatorBox(props: ValidatorBoxProps) {
  const { handleUpdate, identification, handleChange, identificationState, errorMessage, handleClick, mcn } = props
  const [isDisabled, setIsDisabled] = useState(true)

  const getSelected: ValueType<{ value: string; label: string }> = {
    value: '',
    label: 'Seleccione una opción',
  }

  const handleValidation = () => {
    if (
      identification.length == 0 ||
      (identificationState.value === 'CI' && identification.length !== 10) ||
      (identificationState.value === 'RUC' && identification.length !== 13)
    ) {
      setIsDisabled(true)
      return
    }

    setIsDisabled(false)
  }

  useEffect(() => {
    if (identificationState.value != '') {
      handleValidation()
    }
  }, [identificationState.value])

  useEffect(() => {
    handleValidation()
  }, [identification])

  return (
    <>
      <Grid.Row>
        <p className="validator-message">
          {!mcn ? 'Ingresa tu identificación para continuar con tu compra.' :'Ingrese su identificación para continuar con la compra.'}
        </p>
      </Grid.Row>

      <ApolloConsumer>
        {client => (
          <FormBox>
            <Grid.Row className="input-side">
              <Grid.Column lg={6} md={6} sm={12}>
                <span><b>Tipo de Identificación</b></span>
                <Select
                  options={identificationTypeOptions}
                  placeholder="Selecciona el tipo de documento"
                  onChange={(e: any) => handleChange(e)}
                  value={
                    identificationState && identificationState.value == ''
                      ? getSelected
                      : {
                          label: identificationState.type,
                          value: identificationState.value,
                        }
                  }
                  defaultValue={getSelected}
                />
              </Grid.Column>

              <Grid.Column lg={6} md={6} sm={12} className="column-identification">
                <Input
                  label="Numero de identificación"
                  type="text"
                  placeholder="Ingrese su identificación"
                  placeholderColor={COLORS.brownGray}
                  maxLength={13}
                  disabled={identificationState.disable}
                  onChange={handleUpdate}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      handleClick(client)
                    }
                  }}
                  value={identification}
                  required
                  error={errorMessage.length > 0}
                />
                {errorMessage.length > 0 ? <Error>{errorMessage}</Error> : <Spacer />}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="button-side">
             <Button className="validate-btn" backgroundColor="#000000" color={COLORS.white} onClick={() => handleClick(client)} disabled={isDisabled}>
                Validar
              </Button>
            </Grid.Row>
          </FormBox>
        )}
      </ApolloConsumer>
    </>
  )
}

export type InvoiceData = {
  firstName?: string
  lastName?: string
  identification: string
  identificationType?: string
  email: string
  phone?: string
  address?: string
}

export type MemberData = {
  identification: string
  firstName: string
  lastName: string
  stateProvince: string
  city: string
  zone: string
  mainStreet: string
  secondaryStreet: string
  steetNumber: string
  email: string
  phone: string
  addressReference: string
  invoiceData: InvoiceData
}

export type ProspectData = {
  identification: string
  email: string
  invoiceData: InvoiceData
}

type IdValidatorProps = {
  isValidMembership: (validMembership: boolean) => void
  isTestBenefit: (testBenefit: boolean) => void
  setChance: (chace: number) => void
  identification: string
  setIdentification: (identification: string) => void
  setMessageVisible: (showMessage: boolean) => void
  tryOut: boolean
  setMemberData: (memberData: MemberData | null) => void
  mcn?: boolean
  setProspectData: (ProspectData: ProspectData | null) => void
}

type IdentificationStateProps = {
  disable: boolean;
  type: string;
  value: string;
}
function IdValidator(props: IdValidatorProps) {
  const { isValidMembership, isTestBenefit, setChance, identification, setIdentification, setMessageVisible, tryOut, setMemberData, setProspectData } = props
  const [errorMessage, setErrorMessage] = useState('')
  const [invitationMessage, setInvitationMessage] = useState(false)
  const [identificationState, setIdentificationState] = useState({
    disable: true,
    type: '',
    value: '',
  })


  const handleUpdate = (event: any) => {
    setIdentification(event.target.value.toUpperCase().replace(/[^0-9A-Z]/g, ''))
  }
  const nowDate = new Date()
  const handleWhenNoAffiliate = ({ testBenefit, chance, messageVisible }: any) => {
    isTestBenefit(testBenefit)
    setChance(chance)
    setMessageVisible(messageVisible)
    window.localStorage.setItem('isTestBenefit', `${testBenefit}`)
    window.localStorage.setItem('showMessage', `${messageVisible}`)
    window.localStorage.setItem('expireMemberData', nowDate.setHours(nowDate.getHours() + 4) + '')
    window.localStorage.setItem('chance', `${chance}`)
    window.localStorage.setItem('billing-back', `true`)
  }

  const handleClick = async (client: ApolloClient<any>) => {
    if (identification.length == 0) {
      setErrorMessage('La identificación es requerida')
      return
    }

    const { isAffiliate, affiliate, unAffiliate } = await validator(identification)

    if (!isAffiliate) {
      const validatedData = Validate(identification, identificationState.value)

      if (validatedData.error) {
        setErrorMessage(validatedData.message)
        return
      }
    }
    window.localStorage.setItem('identification', identification)

    isValidMembership(isAffiliate)

    if (isAffiliate) {
      setMemberData(retrieveMemberData(affiliate))
      return
    }

    if (!tryOut) {
      setInvitationMessage(true)
      isTestBenefit(false)
      window.localStorage.setItem('isTestBenefit', 'false')
      return
    }

    if (!unAffiliate) {
      handleWhenNoAffiliate({
        testBenefit: true,
        chance: 1,
        messageVisible: true,
      })
      return
    }

    if (unAffiliate) {
      const prospectData = {
        identification: unAffiliate.identification_number,
        email: unAffiliate.email,
        invoiceData: {
          identification: unAffiliate.identification_number,
          email: unAffiliate.email,
        },
      }
      window.localStorage.setItem('isMember', JSON.stringify(false))
      window.localStorage.setItem('prospect-data', JSON.stringify(prospectData, ['identification', 'email']))
      window.localStorage.setItem('invoiceData', JSON.stringify(prospectData.invoiceData))
      setProspectData(prospectData)

      if (unAffiliate.purchases < 2) {
        handleWhenNoAffiliate({
          testBenefit: true,
          chance: 2,
          messageVisible: true,
        })
        return
      }

      if (unAffiliate.purchases >= 2) {
        handleWhenNoAffiliate({
          testBenefit: false,
          chance: 3,
          messageVisible: true,
        })
      }
    }
  }

  const handleChange = (selectedOption: any) => {
    const options = {
      CI: { disable: false, type: 'Cédula de Identidad', value: 'CI' },
      PPN: { disable: false, type: 'Pasaporte', value: 'PPN' },
      RUC: { disable: false, type: 'RUC', value: 'RUC' },
    }

    setIdentificationState(options[selectedOption.value])
  }
  return (
    <ValidatorForm>
      <Grid.Row>
        <Grid.Column>
          <h3>Datos de validación</h3>
        </Grid.Column>
      </Grid.Row>
      <ContainerForm backgroundcolor={COLORS.white} className="content">
        {invitationMessage ? (
          <InvitationMessage setInvitationMessage={setInvitationMessage} />
        ) : (
          <ValidatorBox handleUpdate={handleUpdate} identification={identification} handleChange={handleChange} identificationState={identificationState} setIdentificationState={setIdentificationState} errorMessage={errorMessage} handleClick={handleClick} />
        )}
      </ContainerForm>
    </ValidatorForm>
  )
}

export default IdValidator
