import styled from 'styled-components'
import sizes from '../../styles/media'
import { Column } from '../../components/Grid'
import { Link } from 'react-router-dom'
import Icon from '../Icon'
import { COLORS } from '../../styles/colors'

interface ItemProp {
  selected?: boolean
}

interface CouponProps {
  hasCoupon?: boolean
}
interface Props {
  mcn?: boolean
}

interface Props {
  mcn?: boolean
}

export const Img = styled.img`
  width: auto;
`

export const ImgCart = styled.img`
  margin: 0 0.5rem;
  width: 2rem;
`
export const Burger = styled.img`
  width: 1.5rem;
`

export const TextCart = styled.span`
  padding-top: 0.25rem;
  color: ${COLORS.teeth};
  display: none;
  @media ${sizes.md} {
    display: inline-block;
  }
`

export const Cart = styled.div`
  display: flex;
  align-items: baseline;
  align-self: center;
  position: relative;
  margin-left: -13px;
  @media ${sizes.md} {
    margin-left: 0;
  }
`

export const Oval = styled.span`
  font-family: Arial;
  color: ${(props: Props) => props.mcn === true ? '#000000' : COLORS.white };
  background: red;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
  position: relative;
  top: -0.6rem;
  right: 0rem;
  width: 18px;
  height: 18px;
  padding-top: 2px;
  @media ${sizes.md} {
    padding-top: 2.55px;
  }
`

export const BurguerContainer = styled(Column)`
  display: flex;
  align-items: center;
  flex-basis: 25%;
  @media ${sizes.md} {
    display: none;
  }

  a {
    i {
      display: block;
      height: 100%;
      &:before {
        margin: 0;
      }
    }
  }
`

export const StyledLogoMgnLinkContainer = styled(Link)`
  margin: 0.25rem;
  @media ${sizes.sm} {
    margin: 0.25rem;
    margin-left: 270px;
    max-width: 100%;
  }
`

export const LogoContainer = styled(Column)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  flex-wrap: wrap;

  @media ${sizes.sm} {
    justify-content: left;
    flex-wrap: nowrap;
    flex-basis: 10%;
  }
`

export const LogoMcnContainer = styled(Column)`
  justify-content: center;
  align-items: center;
  padding: 0;
  flex-wrap: wrap;
  margin:auto;
  @media ${sizes.sm} {
    justify-content: left;
    flex-wrap: nowrap;
    flex-basis: 10%;
  }
`

export const StyledLogoLinkContainer = styled(Link)`
  margin: 0.25rem;
  display: flex;
  width: 100px;
  @media ${sizes.sm} {
    margin: 0.25rem;
    max-width: 100%;
  }
  @media ${sizes.xl} {
    width: 120px;
  }
`

export const ActionLinksContainer = styled(Column)`
  display: flex;
  justify-content: flex-end;
  padding: 0;
  position: initial;
  flex-basis: 25%;
  @media ${sizes.sm} {
    flex-basis: 85%;
  }
  @media ${sizes.md} {
    margin-top: auto;
  }

  .separator {
    margin: auto 0;
    padding: 0.25rem 0.8rem 0;

    height: 1.2rem;
  }

  .search {
    cursor: pointer;
    width: 24px;
    @media ${sizes.md} {
      margin: 0 2rem;
      margin-left: 1rem;
    }
  }
`

export const MenuLink = styled.div`
  display: none;
  color: ${COLORS.primary};
  font-weight: bold;

  @media ${sizes.md} {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem;
    a {
      color: #132496;
    }
  }
`

export const CartContainer = styled(Link)`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  width: 50px;
  .cart {
    margin-right: 5%;
  }
`

export const HeaderLinks = styled(Link)`
  display: none;
  transition: none;
  padding: 1rem 0;

  @media ${sizes.md} {
    text-decoration: none;
    display: flex;
    align-items: center;
    color: white;
  }
  &.active {
    display: none;
  }
  span {
    border-right: 0.11rem solid white;
    padding: 0.05rem 0.8rem 0.2rem;
    position: relative;
    line-height: 0.9;
    letter-spacing: 0.69px;
    font-size: 12px;
    &:before {
      content: '';
      border-bottom: solid 2px #b99358;
      position: absolute;
      width: 80%;
      bottom: -2px;
      left: 0;
      right: 0;
      margin: auto;
      visibility: ${(props: ItemProp) => (props.selected === true ? 'visible' : 'hidden')};
      -webkit-transform: ${(props: ItemProp) => (props.selected === true ? 'scaleX(1)' : 'scaleX(0)')};
      transform: ${(props: ItemProp) => (props.selected === true ? 'scaleX(1)' : 'scaleX(0)')};
      -webkit-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s;
    }
  }
  &:hover {
    background: transparent;
    span {
      &:before {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
  }
`

export const Space = styled.span`
  margin-left: 10px;
`

export const StyledIcon = styled(Icon)`
  position: relative;

  &:before {
    width: 40px;
    margin: 0;
  }
`

export const CustomColumn = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  margin: auto;

  .row-no-wrap {
    flex-wrap: nowrap;
    @media ${sizes.sm} {
      margin: 0;
    }
  }
`

export const BellContainer = styled.div`
  display: ${(props: CouponProps) => (props.hasCoupon ? 'flex' : 'none')};
  align-items: center;

  cursor: ${(props: CouponProps) => (props.hasCoupon ? 'pointer' : '')};
  i {
    &:before {
      margin: 0;
    }
    animation: mymove 0.8s infinite;
  }
  &:hover {
    i {
      color: ${COLORS.camel};
    }
  }

  @keyframes mymove {
    from {
      left: -2px;
    }
    to {
      left: 2px;
    }
  }
`
