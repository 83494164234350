import styled from 'styled-components'
import Modal from 'react-modal'
import sizes from '../../styles/media'
import { COLORS } from '../../styles/colors'

interface ModalProps {
  backgroundcolor?: string
  padding: string
}

export const ModalWrapper = styled(Modal)`
  position: relative;
  top: 30%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  outline: none;
  max-width: 400px;
  width: 100%;
  text-align: center;
  z-index: 100;
  height: 60vh;
  transform: translate(-50%, -50%);

  @media ${sizes.sm} {
    height: auto;
    top: 50%;
  }
`
export const ModalDataContainer = styled.div`
  background: ${(props: ModalProps) => props.backgroundcolor};
  padding: ${(props: ModalProps) => props.padding};
  position: relative;

  .description {
    color: #75787b;
    font-size: 14px;
    margin: 0;
  }

  .arrow {
    clip-path: polygon(100% 0, 0 0, 50% 100%);
    position: absolute;
    top: 0;
    left: 50%;
    width: 35px;
    height: 15px;
    background: ${COLORS.white};
    transform: translate(-50%);
  }

  .modalArt {
    width: 80px;
  }
`

export const FeatureErrors = styled.span`
  color: red;
  font-size: 11px;
`

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
  @media ${sizes.md} {
    margin-top: 0;
    width: 100%;
  }
`
