import { COLORS } from '../styles/colors'

export async function validateGtMember(value: string) {
  return {
    error: false,
    message: 'Valido',
    hide: false,
    value: value,
    fillColor: COLORS.white
  }
}
