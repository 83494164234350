import React from 'react'
import leftArrowIcon from '../../../../assets/home/slider-left-arrow.svg'
import rightArrowIcon from '../../../../assets/home/slider-right-arrow.svg'
import { SliderArrow } from './style'

interface ArrowProps {
  onClick?: any
  isPrev?: boolean
}
export default function Arrow(props: ArrowProps) {
  const { onClick, isPrev } = props

  return (
    <SliderArrow onClick={onClick} className={isPrev ? 'prev-btn' : 'next-btn'}>
      <img src={isPrev ? leftArrowIcon : rightArrowIcon} />
    </SliderArrow>
  )
}
