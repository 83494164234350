import React, { useContext } from 'react'
import Slider from 'react-slick'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Query, QueryResult } from 'react-apollo'
import gql from 'graphql-tag'
import { ActiveSpecialties } from '../../model/Specialty'
import { SegmentTitle } from '../Global/style'
import { SpecialtiesBox } from './style'
import Arrow from './components/Arrow'
import SpecialtyItem, { SpecialtyRepresentation } from './components/SpecialtyItem'
import Loader from '../Loader'
import { toRepresentations } from '../../helpers/transformSpecialtyIntoRepresentation'
import { useIsMobile } from '../../helpers/resize'
import LocationContext from '../../context/LocationContext'

const GET_ACTIVE_SPECIALTIES = gql`
  query getActiveSpecialtiesByCity($city: String) {
    activeSpecialtiesByCity(city: $city) {
      name
      status
      order
      type
      description
      slug
      icon
      url
    }
  }
`

export default function Specialties() {
  const initialMql = window.matchMedia(`(max-width: 767px)`)
  const isMobile = useIsMobile(initialMql.matches)
  const { city } = useContext(LocationContext)

  return (
    <Query<ActiveSpecialties> query={GET_ACTIVE_SPECIALTIES} variables={{ city }}>
      {({ loading, error, data }: QueryResult<ActiveSpecialties>) => {
        if (loading) {
          return <Loader />
        }

        if (error) {
          return <></>
        }

        if (!data || !data.activeSpecialtiesByCity) {
          return <></>
        }

        const specialtyRepresentations: SpecialtyRepresentation[] = toRepresentations(data.activeSpecialtiesByCity)

        const specialties = specialtyRepresentations.sort((a: SpecialtyRepresentation, b: SpecialtyRepresentation) => a.priority - b.priority)

        const settings = {
          className: `specialty-slider${specialties.length <= 4 ? ' left-aligned' : ''}`,
          infinite: specialties.length > 4,
          slidesToShow: 4,
          slidesToScroll: 1,
          swipeToSlide: true,
          nextArrow: <Arrow />,
          prevArrow: <Arrow isPrev />,
          responsive: [
            {
              breakpoint: 991,
              settings: { slidesToShow: 2, infinite: specialties.length > 2, className: `specialty-slider${specialties.length <= 2 ? ' left-aligned' : ''}` },
            },
            {
              breakpoint: 1200,
              settings: { slidesToShow: 3, infinite: specialties.length > 3, className: `specialty-slider${specialties.length <= 3 ? ' left-aligned' : ''}` },
            },
          ],
        }

        return specialties.length > 0 ? (
          <SpecialtiesBox>
            <SegmentTitle>Especialidades</SegmentTitle>
            {isMobile ? (
              <div className="no-side-margins">
                <Swiper slidesPerView={1.37} freeMode={true} pagination={false} speed={2000}>
                  {specialties.map((specialty: SpecialtyRepresentation, index: number) => (
                    <SwiperSlide key={index}>
                      <SpecialtyItem specialty={specialty} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            ) : (
              <Slider {...settings}>
                {specialties.map((specialty: SpecialtyRepresentation, index: number) => (
                  <SpecialtyItem key={index} specialty={specialty} />
                ))}
              </Slider>
            )}
          </SpecialtiesBox>
        ) : (
          <></>
        )
      }}
    </Query>
  )
}
