import gql from 'graphql-tag'
import { MemberData } from '.'
import { createClient } from '../../../../client'

const GET_MEMBER_INFO = gql`
  query getMembershipByIdentification($identification: String!) {
    membershipByIdentification(identification: $identification) {
      isAffiliate
      affiliate {
        customer_first_name
        customer_last_name
        validation_identification
        email
        state
        city
        zone
        street_1
        street_2
        number
        phone
        reference
        invoicing_first_name
        invoicing_last_name
        invoicing_identification_number
        invoicing_identification_type
        invoicing_email
        invoicing_phone
        invoicing_address
      }
      unAffiliate {
        identification_number
        email
        purchases
      }
    }
  }
`
const nowDate = new Date();
export const validator = async (identification: string) => {
  const client = createClient()

  const { data } = await client.query({
    query: GET_MEMBER_INFO,
    variables: { identification },
  })

  return data.membershipByIdentification
}

export const retrieveMemberData = (affiliate: any): MemberData => {
  const memberData: MemberData = {
    identification: affiliate.validation_identification,
    firstName: affiliate.customer_first_name,
    lastName: affiliate.customer_last_name,
    stateProvince: affiliate.state,
    city: affiliate.city,
    zone: affiliate.zone,
    mainStreet: affiliate.street_1,
    secondaryStreet: affiliate.street_2,
    steetNumber: affiliate.number,
    email: affiliate.email,
    phone: affiliate.phone,
    addressReference: affiliate.reference,
    invoiceData: {
      firstName: affiliate.invoicing_first_name,
      lastName: affiliate.invoicing_last_name,
      identification: affiliate.invoicing_identification_number,
      identificationType: affiliate.invoicing_identification_type,
      email: affiliate.invoicing_email,
      phone: affiliate.invoicing_phone,
      address: affiliate.invoicing_address,
    },
  }

  window.localStorage.setItem('expireMemberData',   nowDate.setHours(nowDate.getHours() + 4)+"");
  window.localStorage.setItem('isMember', JSON.stringify(true))
  window.localStorage.setItem(
    'member-data',
    JSON.stringify(memberData, [
      'identification',
      'firstName',
      'lastName',
      'stateProvince',
      'city',
      'zone',
      'mainStreet',
      'secondaryStreet',
      'steetNumber',
      'email',
      'phone',
      'addressReference',
    ]),
  )
  window.localStorage.setItem(
    'invoiceData',
    JSON.stringify(memberData.invoiceData),
  )
  return memberData
}
