import React, { useContext } from 'react'
import { Query, QueryResult } from 'react-apollo'
import gql from 'graphql-tag'
import Slider from 'react-slick'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ActivePromos } from '../../model/Promotion'
import { SegmentTitle } from '../Global/style'
import Arrow from '../Specialties/components/Arrow'
import PromotionItem, { PromoRepresentation } from './components/PromotionItem'
import Loader from '../Loader'
import { PromotionsBox } from './style'
import { toRepresentations } from '../../helpers/transformPromotionIntoRepresentation'
import { useIsMobile } from '../../helpers/resize'
import LocationContext from '../../context/LocationContext'

const GET_ACTIVE_PROMOS = gql`
  query getActivePromosByCity($city: String) {
    activePromosByCity(city: $city) {
      name
      status
      order
      type
      summary
      url
      assets {
        type
        url
      }
    }
  }
`

export default function Promotions() {
  const initialMql = window.matchMedia(`(max-width: 767px)`)
  const isMobile = useIsMobile(initialMql.matches)
  const { city } = useContext(LocationContext)

  return (
    <Query<ActivePromos> query={GET_ACTIVE_PROMOS} variables={{ city }}>
      {({ loading, error, data }: QueryResult<ActivePromos>) => {
        if (loading) {
          return <Loader />
        }

        if (error) {
          return <></>
        }

        if (!data || !data.activePromosByCity) {
          return <></>
        }

        const promoRepresentations: PromoRepresentation[] = toRepresentations(data.activePromosByCity)

        const promotions = promoRepresentations.sort((a: PromoRepresentation, b: PromoRepresentation) => a.priority - b.priority)

        const settings = {
          className: promotions.length <= 2 ? 'promo-slider left-aligned' : 'promo-slider',
          infinite: promotions.length > 3,
          slidesToShow: 3,
          slidesToScroll: 1,
          swipeToSlide: true,
          nextArrow: <Arrow />,
          prevArrow: <Arrow isPrev />,
          responsive: [
            {
              breakpoint: 991,
              settings: { slidesToShow: 1, infinite: promotions.length > 1, className: promotions.length <= 1 ? 'promo-slider left-aligned' : 'promo-slider' },
            },
            {
              breakpoint: 1200,
              settings: { slidesToShow: 2, infinite: promotions.length > 2, className: promotions.length <= 2 ? 'promo-slider left-aligned' : 'promo-slider' },
            },
          ],
        }

        return promotions.length > 0 ? (
          <PromotionsBox>
            <SegmentTitle>Lo mejor de la temporada</SegmentTitle>
            <p className="sub">Disfrutar de menús increibles diseñados para paladares exigentes</p>
            {isMobile ? (
              <div className="no-side-margins">
                <Swiper slidesPerView={1.23} freeMode={true} pagination={false} speed={2000}>
                  {promotions.map((promotion: PromoRepresentation, index: number) => (
                    <SwiperSlide key={index}>
                      <PromotionItem promotion={promotion} key={index} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            ) : (
              <Slider {...settings}>
                {promotions.map((promotion: PromoRepresentation, index: number) => (
                  <PromotionItem promotion={promotion} key={index} />
                ))}
              </Slider>
            )}
          </PromotionsBox>
        ) : (
          <></>
        )
      }}
    </Query>
  )
}
