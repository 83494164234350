import React, { useContext, useRef, useState, useEffect } from 'react'
import { Query, QueryResult } from 'react-apollo'
import Layout from '../../components/Layout'
import { RouteComponentProps, withRouter } from 'react-router'
import Delivery from '../../components/Delivery'
import { Column, Row, Container } from '../../components/Grid'
import Catalog from '../../components/Catalog'
import { COLORS } from '../../styles/colors'
import { FACEBOOK_PIXEL_ID } from '../../config/'
import pixelFacebookStructure from '../../helpers/pixelFacebookStructure'

import {
  MainBanner,
  Img,
  BannerRestaurantContainer,
  StyledTitle,
  RestaurantContainer,
  CustomLink,
  StyledColumn,
  Refdiv,
  Discount,
  ModalMinDataContainer,
  AmountModal,
  Line,
  Close,
  ExperienceTitle,
  ExperienceInfo,
  CatalogWrapper,
  StyledRow,
} from './style'
import Breadcrumb from '../../components/Breadcrumb'
import EnCasa from './components/EnCasa'
import { useIsMobile } from '../../helpers/resize'
import bannerDesktop from '../../assets/banners/banner-gt-establecimiento.jpg'
import bannerMobile from '../../assets/banners/banner-gt-establecimiento-mobile.jpg'
import _star from '../../assets/banners/star-icon.svg'
import Supplier from '../../model/Supplier'
import Loader from '../../components/Loader'
import { GET_RESTAURANT_QUERY, GET_SUPPLIERS_QUERY } from './query'

import menuImg from '../../assets/at-home/menu.svg'

import Icon from '../../components/Icon'
import { Redirect } from 'react-router-dom'
import CartContext from '../../context/CartContext'
import { SupplierEvent } from '../../helpers/GTM/SupplierEvent'
import { GtmEventsLoad } from '../../helpers/gtm'
interface Filter {
  slug: String
}

interface DataSuppliers {
  suppliers: {
    nodes: Supplier[]
  }
}

interface DataSupplier {
  supplier: Supplier
}

interface Variables {
  filters: Filter
}

interface SuppliersFilter {
  s: String
}

interface Options {
  limit: Number
  sortBy: String
}

interface SuppliersVariables {
  filters: SuppliersFilter
  options: Options
}
interface RouterParams
  extends RouteComponentProps<{
    restaurantSlug?: string
  }> {}

function Restaurant({
  match: {
    params: { restaurantSlug },
  },
}: RouterParams) {
  const { productScroll } = useContext(CartContext)
  const initialMql = window.matchMedia(`(max-width: 767px)`)
  const isMobile = useIsMobile(initialMql.matches)
  const CatalogRef = useRef<null | HTMLDivElement>(null)
  const supplierVariables = {
    filters: { slug: restaurantSlug ? restaurantSlug : '' },
  }
  const [schedulesOpen, setScheduleOpen] = useState(false)

  const [mcn, setMcn] = useState(false)
  useEffect(() => {
    const strmcn = window.sessionStorage.getItem('mcn')
    if (strmcn === '1') {
      setMcn(true)
    }
  }, [])

  const other = {
    filters: { s: '' },
    options: {
      limit: 7,
      sortBy: 'priority',
    },
  }

  return (
    <Query<DataSupplier, Variables> query={GET_RESTAURANT_QUERY} variables={supplierVariables}>
      {({ loading, error, data }: QueryResult<DataSupplier, Variables>) => {
        if (loading) {
          return <Loader />
        }

        if (error) {
          return <Redirect to="/" />
        }

        if (!data) {
          return <p>No Data</p>
        }
        const restaurant = data.supplier

        GtmEventsLoad(new SupplierEvent('supplierClick', 'OpenSupplier', 'supplierClick').initialize(restaurant))

        const { metaData, assets } = restaurant
        const diasSemana = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']
        const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

        const sortWeekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
        const todayDate = new Date()
        const scheduleData: String = metaData.horarios
        const schedule = scheduleData ? JSON.parse(scheduleData.toString()) : ''
        let todaySchedule
        if (scheduleData) {
          if (schedule.mondayToSunday) {
            todaySchedule = schedule['mondayToSunday']
          } else {
            todaySchedule = schedule[weekdays[todayDate.getDay()]]
          }
        } else {
          todaySchedule = 'Desconocido'
        }

        const displayAssets = assets
          ? assets.sort((a: any, b: any) => {
              return a.order > b.order ? 1 : -1
            })
          : []

        const ReactPixel =  require('react-facebook-pixel')
        ReactPixel.default.init(FACEBOOK_PIXEL_ID)
        const PixelFacebookStructure = pixelFacebookStructure({restaurant,metaData,schedule});
        ReactPixel.default.pageView()

        return (
          <Layout showCart={true} hasLocationBar>
            <script type="application/ld+json">
              {PixelFacebookStructure}
            </script>
            <Delivery />
            <Row>
              <Column>
                <Breadcrumb
                  links={[
                    { label: 'Inicio', to: '/' },
                    {
                      label: restaurant.business_name,
                    },
                  ]}
                />
              </Column>
            </Row>
            <Row>
              <Column md={12}>
                <MainBanner>
                  <BannerRestaurantContainer>
                    <div className="wrapper">
                      <img
                        className="restaurant-img"
                        src={
                          displayAssets && displayAssets.length > 0
                            ? displayAssets[0].url
                            : 'https://storage.googleapis.com/gaia-assets-bucket/dba24fe0-3151-4063-927d-178b881ef7aa/759df298-d1d5-41bf-9a68-24395e9089df.jpg'
                        }
                        alt={restaurant.business_name + '-Grande Table Delivery'}
                      ></img>
                      <h1 className="restaurant-name">{restaurant.business_name}</h1>
                    </div>
                    {metaData.descuento && (
                      <Discount className="mobile">
                        <img src={_star} alt="" />
                        <strong>Beneficio exclusivo:</strong>
                        {`${metaData.descuento}% de descuento en todo el menú `}
                      </Discount>
                    )}
                  </BannerRestaurantContainer>
                  {isMobile && <Img src={bannerMobile} />}
                  {!isMobile && <Img src={bannerDesktop} />}
                </MainBanner>
              </Column>
              {metaData.descuento && (
                <Column>
                  <Discount className="desktop">
                    <img src={_star} alt="" />
                    <strong>Beneficio exclusivo:</strong>
                    {`${metaData.descuento}% de descuento en todo el menú `}
                  </Discount>
                </Column>
              )}
            </Row>
            {metaData.en_casa && metaData.en_casa === 'si' && <EnCasa supplier={restaurant.id} />}
            <CatalogWrapper>
              <Container>
                <Row>
                  <Column>
                    <ExperienceTitle>
                      <div className="header">
                        <img src={menuImg} />
                        <ExperienceInfo>
                          <h3>{!mcn ? 'Selecciona tu menú' : 'Seleccione su menú'} </h3>
                        </ExperienceInfo>
                      </div>
                    </ExperienceTitle>
                  </Column>
                </Row>
                <Row>
                  <Refdiv ref={CatalogRef}>
                    <Column md={12}>
                      <Catalog mcn={mcn} supplierId={restaurant.id} productScroll={productScroll} />
                    </Column>
                  </Refdiv>
                </Row>
              </Container>
            </CatalogWrapper>
            <Row>
              <Column xs={6}>
                <StyledTitle>Otros restaurantes</StyledTitle>
              </Column>
              <Column
                xs={6}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <CustomLink href="/">Ver todos</CustomLink>
              </Column>
            </Row>
            <StyledRow>
              <Query<DataSuppliers, SuppliersVariables> query={GET_SUPPLIERS_QUERY} variables={other}>
                {({ loading, error, data }: QueryResult<DataSuppliers, SuppliersVariables>) => {
                  if (loading) {
                    return <Loader />
                  }

                  if (error) {
                    return <p>Error</p>
                  }

                  if (!data) {
                    return <p>No Data</p>
                  }

                  const suppliers = data.suppliers.nodes.filter((supplier: Supplier) => supplier.id !== restaurant.id)

                  return (
                    <>
                      {suppliers.map((supplier: Supplier, index: number) => {
                        const suppliersAssets = supplier.assets
                          ? supplier.assets.sort((a: any, b: any) => {
                              return a.order > b.order ? 1 : -1
                            })
                          : []
                        const assetImg =
                          suppliersAssets && suppliersAssets.length > 0
                            ? suppliersAssets[0].url
                            : 'https://storage.googleapis.com/gaia-assets-bucket/dba24fe0-3151-4063-927d-178b881ef7aa/759df298-d1d5-41bf-9a68-24395e9089df.jpg'

                        if (index < 6) {
                          return (
                            <StyledColumn key={index} xs={6} sm={2}>
                              <RestaurantContainer>
                                <a href={`/restaurant/${supplier.slug}`}>
                                  <img className="restautant-logo" width="150" src={assetImg} alt={supplier.business_name}></img>
                                </a>
                              </RestaurantContainer>
                            </StyledColumn>
                          )
                        }

                        return <></>
                      })}
                    </>
                  )
                }}
              </Query>
            </StyledRow>

            <AmountModal
              style={{
                overlay: {
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  overflow: 'auto',
                  padding: '15px',
                  backgroundColor: 'rgba(0, 0, 0, 0.75)',
                  zIndex: 100,
                },
              }}
              ariaHideApp={false}
              isOpen={schedulesOpen}
            >
              <>
                <ModalMinDataContainer padding={'30px 50px 30px 50px'} backgroundcolor={COLORS.white}>
                  <Row>
                    <Column>
                      <img
                        src={
                          displayAssets && displayAssets.length > 0
                            ? displayAssets[0].url
                            : 'https://storage.googleapis.com/gaia-assets-bucket/dba24fe0-3151-4063-927d-178b881ef7aa/759df298-d1d5-41bf-9a68-24395e9089df.jpg'
                        }
                        alt="art"
                        width="80"
                        className="modalIcon"
                      ></img>
                    </Column>
                    <Column>
                      <h2>{restaurant.business_name}</h2>
                      <p className="subtitle">
                        Horarios de atención: <br />
                        <span>Hoy: {todaySchedule.active ? `${todaySchedule.from.label} a ${todaySchedule.to.label}` : 'Cerrado'}</span>
                      </p>
                      <Line></Line>
                      {schedule.mondayToSunday && schedule.mondayToSunday.active ? (
                        <>
                          {diasSemana.map((item: any, index: number) => {
                            return (
                              <div key={index} className="description">
                                <span className="bolder">{item}</span>
                                <span>{`${schedule.mondayToSunday.from.label} a ${schedule.mondayToSunday.to.label}`}</span>
                              </div>
                            )
                          })}
                        </>
                      ) : (
                        <>
                          {sortWeekdays.map((item: any, index: number) => {
                            return (
                              <div key={index} className="description">
                                <span className="bolder">{schedule[item] ? schedule[item].day : item}</span>
                                <span>{schedule[item] ? (schedule[item].active ? `${schedule[item].from.label} a ${schedule[item].to.label}` : 'Cerrado') : item}</span>
                              </div>
                            )
                          })}
                        </>
                      )}
                    </Column>
                  </Row>
                </ModalMinDataContainer>
                <Close onClick={() => setScheduleOpen(false)}>
                  <Icon color={COLORS.purpleBrown} size="22px" name="cancel-1" className="kronos-icon" />
                </Close>
              </>
            </AmountModal>
          </Layout>
        )
      }}
    </Query>
  )
}

export default withRouter(Restaurant)
