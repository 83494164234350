import { createContext } from 'react'
import { COLORS } from '../../styles/colors'

export type Theme = {
  primary: string
  secondary: string
  third?: string
  btnPrimary?: string
  btnSecondary?: string
}

const ThemeContext = createContext<Theme>({
  primary: COLORS.primary,
  secondary: COLORS.white,
  third: COLORS.teeth,
  btnPrimary: COLORS.black,
  btnSecondary: COLORS.white
})

export default ThemeContext
