import { createContext } from 'react'
import Variation from '../../model/Variation'
import Product from '../../model/Product'

type Notification = {
  type: string
  message: string
}

export type Cart = {
  deliverInfo: string
  items: Variation[]
  updateEnCasa?: (item: any) => void
  updateScheduledOrder?: (item: any) => void
  couponData: any
  itemSelected?: Product
  onGetProduct?: (product: Product) => void
  loaded: boolean
  onSetLoaded?: (loaded: boolean) => void
  onLoadProductList?: (variation: Variation[]) => void
  changeDeliveryInfo?: (info: string) => void
  onAddToCart?: (variation: Variation, quantity: number) => boolean
  validateSameRestaurantItems?: (variation: Variation) => boolean
  onRemoveFromCart?: (index: number) => void
  onDelFromCart?: (variation: Variation) => void
  onDelAllItems?: () => void
  deleteItemsFromStorage?: () => void
  notification: Notification
  onSetNotification?: (notify: Notification) => void
  forceShowNotification?: (notify: Notification) => boolean
  updateItems?: () => Promise<boolean>
  onsetCouponData?: (coupon: any) => void,
  productScroll?: string
  onSetProductScroll?: (productName: string) => void
}

const CartContext = createContext<Cart>({
  deliverInfo: '',
  couponData: {},
  items: [],
  loaded: false,
  notification: { type: '', message: '' },
  productScroll: '',
})

export default CartContext
