import React from 'react'
import Supplier from '../../model/Supplier'
import { Row } from '../Grid'
import SupplierCard from '../SupplierCard'
import { StyledColumn, StyledSubTitle, StyledRow } from './style'

type RelatedRestaurantsProps = {
  restaurants: Supplier[]
  title: string
}
export default function RelatedRestaurants(props: RelatedRestaurantsProps) {
  const { restaurants, title } = props

  return (
    <>
      <Row>
        <StyledColumn sm={7} xs={12}>
          <StyledSubTitle>
            <span>{`${restaurants.length} Disponibles`}</span>
            {title}
          </StyledSubTitle>
        </StyledColumn>
      </Row>
      <StyledRow>
        {restaurants &&
          restaurants.map((supplier: Supplier, index: number) => {
            return (
              <StyledColumn md={6} xs={12} lg={4} key={`product-${index}`}>
                <SupplierCard supplier={supplier} />
              </StyledColumn>
            )
          })}
      </StyledRow>
    </>
  )
}
