import styled from 'styled-components'

interface ImageProps {
  status: string
}

export const StyledImage = styled.img`
  display: ${(props: ImageProps) =>
    props.status === 'loaded' ? 'flex' : 'none !important'};
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
`

export const StyledLoading = styled.img`
  display: ${(props: ImageProps) =>
    props.status !== 'loaded' ? 'flex' : 'none !important'};
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  padding: 3.5rem;
  height: auto;
  max-height: 100%;
  transition: max-height 1s ease;
`

export const StyledContainer = styled.div`
  max-height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`
