import { IPageEventSuppliers, PageEventProps } from "./IPageEvent";
import Supplier from "../../model/Supplier";

export class SupplierEvent implements IPageEventSuppliers {
  private readonly TypeEvent: string;
  private readonly ActionField: string;
  private readonly GroupActionEvent: string;

  constructor(typeEvent: string, actionField: string, groupActionEvent: string) {
    this.TypeEvent = typeEvent;
    this.ActionField = actionField;
    this.GroupActionEvent = groupActionEvent;
  }

  initialize(supplier: Supplier): PageEventProps {
  
    let supplierClick = {
      name: supplier.business_name,
      city: supplier.city,
      categoria: supplier.metaData.categoria,
    }

    return {
      event: this.TypeEvent,
      ecommerce: {
        [this.GroupActionEvent]: {
          actionField: { list: this.ActionField },
          supplier: supplierClick,
        }
      },
    };
  }
}
