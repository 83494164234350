import styled from 'styled-components'
import sizes from '../../styles/media'
import { Column, Row } from '../Grid'

export const StyledSubTitle = styled.h2`
  display: flex;
  flex-direction: column;
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #000000;
  span {
    font-family: 'Roboto-Light';
    font-size: 16px;
    font-weight: lighter;
  }
`

export const StyledColumn = styled(Column)`
  display: flex;
`

export const StyledRow = styled(Row)`
  margin-top: 1rem;
  @media ${sizes.sm} {
    margin-top: 0;
  }
`
