import styled from 'styled-components'
// import sizes from "../../styles/media";

interface Props {
  backgroundcolor?: string
}

export const ContainerForm = styled.div`
  ${(props: Props) => {
    return `
      width: 100%;
      background-color: ${props.backgroundcolor};
      box-shadow: 0 2px 4px 0 #d9d9d9;
      padding: 31px 32px 0px 33px;
      margin-bottom: 2rem;
      h2 {
        margin-bottom: 5px;
      }
      
    `
  }}

  .hidden-input {
    display: none;
  }
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  label,
  span {
    margin-bottom: 7px;
  }
  span {
    margin-top: 4px;
  }
`

export const ContainerElements = styled.div`
  margin-bottom: 1rem;
`

export const TextAreaElements = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const Element = styled.div`
  width: 80%;
`

export const CountContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  width: 20%;
  font-size: 12px;
  color: #303030;
  line-height: 2;
  margin-top: 3px;
`

export const CustomDisclaimer = styled.h4`
  text-align: justify;
`
