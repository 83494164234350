import React from 'react'
import { Link } from 'react-router-dom'
import nextArrowIcon from '../../../../assets/promo/next.svg'
import { Promotion } from './style'

export type PromoRepresentation = { image: string; title: string; comment: string; priority: number; url: string }

type PromotionItemProps = {
  promotion: PromoRepresentation
}
export default function PromotionItem(props: PromotionItemProps) {
  const { promotion } = props

  return (
    <Promotion background={promotion.image}>
      <Link to={promotion.url}>
        <div className="title">
          <span>{promotion.title}</span>
          <img src={nextArrowIcon} />
        </div>
      </Link>
      <div className="comment">{promotion.comment}</div>
    </Promotion>
  )
}
