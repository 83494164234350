import React from 'react'
import PaymentViewHandler from '../views/PaymentViewHandler'

type SwitchProps = {
  Component: React.ComponentType
  FallbackComponent: React.ComponentType
}

type PaymentFallBackProps = {
  Component: React.ComponentType
  FallbackComponent: React.ComponentType
}

const Switch = (props: SwitchProps) => {
  const { Component, FallbackComponent, ...rest } = props

  return (
    <PaymentViewHandler>
      {(hasCustomer: boolean) => {
        if (!hasCustomer) {
          return <FallbackComponent {...rest} />
        } else {
          return <Component {...rest} />
        }
      }}
    </PaymentViewHandler>
  )
}

const paymentFallback = (
  Component: React.ComponentType,
  FallbackComponent: React.ComponentType
) => {
  return (props: PaymentFallBackProps) => (
    <Switch
      {...props}
      Component={Component}
      FallbackComponent={FallbackComponent}
    />
  )
}

export default paymentFallback
