import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { graphql, Query, QueryResult } from 'react-apollo'
import formatNumber from '../../helpers/formatNumber'
import { validateCoupon } from '../../helpers/validateCoupon'
import Modal from '../../components/Modal/ModalRoot'
import { Button, IconButton } from '../Buttons'
import { Column, Row } from '../Grid'
import Icon from '../../components/Icon'
import {
  ActionsCartContainer,
  AmountModal,
  ApplyDiscount,
  ButtonContainer,
  ButtonMobile,
  CardDetail,
  Clean,
  ColumnContainer,
  ContainerItem,
  ContainerMobile,
  Continue,
  CouponContainer,
  Disclaimer,
  ErrorDiscount,
  HearFormCoupon,
  Img,
  ImgContainer,
  InHouseLabel,
  InputWrapper,
  LinkProduct,
  ListContainerHome,
  ListContainerMobile,
  ModalMinDataContainer,
  Name,
  Order,
  PriceContainer,
  ProductListContainer,
  Quantity,
  QuantityButton,
  QuantityContainer,
  Reference,
  RowContainer,
  RowQuantityContainer,
  StockContainer,
  SuccessDiscount,
  TotalItems,
  WhiteSpace,
} from './style'
import ThemeContext from '../../context/ThemeContext'
import CartContext from '../../context/CartContext'
import { ADD_TRACKING_MUTATION, GET_SUPPLIER_STATUS } from './query'
import productExample from '../../assets/catalog/product-samsung.png'
import couponIcon from '../../assets/cart/coupon-image.svg'
import couponErrorIcon from '../../assets/cart/coupon-error.svg'
import atHomeImg from '../../assets/at-home/product.svg'
import clock from '../../assets/images/clock.svg'
import errorAmount from '../../assets/images/sectorerror.png'
import SupplierModel from '../../model/Supplier'
import Loader from '../../components/Loader'
import CartComment from '../CartComment'

interface CouponObject {
  id: string
  value: number
  use: boolean
  expiration: string
}

interface DataSupplier {
  supplierId: SupplierModel
}

interface Variables {
  supplierID: string
}

const ProductsList = ({ history, mutate }: any) => {
  const [restaurantSlug, setRestaurantSlug] = useState('')
  let subtotal: number = 0,
    discount: number = 0,
    iva: number = 0,
    total: number = 0
  const enCasa = JSON.parse(window.localStorage.getItem('encasa') || '{}')
  const { primary, secondary, btnPrimary, btnSecondary } = useContext(ThemeContext)
  const { items, onSetLoaded, onRemoveFromCart, forceShowNotification, onAddToCart, onDelFromCart, updateEnCasa, onDelAllItems } = useContext(CartContext)
  const showButtonCoupon = items && items[0].product.supplier ? items[0].product.supplier.allow_coupons : true

  if (!showButtonCoupon) {
    window.localStorage.removeItem('coupon')
  }

  const storageCouponValue = JSON.parse(window.localStorage.getItem('coupon') || '{}')
  const storageSpecifications = JSON.parse(window.localStorage.getItem('specifications') || '{}')
  const [validAmount, setValidAmount] = useState(true)
  const [enCasaAdded, setEnCasa] = useState(enCasa ? enCasa.added : false)
  const [couponValue, setCouponValue] = useState({
    id: storageCouponValue.id ? storageCouponValue.id : '',
    value: storageCouponValue.value ? storageCouponValue.value : 0,
    use: storageCouponValue.use ? storageCouponValue.use : false,
    expiration: storageCouponValue.expiration ? storageCouponValue.expiration : '',
  } as CouponObject)
  const [_document, setDocument] = useState(storageCouponValue.identification ? storageCouponValue.identification : '')
  const [_coupon, setCoupon] = useState(storageCouponValue.code ? storageCouponValue.code : '')
  const [disabled, setDisabled] = useState(!storageCouponValue.identification && storageCouponValue.code)
  const [minOpen, setMinOpen] = useState(false)
  const [validCoupon, setValidCoupon] = useState(false)
  const [message, setMessage] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [showFormCoupon, setShowFormCoupon] = useState(false)
  const [mcn, setMcn] = useState(false)
  const [quantityDiners, setQuantityDiners] = useState(storageSpecifications.diners || 1)

  const supplierVariable = {
    supplierID: items[0].product.supplier.id,
  }

  useEffect(() => {
    const mcnStorage = window.sessionStorage.getItem('mcn')
    if (mcnStorage === '1') {
      setMcn(true)
    }
    if (
      items &&
      items.length > 0 &&
      items[0].product &&
      items[0].product.supplier &&
      items[0].product.supplier.metaData &&
      (!items[0].product.supplier.metaData.en_casa || (items[0].product.supplier.metaData.en_casa && items[0].product.supplier.metaData.en_casa === 'no'))
    ) {
      window.localStorage.removeItem('encasa')
      setEnCasa(false)
    }
  }, [])

  useEffect(() => {
    if (couponValue.use) {
      setValidCoupon(couponValue.use)
      setModalOpen(couponValue.use)
    }
  }, [couponValue])

  useEffect(() => {
    const supplierDiscount = items.length > 0 ? items[0].product.supplier && items[0].product.supplier.metaData && items[0].product.supplier.metaData.monto_minimo : null

    if (supplierDiscount) {
      if (supplierDiscount > total) {
        setValidAmount(false)
      } else {
        setValidAmount(true)
      }
    }
  }, [total])

  const handleClickOnEvent = (eventType: any, type: any, items: any) => {
    if (eventType === 'onClick' && onSetLoaded) {
      onSetLoaded(false)
    }
    let itemsTrack: any = []

    items.map((item: any, index: number) => {
      const {
        id,
        price,
        regularPrice,
        reference,
        product: { name, assets },
      } = item

      itemsTrack[index] = {
        id: id,
        name: name,
        reference: reference,
        price: price,
        regularPrice: regularPrice,
        image: assets[0].url,
      }
    })

    let token = window.localStorage.getItem('token') || ''
    mutate({
      variables: {
        token: token,
        createdAt: new Date().toLocaleString(),
        createdAtUnix: Math.round(+new Date() / 1000),
        type: type,
        eventType: eventType,
        items: itemsTrack,
        docUpdated: 0,
      },
    })
  }

  const subtractQuantity = (quantity: number, item: any, openModal: any, index: number) => {
    if (!onDelFromCart) {
      return
    }
    if (quantity > 1) {
      onDelFromCart(item)
    } else {
      confirmDeleteProduct(openModal, index)
    }
  }

  const confirmDeleteProduct = (openModal: any, index: number) => {
    openModal('CONFIRM_DELETE', {
      description: '¿Está seguro de que desea eliminar el producto?',
      functionOK: deleteProduct.call({}, name, index),
    })
  }

  const additionQuantity = (localStock: number, storageStock: number, quantity: number, item: any) => {
    localStock + storageStock > quantity && 10 > quantity && onAddToCart && onAddToCart(item, 1)
  }

  const updateQuantityDiners = (quantity: number) => {
    if (quantity > 0 && quantity < 11) {
      window.localStorage.setItem('specifications', JSON.stringify({ diners: quantity }))
      setQuantityDiners(quantity)
    }
  }

  const deleteProduct = (name: string, index: number) => () => {
    onRemoveFromCart && onRemoveFromCart(index)
    forceShowNotification &&
      forceShowNotification({
        type: 'alert',
        message: name + ' se ha eliminado del carrito.',
      })
  }

  const getPrincipalImage = (assets: any, item: any) => {
    let image = productExample
    if (assets && assets.length > 0) {
      const composeAssets = item.assets && item.assets.length > 0 ? item.assets : assets
      image = composeAssets.sort(function (a: any, b: any) {
        if (a.order > b.order) {
          return 1
        }
        if (a.order < b.order) {
          return -1
        }
        return 0
      })[0].url
    }
    return image
  }

  const calculatePayment = () => {
    items.forEach(item => {
      discount += (item.supplierCost * item.supplierDiscount * item.quantity) / 100
      subtotal += item.supplierCost * item.quantity
    })
    if (enCasaAdded && enCasa && enCasa.variation && enCasa.variation.price) {
      subtotal = subtotal + enCasa.variation.price / 1.15
    }
    iva = (subtotal - discount) * 0.15
    total = subtotal - discount + iva
  }

  calculatePayment()

  const removeEnCasa = () => () => {
    updateEnCasa && updateEnCasa({ added: false, variation: '', quantity: 0, plate: false })
    setEnCasa(false)
  }

  const clearCart = () => () => {
    onDelAllItems && onDelAllItems()
    window.localStorage.removeItem('encasa')
    window.localStorage.removeItem('scheduled')
    forceShowNotification &&
      forceShowNotification({
        type: 'alert',
        message: 'Se ha limpiado el carrito.',
      })
  }

  const validateData = (value: string, id: string) => {
    switch (id) {
      case 'document':
        setDocument(value)
        setDisabled(true)
        if (value.length >= 5 && _coupon.length <= 20) {
          setDisabled(false)
        }
        break
      case 'coupon':
        setCoupon(value)
        setDisabled(true)
        if (_document.length >= 5 && value.length <= 20) {
          setDisabled(false)
        }
        break
      default:
        break
    }
  }

  const checkCoupon = () => {
    setShowFormCoupon(!showFormCoupon)
    validateCoupon(_coupon, _document)
      .then((response: any) => {
        if (response) {
          setValidCoupon(response.valid)
          if (!response.valid) {
            setCouponInformation(
              response.message,
              false,
              'coupon',
              {
                id: '',
                value: 0,
                use: false,
                expiration: '',
              },
              true,
            )
            return
          }
          setCouponValue({
            id: response.id,
            value: response.value,
            use: true,
            expiration: response.expiration,
          })
          redeemCoupon(response)
        } else {
          setCouponInformation(
            'El código o la cédula asignada no son válidos.',
            false,
            'coupon',
            {
              id: '',
              value: 0,
              use: false,
              expiration: '',
            },
            true,
          )
        }
      })
      .catch(() => {
        setCouponInformation(
          'El código o la cédula asignada no son válidos.',
          false,
          'coupon',
          {
            id: '',
            value: 0,
            use: false,
            expiration: '',
          },
          true,
        )
      })
  }

  const destroyCoupon = () => {
    setCouponInformation(
      'El código o la cédula asignada no son válidos.',
      false,
      'coupon',
      {
        id: '',
        value: 0,
        use: false,
        expiration: '',
      },
      false,
    )
  }

  const setCouponInformation = (messageCoupon: string, isValidCoupon: boolean, localStorageKey: string, couponValue: any, isModelOpen: boolean) => {
    setMessage(messageCoupon)
    setValidCoupon(isValidCoupon)
    window.localStorage.removeItem(localStorageKey)
    setCouponValue(couponValue)
    const customer = JSON.parse(window.localStorage.getItem('customer') || '{}')
    customer['coupon'] = ''
    window.localStorage.setItem('customer', JSON.stringify(customer))
    setModalOpen(isModelOpen)
  }

  const getMessageCoupon = () => {
    return validCoupon ? (
      <SuccessDiscount>
        {' '}
        <img src={couponIcon} alt={''} /> Código ingresado exitosamente.
        <br />
        Tu cupón es de <span>${couponValue.value}</span>
        <br />
        <a onClick={() => destroyCoupon()}>Utilizar en otro momento</a>
      </SuccessDiscount>
    ) : (
      <ErrorDiscount>
        {' '}
        <img src={couponErrorIcon} alt={''} /> {message}
        <br />
        <a
          onClick={() => {
            setShowFormCoupon(!showFormCoupon)
            setModalOpen(!setModalOpen)
          }}
        >
          Volver a intentar
        </a>
      </ErrorDiscount>
    )
  }

  const redeemCoupon = (response: any) => {
    const customer = JSON.parse(window.localStorage.getItem('customer') || '{}')
    const coupon = JSON.parse(window.localStorage.getItem('coupon') || '{}')
    customer['coupon'] = response.id
    coupon['id'] = response.id
    coupon['identification'] = _document
    coupon['code'] = _coupon
    coupon['use'] = true
    coupon['value'] = response.value
    coupon['expiration'] = response.expiration
    window.localStorage.setItem('customer', JSON.stringify(customer))
    window.localStorage.setItem('coupon', JSON.stringify(coupon))
  }

  const obtainVariationsInfo = (features: any, options: any) => {
    let variations = ''
    features.forEach((feature: any, index: number) => {
      if (feature.options.length > 1) {
        variations += feature.name
        feature.options.forEach((option: any) => {
          if (options.some((elem: any) => elem.id === option.id)) {
            variations += ': ' + option.name
          }
        })
        variations += '\n'
      }
    })
    return variations
  }

  const validateAmount = () => {
    if (validAmount) {
      history.push('/shipping')
      return
    }
    setMinOpen(true)
  }

  return (
    <Modal>
      {({ openModal }) => {
        return (
          <>
            {
              <Query<DataSupplier, Variables> query={GET_SUPPLIER_STATUS} fetchPolicy="network-only" variables={supplierVariable}>
                {({ loading, error, data }: QueryResult<DataSupplier, Variables>) => {
                  if (loading) {
                    return <Loader />
                  }

                  if (error) {
                    return null
                  }

                  if (data && data.supplierId) {
                    const supplier = data.supplierId
                    if (supplier.status == 'inactive') {
                      forceShowNotification &&
                        forceShowNotification({
                          type: 'fail',
                          message: 'Productos no disponible',
                        })
                      window.localStorage.removeItem('items')
                      clearCart()
                      window.location.reload()
                    }
                  }
                  return null
                }}
              </Query>
            }
            <Order>{mcn ? 'Su pedido' : 'Tu pedido'}</Order>
            <TotalItems>Total: ({items.length} Items) </TotalItems>
            <Row>
              <Column md={7} lg={8}>
                <ContainerMobile onLoad={() => handleClickOnEvent('onLoad', 'VIEW_CART_EVENT', items)}>
                  {items.map((item, index: number) => {
                    const {
                      price,
                      options,
                      product: { name, assets, slug, supplier, features },
                      inventory: { storageStock, localStock },
                      quantity,
                    } = item
                    let image = getPrincipalImage(assets, item)
                    let variationsInfo = options.length > 0 ? obtainVariationsInfo(features, options) : ''
                    let supplierSlug = supplier ? supplier.slug : ''
                    setRestaurantSlug(supplierSlug)

                    return (
                      <ContainerItem key={index}>
                        <ListContainerMobile>
                          <LinkProduct to={`/product/${slug}`} key={index}>
                            <ImgContainer>
                              <Img src={image} />
                            </ImgContainer>
                          </LinkProduct>
                          <ColumnContainer>
                            <LinkProduct to={`/product/${slug}`} key={index}>
                              <Name>{name}</Name>
                              <Reference>
                                <i>{variationsInfo}</i>
                              </Reference>
                            </LinkProduct>
                            <ButtonMobile onClick={() => confirmDeleteProduct(openModal, index)}>
                              <IconButton icon="cancel" />
                            </ButtonMobile>
                            <RowQuantityContainer>
                              <small>Cantidad:</small>
                              <RowContainer>
                                <QuantityContainer>
                                  <QuantityButton backgroundcolor={primary} fontcolor={secondary} onClick={() => subtractQuantity(quantity, item, openModal, index)}>
                                    <Icon color={secondary} name="minus" className="kronos-icon" />
                                  </QuantityButton>
                                  <Quantity backgroundcolor={primary}>{quantity}</Quantity>
                                  <QuantityButton backgroundcolor={primary} fontcolor={secondary} onClick={() => additionQuantity(localStock, storageStock, quantity, item)}>
                                    <Icon color={secondary} name="plus" className="kronos-icon" />
                                  </QuantityButton>
                                </QuantityContainer>
                                <PriceContainer>
                                  <span>$ {formatNumber(price * quantity)}</span>
                                </PriceContainer>
                              </RowContainer>
                            </RowQuantityContainer>
                          </ColumnContainer>
                        </ListContainerMobile>
                      </ContainerItem>
                    )
                  })}
                </ContainerMobile>
                <>
                  {enCasaAdded && (
                    <>
                      <InHouseLabel>Restaurante en casa</InHouseLabel>
                      <ProductListContainer>
                        <ContainerItem>
                          <ListContainerHome>
                            <LinkProduct to={`/product/tes`}>
                              <ImgContainer>
                                <Img src={atHomeImg} />
                              </ImgContainer>
                            </LinkProduct>
                            <ColumnContainer>
                              <StockContainer>
                                <Name>Servicio</Name>
                                <Name>Restaurante en Casa</Name>
                                {enCasa && enCasa.variation && enCasa.variation.reference && <Reference>{enCasa.variation.reference}</Reference>}
                              </StockContainer>
                              <ButtonMobile
                                onClick={() => {
                                  openModal('CONFIRM_DELETE', {
                                    title: '',
                                    description: '¿Está seguro de que desea eliminar la experiencia de restaurante en casa?',
                                    functionOK: removeEnCasa(),
                                  })
                                }}
                              >
                                <IconButton icon="cancel" />
                              </ButtonMobile>
                              <RowQuantityContainer>
                                <RowContainer>
                                  <PriceContainer>
                                    <span>$ {enCasa.variation.price}</span>
                                  </PriceContainer>
                                </RowContainer>
                              </RowQuantityContainer>
                            </ColumnContainer>
                          </ListContainerHome>
                        </ContainerItem>
                        <Disclaimer>
                          <img src={clock} alt={''} />
                          <span>
                            El tiempo mínimo para solicitar restaurante en casa es de <strong>24 horas</strong>
                          </span>
                        </Disclaimer>
                      </ProductListContainer>
                    </>
                  )}
                </>
                <ActionsCartContainer>
                  <Clean
                    onClick={() => {
                      openModal('CONFIRM_DELETE', {
                        title: '',
                        type: 'ok',
                        description: '¿Está seguro de que desea eliminar todos los productos del carrito?',
                        functionOK: clearCart(),
                      })
                    }}
                  >
                    Vaciar el carrito
                  </Clean>
                </ActionsCartContainer>
                <CartComment />
              </Column>
              <Column md={5} lg={4}>
                <CardDetail>
                  <h2>Detalle de compra</h2>
                  <Row>
                    <Column xs={8} sm={8} md={8}>
                      Subtotal:
                    </Column>
                    <Column xs={4} sm={4} md={4}>
                      $ {formatNumber(subtotal)}
                    </Column>
                  </Row>
                  <Row>
                    <Column xs={8} sm={8} md={8}>
                      Descuento:
                    </Column>
                    <Column xs={4} sm={4} md={4}>
                      $ {formatNumber(discount)}
                    </Column>
                  </Row>
                  <Row>
                    <Column xs={8} sm={8} md={8}>
                      Cupón:
                    </Column>
                    <Column xs={4} sm={4} md={4}>
                      - {couponValue.use && `$ ${formatNumber(couponValue.value)}`}
                    </Column>
                  </Row>
                  <Row>
                    <Column xs={8} sm={8} md={8}>
                      IVA:
                    </Column>
                    <Column xs={4} sm={4} md={4}>
                      $ {formatNumber(iva)}
                    </Column>
                  </Row>
                  <Row>
                    <Column xs={8} sm={8} md={8}>
                      Total:
                    </Column>
                    <Column xs={4} sm={4} md={4}>
                      $ {couponValue.use ? formatNumber(total - couponValue.value > 0 ? total - couponValue.value : 0) : formatNumber(total)}
                    </Column>
                  </Row>
                  {!modalOpen && !showFormCoupon && showButtonCoupon && (
                    <ApplyDiscount onClick={() => setShowFormCoupon(!showFormCoupon)}>
                      <img src={couponIcon} alt={''} /> Aplicar código promocional
                    </ApplyDiscount>
                  )}
                  {modalOpen && getMessageCoupon()}
                  {showFormCoupon && (
                    <CouponContainer>
                      <HearFormCoupon>
                        <h2>Código promocional</h2>
                        <IconButton onClick={() => setShowFormCoupon(!showFormCoupon)} icon="cancel" />
                      </HearFormCoupon>
                      <Row>
                        <Column md={12}>
                          <InputWrapper>
                            <label>{mcn ? 'Ingrese su cédula de identidad' : 'Ingresa documento de identidad'} </label>
                            <input
                              type="text"
                              name="document"
                              onChange={(e: any) => {
                                validateData(e.target.value, 'document')
                              }}
                              value={_document}
                            />
                          </InputWrapper>
                        </Column>
                        <Column md={12}>
                          <InputWrapper>
                            <label>{mcn ? 'Ingrese su código promocional' : 'Ingresa tu código promocional'}</label>
                            <input
                              type="text"
                              name="coupon"
                              onChange={(e: any) => {
                                validateData(e.target.value, 'coupon')
                              }}
                              value={_coupon}
                            />
                          </InputWrapper>
                        </Column>
                        <Column md={12}>
                          <InputWrapper>
                            <button onClick={() => checkCoupon()} disabled={disabled}>
                              Validar
                            </button>
                          </InputWrapper>
                        </Column>
                      </Row>
                    </CouponContainer>
                  )}
                  {!showButtonCoupon && <WhiteSpace />}
                  <Row>
                    <Column xs={8} sm={8} md={8}>
                      Cantidad de comensales
                    </Column>
                    <Column xs={4} sm={4} md={4}>
                      <QuantityContainer className="diners">
                        <QuantityButton backgroundcolor={primary} fontcolor={secondary} onClick={() => updateQuantityDiners(quantityDiners - 1)}>
                          <Icon color={secondary} name="minus" className="kronos-icon" />
                        </QuantityButton>
                        <Quantity backgroundcolor={primary}>{quantityDiners}</Quantity>
                        <QuantityButton backgroundcolor={primary} fontcolor={secondary} onClick={() => updateQuantityDiners(quantityDiners + 1)}>
                          <Icon color={secondary} name="plus" className="kronos-icon" />
                        </QuantityButton>
                      </QuantityContainer>
                    </Column>
                  </Row>
                  <Button backgroundColor={btnPrimary ? btnPrimary : '#c59d5f'} color={btnSecondary ? btnSecondary : 'white'} onClick={() => validateAmount()}>
                    Siguiente
                  </Button>
                  <Continue to={`/restaurant/${restaurantSlug}`}>Seguir Comprando</Continue>
                </CardDetail>
              </Column>
            </Row>
            <AmountModal
              style={{
                overlay: {
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  overflow: 'auto',
                  padding: '15px',
                  backgroundColor: 'rgba(0, 0, 0, 0.75)',
                  zIndex: 100,
                },
              }}
              ariaHideApp={false}
              isOpen={minOpen}
            >
              <>
                <ModalMinDataContainer padding={'30px 30px 0px 30px'} backgroundcolor={'#ffffff'}>
                  <Row>
                    <Column>
                      <img src={errorAmount} alt="art" width="80" className="modalIcon" />
                    </Column>
                    <Column>
                      <h2>Pedido incompleto</h2>
                      <p className="description">
                        El monto mínimo de compra es de
                        <br />
                        <span className="amount-modal">
                          USD {items[0].product.supplier && items[0].product.supplier.metaData && items[0].product.supplier.metaData.monto_minimo}.
                        </span>{' '}
                        Para poder realizar el <br /> pedido faltan
                        <span className="amount-modal">
                          USD {formatNumber((items[0].product.supplier && items[0].product.supplier.metaData ? items[0].product.supplier.metaData.monto_minimo : 0) - total)}
                        </span>
                      </p>
                    </Column>
                  </Row>
                </ModalMinDataContainer>
                <ModalMinDataContainer padding={'10px 30px 10px 30px'} backgroundcolor={'#f5f5f5'}>
                  <span className="arrow" />
                  <Row style={{ justifyContent: 'center' }}>
                    <Column sm={6}>
                      <ButtonContainer>
                        <Button backgroundColor={primary} color={secondary} onClick={() => setMinOpen(false)}>
                          Aceptar
                        </Button>
                      </ButtonContainer>
                    </Column>
                  </Row>
                </ModalMinDataContainer>
              </>
            </AmountModal>
          </>
        )
      }}
    </Modal>
  )
}

const AddTrackWithMutation = graphql(ADD_TRACKING_MUTATION)(withRouter(ProductsList))
export default AddTrackWithMutation
