import styled from 'styled-components'
import { Column, Row } from '../../components/Grid'
import { COLORS } from '../../styles/colors'
import Modal from 'react-modal'
import sizes from '../../styles/media'

export const Img = styled.img`
  width: 100%;
  height: 136px;

  @media ${sizes.md} {
    max-width: 100%;
    height: 110px;
  }
  @media ${sizes.lg} {
    max-width: 100%;
    height: 100%;
  }
`
type Props = {
  backgroundcolor?: string
  fontcolor?: string
}

type InfoContainerProps = {
  align?: string
}

export const StyledTitle = styled.h2`
  margin: 1rem 0;
  font-size: 1rem;
  letter-spacing: 1.08px;
  text-transform: uppercase;

  @media ${sizes.sm} {
    font-size: 1rem;
  }
`

export const RestaurantContainer = styled.div`
  box-shadow: 0 2px 4px 0 rgba(137, 137, 137, 0.3);
  .restautant-logo {
    width: 100%;
  }
`

export const CustomLink = styled.a`
  color: ${COLORS.camel};
  font-size: 0.929rem;
  letter-spacing: 1px;
  font-weight: 500;
  text-decoration: underline;

  &:focus,
  &:active {
    color: #4e8ee5;
  }
`

export const BannerRestaurantContainer = styled.div`
  width: 100%;
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  .wrapper {
    width: 100%;
    display: flex;
    margin-top: -15px;
    padding-bottom: 10px;
  }

  .restaurant-name {
    align-items: center;
    display: flex;
    color: ${COLORS.white};
    font-size: 20px;
    margin-left: 20px;
    font-family: 'DroidSerif';
    line-height: 1.2;
  }

  .restaurant-img {
    width: 80px;
    height: 100%;
    margin-left: 20px;
    border-radius: 50%;
  }

  @media ${sizes.sm} {
    & {
      width: unset;
      flex-direction: row;
      margin-top: unset;
    }
    .wrapper {
      width: unset;
      margin-top: unset;
      padding-bottom: unset;
      border-bottom: unset;
    }

    .restaurant-name {
      font-size: 24px;
      width: 290px;
      max-width: 290px;
      line-height: 1.5;
      padding: 16px 20px 16px 0;
    }

    .restaurant-img {
      padding: 16px 0;
      width: 90px;
      height: 100%;
    }
  }

  @media ${sizes.xl} {
    .restaurant-img {
      width: 105px;
      height: 100%;
    }
  }
`

export const BannersContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${sizes.md} {
    display: flex;
    flex-direction: row;
  }
`

type MainBannerProps = {
  img?: string
}

export const MainBanner = styled.div`
  background-image: none;
  position: relative;
  height: 100%;
  @media ${sizes.lg} {
    display: flex;

    background-image: url(${(props: MainBannerProps) => props.img});
    background-size: cover;
    background-position: 0 center;
    background-repeat: no-repeat;
  }
`

export const CategoryContainer = styled.div`
  z-index: 2;
  background-color: ${COLORS.teeth};
  padding-bottom: 1rem;
`

export const CategoryResponsive = styled.div`
  @media ${sizes.lg} {
    display: none;
  }
`

export const CategoryDesktop = styled.div`
  display: none;
  @media ${sizes.lg} {
    display: block;
  }
`

export const PromoBannerContainer = styled(Column)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-self: center;
`

export const Refdiv = styled.div`
  width: 100%;
`

export const StyledColumn = styled(Column)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0.5rem 0;

  @media ${sizes.md} {
    margin: 0;
  }
`

export const StyledRow = styled(Row)`
  padding-bottom: 10px;
  @media ${sizes.md} {
    padding-bottom: 15px;
  }
  @media ${sizes.lg} {
    padding-bottom: 20px;
  }
`

export const StyledAnimateBanner = styled.div`
  margin: auto;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const StyleContentText = styled.div`
  display: none;
  margin: 5px 3em;
  && h1 {
    font-size: 40px;
    margin: 0;
    margin-top: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
  }

  p {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.13;
    letter-spacing: normal;
  }

  @media ${sizes.lg} {
    display: initial;
  }
`

export const Discount = styled.div`
  font-size: 1rem;
  &.mobile {
    font-size: 0.96rem;
    display: flex;
    @media ${sizes.sm} {
      display: none;
    }
  }
  &.desktop {
    display: none;
    @media ${sizes.sm} {
      display: flex;
    }
  }
  width: 100%;
  align-items: center;
  background-color: #dad1c7;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.dark};
  padding: 10px 20px;
  strong {
    display: none;
    font-family: 'Roboto-Bold';
    font-size: 1.14rem;
    margin-right: 5px;
    @media ${sizes.md} {
      display: block;
    }
  }
  img {
    width: 17px;
    margin: 0 10px 0 0;
  }
`

export const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0.5rem 0 0;
`

export const InfoItem = styled.div`
  border-radius: 10px;
  background-color: ${COLORS.white};
  width: 25%;
  max-width: 270px;
  padding: 1rem 1.4rem;
  margin: 0;
  min-height: 138px;
  @media ${sizes.sm} {
    margin: 0 1rem;
    height: fit-content;
  }
`

export const InfoContainer = styled.div`
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  align-items: ${(props: InfoContainerProps) => props.align};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;

  img {
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0;
  }
  span {
    color: ${COLORS.camel};
    text-decoration: underline;
    cursor: pointer;
    &.dflex {
      display: flex;
      align-items: center;
      margin-top: 1rem;
      img {
        width: 5px;
        margin-bottom: -0.1rem;
        margin-left: 0.3rem;
      }
    }
  }
`

export const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: ${COLORS.purpleBrown};
`

export const ExperienceContainer = styled.div`
  width: 100vw;
  margin-left: calc(50% - 50vw);
  background: white;
  padding: 2rem 0;
`

export const CatalogWrapper = styled.div`
  width: 100vw;
  margin-left: calc(50% - 50vw);
  background: white;
  padding: 2rem 0;
  margin-top: 1rem;

  @media ${sizes.sm} {
    margin-top: 1rem;
  }
`

export const ExperienceWrapper = styled.div`
  label {
    display: flex;
    align-items: center;
    input {
      margin-right: 1rem;
    }
    span {
      padding: 1px 0 0;
    }
  }

  margin: 1rem 4.5rem 0;
`

export const ExperienceTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header {
    display: flex;
    align-items: center;
    img {
      width: 2.9;
      margin-right: 1.6rem;
    }
    h3 {
      margin: 0 0 0.3rem;
      font-size: 1.1rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: #000000;
    }
    p {
      margin: 0;
      font-size: 1rem;
      font-weight: 200;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #000000;
    }
  }
`

export const ExperienceInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const SliderWrapper = styled.div`
  width: 100vw;
  margin-left: calc(50% - 50vw);
`

export const QuantityWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 1rem;
  h3 {
    margin: 0 0 0.3rem;
    font-size: 1.1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #000000;
  }
  p {
    margin: 0;
    font-size: 1rem;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #000000;
  }

  @media ${sizes.md} {
    flex-direction: row;
  }
`

export const PeopleWrapper = styled.div`
  position: relative;
  opacity: 0;
  max-height: 0px;
  flex-direction: column;
  border-top: 1px solid #d2d2d0;
  transition: all 500ms ease-in-out;
  padding-top: 0;
  margin-top: 0;
  &.active {
    padding-top: 20px;
    margin-top: 20px;
    opacity: 1;
    max-height: 300px;
  }
`

export const Checkbox = styled.div`
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  border-radius: 2px;
  border: solid 1px #979797;
  display: block;
  margin-right: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  &.checked {
    background-color: ${COLORS.camel};
    border-color: ${COLORS.camel};
  }
`

export const TermsContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  label {
    color: #353535;
    font-size: 14px;
  }
  &.start {
    align-items: flex-start;
    label {
      margin-top: -0.1rem;
    }
  }
`

export const ModalContent = styled.div`
  display: flex;
  margin: auto;
  flex-direction: column;
  color: ${COLORS.dark};
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  padding: 0 0 30px;
  font-family: 'Roboto';
  h1 {
    font-size: 1.42rem;
    line-height: normal;
    text-align: center;
    font-weight: bold;
  }
  p {
    font-size: 1rem;
    line-height: 1.36;
    text-align: center;
    max-width: 400px;
  }
  ul {
    text-align: left;
    max-width: 340px;
    li {
      color: ${COLORS.camel};
      span {
        color: ${COLORS.dark};
      }
    }
  }
  img {
    margin: auto;
    width: 60%;
    &.contact2 {
      width: 80%;
    }
  }
`

export const ButtonContainer = styled.div`
  margin-left: auto;
  width: 100%;
  padding: 20px 16px 4px 16px;
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLORS.brownGray};
  margin-bottom: 1rem;
`
export const Close = styled.div`
  position: absolute;
  top: 0.9rem;
  right: 1rem;
  cursor: pointer;
`
interface ModalProps {
  backgroundcolor?: string
  padding: string
}
export const ModalMinDataContainer = styled.div`
  background: ${(props: ModalProps) => props.backgroundcolor};
  padding: ${(props: ModalProps) => props.padding};
  position: relative;
  img {
    border-radius: 50%;
  }
  h2 {
    font-family: 'Roboto-Bold';
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.71px;
    color: ${COLORS.purpleBrown};
  }
  .description {
    display: flex;
    color: #000000;
    font-size: 14px;
    margin: 0.3rem 0;
    justify-content: space-between;

    .amount-modal {
      font-family: 'Roboto-Bold';
      font-weight: bold;
      color: #112340;
    }
    .bolder {
      font-family: 'Roboto-Bold';
      font-weight: bold;
    }
  }

  .arrow {
    clip-path: polygon(100% 0, 0 0, 50% 100%);
    position: absolute;
    top: 0;
    left: 50%;
    width: 35px;
    height: 15px;
    background: ${COLORS.white};
    transform: translate(-50%);
  }

  .modalIcon {
    width: 120px;
  }
  .subtitle {
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    span {
      font-weight: bold;
      color: ${COLORS.camel};
      font-family: 'Roboto-Bold';
    }
  }

  @media ${sizes.sm} {
    .description {
      margin: 0.3rem 0;
    }
  }
`
export const AmountModal = styled(Modal)`
  position: relative;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  outline: none;
  /* padding: 30px; */
  max-width: 400px;
  width: 100%;
  text-align: center;
  z-index: 100;
  transform: translate(-50%, -50%);
`

export const QuantityButton = styled.button`
  ${(props: Props) => {
    return `
    background-color: ${props.backgroundcolor};
    color: ${props.fontcolor};
    border: solid 1px ${props.backgroundcolor};
    outline: none;
    cursor: pointer;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 4px;
    height: 2rem;
    `
  }};
`

export const QuantityContainer = styled.div`
  display: flex;
  height: 28px;
  width: 75px;
  margin-top: 0.5rem;

  @media ${sizes.xs} {
    height: auto;
    width: auto;
  }

  @media ${sizes.sm} {
    height: 28px;
    margin-top: 0m;
  }
`

export const Quantity = styled.div`
  ${(props: Props) => {
    return `
      border: solid 1px ${props.backgroundcolor};
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      padding-top: 1px;
      height: 2rem;
      `
  }};
`
