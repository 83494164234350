import React from 'react'
import Modal from 'react-modal'
import { LoaderContainer, Gif } from './style'
import loaderGif from '../../assets/loader/loader.gif'

export default function Loader() {
  const loaderStyles = {
    content: {
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      zIndex: 101,
      padding: '0 0 10rem 0',
      backgroundColor: 'transparent',
      border: 'none',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
    },
  }

  Modal.setAppElement('body')

  return (
    <LoaderContainer style={loaderStyles} isOpen={true}>
      <Gif src={loaderGif} />
    </LoaderContainer>
  )
}
