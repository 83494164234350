import styled from 'styled-components'
import { Container, Column, Row } from '../../components/Grid'

export const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
`

export const StyledColumn = styled(Column)`
  display: flex;
  flex-direction: column;
  align-self: center;
`

export const StyledImage = styled.img`
  max-width: 100%;
  margin: auto;
`
export const Wrapper = styled.div`
  height: 100%;
`

export const StyledRow = styled(Row)`
  height: 100%;
`
