import React, { createRef, useContext, useState } from 'react'
import { Query, QueryResult } from 'react-apollo'
import Supplier from '../../model/Supplier'
import { GET_SUPPLIERS_QUERY } from './query'

import Loader from '../Loader'
import { Recommended } from '../Recommended'
import RelatedRestaurants from '../RelatedRestaurants'
import LocationContext from '../../context/LocationContext'
import { ImpressionEvent } from '../../helpers/GTM/ImpressionEvent'
import { GtmEventsLoad } from '../../helpers/gtm'

interface DataSuppliers {
  suppliers: {
    nodes: Supplier[]
  }
}

interface Filter {
  s: String
  city: String
  latitude?: number
  longitude?: number
}

interface Options {
  limit: Number
  sortBy: String
}

interface Variables {
  filters: Filter
  options: Options
}

function Suppliers(props: any) {
  const { city, withCoverage } = useContext(LocationContext)
  const items = 100
  const { mcn } = props
  const [selectedCity, setSelectedCity] = useState({
    value: 'all',
    label: 'Seleccione una ciudad',
  })
  const myRef = createRef<HTMLDivElement>()

  let filters: Filter = { s: '', city }

  if (withCoverage) {
    filters = {
      ...filters,
      city,
    }
  }

  const suppliersVariables: Variables = {
    filters,
    options: {
      limit: items,
      sortBy: 'priority',
    },
  }

  return (
    <Query<DataSuppliers, Variables> query={GET_SUPPLIERS_QUERY} variables={suppliersVariables}>
      {({ loading, error, data }: QueryResult<DataSuppliers, Variables>) => {
        if (loading) {
          return <Loader />
        }

        if (error) {
          return <p>Error</p>
        }

        if (!data || !data.suppliers) {
          return <p>No Data</p>
        }

        const suppliers = data.suppliers.nodes ? data.suppliers.nodes : []

        GtmEventsLoad(new ImpressionEvent().initialize(suppliers))

        const supplierCategories: string[] = []
        suppliers.forEach((item: Supplier) => {
          if (item.metaData.recomendado && item.metaData.recomendado !== '') {
            if (!supplierCategories.includes(item.metaData.recomendado)) {
              supplierCategories.push(item.metaData.recomendado)
            }
          }
        })

        const filteredSuppliers = suppliers
          ? suppliers
              .filter((supplier: Supplier) => {
                if (selectedCity.value == 'all') {
                  return true
                }
                return supplier.city.toLowerCase() === selectedCity.value.toLowerCase()
              })
              .sort((a: Supplier, b: Supplier) => {
                return a.legal_name > b.legal_name ? 1 : -1
              })
          : []

        const recommendedSuppliers = filteredSuppliers
          ? filteredSuppliers
              .filter((supplier: Supplier) => {
                return supplier.metaData.recomendado == 'si'
              })
              .sort((a: Supplier, b: Supplier) => {
                return a.legal_name > b.legal_name ? 1 : -1
              })
          : []

        const notRecommendedSuppliers = filteredSuppliers
          ? filteredSuppliers
              .filter((supplier: Supplier) => {
                return !supplier.metaData.recomendado || (supplier.metaData.recomendado && supplier.metaData.recomendado !== 'si')
              })
              .sort((a: Supplier, b: Supplier) => {
                return a.legal_name > b.legal_name ? 1 : -1
              })
          : []
        return (
          <>
            <div ref={myRef} />

            <Recommended suppliers={recommendedSuppliers} title={`Nuestros recomendados${city ? ` en ${city}` : ''}`} />
            <RelatedRestaurants restaurants={notRecommendedSuppliers} title={`Más restaurantes${city ? ' en tu ciudad' : ''}`} />
          </>
        )
      }}
    </Query>
  )
}

export default Suppliers
