import styled from 'styled-components'
import { Link } from 'react-router-dom'
import sizes from '../../styles/media'
import { COLORS } from '../../styles/colors'
import { Column } from '../Grid'
import Select from 'react-select'

export const StyledColumn = styled(Column)`
  display: flex;
`

export const StyledRightColumn = styled(Column)`
  display: flex;
  justify-content: flex-end;
`

export const StyledSelect = styled(Select)`
  width: 70%;
`

export const FlexCol = styled.div`
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* position: relative; */
  label,
  span {
    margin-bottom: 7px;
    width: 30%;
    font-weight: bold;
    text-align: center;
  }
  span {
    margin-right: 10px;
    margin-top: 0px;
  }

  @media ${sizes.sm} {
    width: 90%;
  }
`

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-shadow: 0 2px 4px 0 rgba(137, 137, 137, 0.3);
  padding: 1.5rem;
  border: 2px solid;
  border-color: transparent;
  background-color: white;
  margin: 1rem 0;
  width: 100%;
  flex: 1;
  &:hover {
    border-color: ${COLORS.primary};
    box-shadow: 0 4px 8px 0 rgba(117, 120, 123, 0.6);
    cursor: pointer;
  }
`

export const ImgContainer = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`

export const Img = styled.img`
  width: 100%;
  max-width: 100%;
`

export const ProductInfoContainer = styled.div`
  display: block;
  margin-bottom: 0.7rem;
  max-height: 8rem;
  overflow: hidden;
`

export const ProductPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 0.7rem;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  letter-spacing: 1px;
`

export const TitleCenter = styled.h2`
  margin-top: 21px;
  font-size: 18px;
  text-align: center;
  @media ${sizes.xs} {
    margin-bottom: 10px;
  }
  @media ${sizes.md} {
    font-size: 24px;
    text-align: left;
    margin-bottom: 24px;
  }
`

export const ProductContainerResponsive = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  grid-auto-rows: minmax(min-content, max-content);
`

export const ProductResponsive = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(137, 137, 137, 0.3);
  background-color: white;
  padding: 0.5rem;
  height: 100%;
`

export const LinkResponsive = styled(Link)`
  color: black;
`

export const PriceContainer = styled.div`
  display: flex-end;
`

export const StyledTitle = styled.h2`
  margin: 0;
  margin-top: 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.08px;
  color: ${COLORS.purpleBrown};
  text-transform: uppercase;
`
