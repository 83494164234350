import React, { useState, useContext, useEffect } from 'react'
import { Query, QueryResult } from 'react-apollo'

import Loader from '../../../../components/Loader'
import { Column, Row, Container } from '../../../../components/Grid'
import experienceImg from '../../../../assets/catalog/experiencia.svg'
import Icon from '../../../../components/Icon'

import {
  Title,
  ExperienceContainer,
  ExperienceWrapper,
  ExperienceTitle,
  ExperienceInfo,
  PeopleWrapper,
  Checkbox,
  TermsContainer
} from '../../style'

import CartContext from '../../../../context/CartContext'
import formatNumber from '../../../../helpers/formatNumber'

import { GET_VARIATIONS_QUERY } from './query'

interface Variations {
  id: string
  reference: string
  price: number
  options: [
    {
      name: string
      feature: {
        name: string
      }
    }
  ]
}
interface DataSupplier {
  variationsAtHome: [Variations]
}

interface Variables {
  supplierId: String
}

interface IProps {
  supplier: string
}
function AtHomeWidget({ supplier }: IProps) {
  const supplierVariables = {
    supplierId: supplier
  }
  const enCasa = JSON.parse(window.localStorage.getItem('encasa') || '{}')
  const { updateEnCasa } = useContext(CartContext)
  const [homeChecked, setHome] = useState(
    enCasa && enCasa.added ? enCasa.added : false
  )
  const [plateChecked, setPlate] = useState(
    enCasa && enCasa.plate ? enCasa.plate : false
  )


  const [mcn, setMcn] = useState(false);
  const [count] = useState(enCasa && enCasa.quantity ? enCasa.quantity : 1)
  useEffect(() => {
    const strmcn =window.sessionStorage.getItem("mcn");
    if (strmcn === "1") {
      setMcn(true);
    }
  }, [])
  return (
    <Query<DataSupplier, Variables>
      query={GET_VARIATIONS_QUERY}
      variables={supplierVariables}
    >
      {({ loading, error, data }: QueryResult<DataSupplier, Variables>) => {
        if (loading) {
          return <Loader />
        }

        if (error) {
          return <p></p>
        }

        if (!data) {
          return <p></p>
        }
        const variations = data.variationsAtHome
        const selectVariation = (
          newHome?: boolean,
          newPlate?: boolean,
          newQuantity?: number
        ) => {
          const response = variations.filter((item: Variations) => {
            let plate = false
            let people = false
            const includePlate = newPlate ? 'si' : 'no'
            item.options.forEach(element => {
              if (
                element.feature &&
                element.feature.name.toLowerCase() === 'vajilla' &&
                element.name.toLowerCase() === includePlate
              ) {
                plate = true
              }
              if (
                element.feature &&
                element.feature.name.toLowerCase() === 'asistentes' &&
                parseInt(element.name) === newQuantity
              ) {
                people = true
              }
            })
            return plate && people
          })

          const currentVariation = response.length > 0 ? response[0] : {}
          const newItem = {
            added: newHome,
            variation: currentVariation,
            quantity: newQuantity,
            plate: newPlate
          }
          updateEnCasa && updateEnCasa(newItem)
        }

        const handlePlate = () => {
          selectVariation(homeChecked, !plateChecked, count)
          setPlate(!plateChecked)
        }

        const handleAddHome = () => {
          selectVariation(!homeChecked, plateChecked, count)
          setHome(!homeChecked)
        }
        return (
          <>
            <Row>
              <Column>
                <Title>
                  <p>Experiencia restaurante en casa</p>
                </Title>
              </Column>
            </Row>
            <ExperienceContainer>
              <Container>
                <Row>
                  <Column>
                    <ExperienceTitle>
                      <div className="header">
                        <img src={experienceImg} />
                        <ExperienceInfo>
                          <h3>
                            Por
                            {` $${formatNumber(
                              variations && variations.length > 0
                                ? variations[0].price
                                : 0
                            )} `}
                            adicionales {!mcn ? 'recibes' : 'recibe'} un servicio personalizado de
                            delivery para dejar todo listo en {!mcn ? 'tu' : 'su'} mesa.
                          </h3>
                          <p>Solicítalo con 24 horas de anticipación.</p>
                        </ExperienceInfo>
                      </div>
                    </ExperienceTitle>
                    <ExperienceWrapper>
                      <TermsContainer onClick={() => handleAddHome()}>
                        <Checkbox className={homeChecked ? 'checked' : ''}>
                          {homeChecked == true ? (
                            <Icon
                              color="white"
                              size="11px"
                              name="ok"
                              className="kronos-icon"
                            />
                          ) : (
                            <div />
                          )}
                        </Checkbox>
                        <label>
                          <span>Agregar servicio</span>
                        </label>
                      </TermsContainer>
                      <PeopleWrapper className={homeChecked ? 'active' : ''}>
                        <TermsContainer
                          onClick={() => handlePlate()}
                          className="start"
                        >
                          <Checkbox className={plateChecked ? 'checked' : ''}>
                            {plateChecked == true ? (
                              <Icon
                                color="white"
                                size="11px"
                                name="ok"
                                className="kronos-icon"
                              />
                            ) : (
                              <div />
                            )}
                          </Checkbox>
                          <label>
                            <span>
                              Incluir vajilla <br />
                              *Sin costo adicional
                            </span>
                          </label>
                        </TermsContainer>
                      </PeopleWrapper>
                    </ExperienceWrapper>
                  </Column>
                </Row>
              </Container>
            </ExperienceContainer>
          </>
        )
      }}
    </Query>
  )
}

export default AtHomeWidget
