import React from 'react'
//import { consts } from 'react-elastic-carousel'

import {
  SliderContainer
  // StyledCarousel,
  // ArrowContainer,
  // ImgLink,
  // StyledList,
  // StyledPaginationImg
} from './style'

import Assets from '../../model/Assets'
//import Icon from '../Icon'
//import LazyImage from '../LazyImage'

type Images = {
  assets: Assets[]
  alt?: string
}

const SliderProduct = ({ assets, alt }: Images) => {
  /*   const settings = {
    itemsToShow: 1,
    showArrows: false,
    initialFirstItem: 0,
    renderArrow: ({ type, onClick }: any) => {
      const pointer =
        type === consts.PREV ? (
          <Icon
            color="black"
            size="23px"
            name="left-open"
            className="kronos-icon"
          />
        ) : (
          <Icon
            color="black"
            size="23px"
            name="right-open"
            className="kronos-icon"
          />
        )
      return (
        <ArrowContainer
          position={type === consts.PREV ? 'left' : 'right'}
          onClick={onClick}
        >
          {pointer}
        </ArrowContainer>
      )
    },
    renderPagination: ({ pages, onClick }: any) => {
      return (
        <ImgLink>
          <StyledList>
            {assets.map((item, index) => {
              return (
                <li key={pages[index]} onClick={() => onClick(pages[index])}>
                  <StyledPaginationImg src={item.url} />
                </li>
              )
            })}
          </StyledList>
        </ImgLink>
      )
    }
  } */
  return (
    // <SliderContainer>
    //   <StyledCarousel {...settings}>
    //     {assets.map((item, index) => {
    //       // return <LazyImage src={item.url} key={`slider-image-${index}`} />
    //       return <img src={item.url} key={`slider-image-${index}`}></img>
    //     })}
    //   </StyledCarousel>
    // </SliderContainer>
    <SliderContainer>
      <img
        className="img-product"
        src={assets && assets.length > 0 ? assets[0].url : ''}
        /* src={
          assets && assets.length > 0
            ? assets.length > 1
              ? assets[1].url
              : assets[0].url
            : ''
        } */
        alt={alt}
      ></img>
    </SliderContainer>
  )
}

export default SliderProduct
