import styled from 'styled-components'

interface CircleProps{
  color?: string
  size : string
  margin: string
}

export const BagdeCircle = styled.div<CircleProps>`
margin: ${props => props.margin ? props.margin:'0px'};
background-color: ${props => props.color};
width: ${props => props.size};
height: ${props => props.size};
border-radius: ${props => props.size};
text-align: center;
display: flex;
align-items: center;
justify-content: center;
`
export const BagdeContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
`
