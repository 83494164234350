import React, { useState } from 'react'
import { ApolloConsumer } from 'react-apollo'
import gql from 'graphql-tag'
import Select from 'react-select'
import CreatableSelect from 'react-select/lib/Creatable'
import { ContainerForm, FlexCol, Hr } from '../../style'
import * as Grid from '../../../../components/Grid'
import { StyledNewChoice, StyledAction, StyledNewData, StyledFlexCol, TextAreaElements, Element, CountContainer } from './style'
import { Input, Error, Textarea } from '../../../../components/FormElements'
import { Spacer } from '../../../../components/Global/style'
import { customStyle, errorStyle } from '../../../../helpers/selectProps'
import { SelectableLocationItem, WritableLocationItem } from '../../types'
import { COLORS } from '../../../../styles/colors'

const GET_CITIES = gql`
  query getCitiesByStateId($stateId: String!, $filters: CityFilters, $options: CityOptions) {
    citiesByState(stateId: $stateId, filters: $filters, options: $options) {
      nodes {
        id
        name
      }
    }
  }
`

const GET_ZONES = gql`
  query getZonesByCityId($cityId: ID!, $filters: ZoneFilters, $options: ZoneOptions) {
    zonesByCity(cityId: $cityId, filters: $filters, options: $options) {
      nodes {
        id
        name
      }
    }
  }
`

export type ShippingAddressProps = {
  isDisabled: () => boolean
  isMobile: boolean
  nodes: any
  filters: { s: string }
  options: { limit: number }
  validateData: (value: string, id: string) => boolean
  province: SelectableLocationItem
  setProvince: (province: SelectableLocationItem) => void
  city: SelectableLocationItem
  setCity: (city: SelectableLocationItem) => void
  zone: SelectableLocationItem
  setZone: (zone: SelectableLocationItem) => void
  mainStreet: WritableLocationItem
  secondaryStreet: WritableLocationItem
  streetNumber: WritableLocationItem
  reference: WritableLocationItem
  letterCount: number
  setLetterCount: (letterCount: number) => void
  saveInLocalStorage: (value: string, id: string) => void
}

type Location = {
  id: string
  name: string
  postalcodeUrbano?: string
  value?: string
  label?: string
}

function ShippingAddress(props: ShippingAddressProps) {
  const {
    isDisabled,
    isMobile,
    nodes,
    filters,
    options,
    validateData,
    province,
    setProvince,
    city,
    setCity,
    zone,
    setZone,
    mainStreet,
    secondaryStreet,
    streetNumber,
    reference,
    letterCount,
    setLetterCount,
    saveInLocalStorage,
  } = props

  const [cities, setCities] = useState(JSON.parse(window.localStorage.getItem('cities') || '[{ "value": "", "label": "" }]'))

  const [zones, setZones] = useState(JSON.parse(window.localStorage.getItem('zones') || '[{ "value": "", "label": "" }]'))

  const states = nodes.map((state: Location) => {
    state.value = state.id
    state.label = state.name
    return state
  })

  const onSelectProvince = async (optionSelected: any) => {
    setProvince({
      value: optionSelected.value,
      label: optionSelected.label,
      error: false,
      style: customStyle,
    })

    await setCity({
      value: '',
      label: 'Seleccione una ciudad',
      error: false,
      style: customStyle,
    })

    await setZone({
      value: '',
      label: 'Ingrese un sector',
      error: false,
      style: customStyle,
    })

    saveInLocalStorage(optionSelected.value, 'province')
    saveInLocalStorage(optionSelected.label, 'provinceName')
  }

  const onSelectCity = (optionSelected: any, actionMeta: any) => {
    if (actionMeta.action != 'clear') {
      setCity({
        value: optionSelected.value,
        label: optionSelected.label,
        error: false,
        style: customStyle,
      })

      setZone({
        error: false,
        label: 'Ingrese un sector',
        value: '',
        style: customStyle,
      })

      saveInLocalStorage(optionSelected.value, 'city')
      saveInLocalStorage(optionSelected.label, 'cityName')
    }
  }

  const onSelectZone = (optionSelected: any, actionMeta: any) => {
    if (actionMeta.action != 'clear') {
      setZone({
        ...zone,
        error: false,
        label: optionSelected.label,
        value: optionSelected.value,
        style: customStyle,
      })

      saveInLocalStorage(optionSelected.value, 'zone')
      saveInLocalStorage(optionSelected.label, 'zoneName')
      return
    }
    setZone({
      error: true,
      label: '',
      value: '',
      style: errorStyle,
    })
  }

  return isDisabled() && isMobile ? (
    <Hr />
  ) : (
    <ContainerForm backgroundcolor={COLORS.white} isDisabled={isDisabled()}>
      <Grid.Row>
        <Grid.Column lg={6} md={6} sm={12}>
          <FlexCol id="flexcol">
            <ApolloConsumer>
              {client => (
                <StyledFlexCol>
                  <span>* Provincia</span>
                  <Select
                    id="province"
                    isDisabled={true}
                    options={states}
                    onChange={async (optionSelected: any) => {
                      const stateId = optionSelected.value
                      const { data } = await client.query({
                        query: GET_CITIES,
                        variables: { stateId, filters, options },
                      })
                      const cities = data.citiesByState.nodes.map((city: Location) => {
                        return {
                          value: city.id,
                          label: city.name,
                        }
                      })
                      window.localStorage.setItem('cities', JSON.stringify(cities))
                      setCities(cities)
                      onSelectProvince(optionSelected)
                    }}
                    value={province}
                    styles={province.style}
                    noOptionsMessage={() => 'No existe la provincia.'}
                    autoFocus={false}
                    className="select-control-container"
                    classNamePrefix="select-control"
                  />
                  <>{province.error ? <Error>Campo obligatorio, no puede estar vacio</Error> : <Spacer />}</>
                </StyledFlexCol>
              )}
            </ApolloConsumer>
          </FlexCol>
        </Grid.Column>
        <Grid.Column lg={6} md={6} sm={12}>
          <FlexCol>
            <ApolloConsumer>
              {client => (
                <StyledFlexCol>
                  <span>* Ciudad</span>
                  <Select
                    id="city"
                    isDisabled={true}
                    options={cities}
                    onChange={async (optionSelected: any, actionMeta: any) => {
                      const cityId = optionSelected.value
                      const { data } = await client.query({
                        query: GET_ZONES,
                        variables: { cityId, filters, options },
                      })
                      const zones = data.zonesByCity.nodes.map((zone: Location) => {
                        return {
                          value: zone.id,
                          label: zone.name,
                        }
                      })
                      window.localStorage.setItem('zones', JSON.stringify(zones))
                      onSelectCity(optionSelected, actionMeta)
                      setZones(zones)
                    }}
                    value={city}
                    styles={city.style}
                    noOptionsMessage={() => 'No existe la ciudad.'}
                    autoFocus={false}
                    className="select-control-container"
                    classNamePrefix="select-control"
                  />
                  <>{city.error ? <Error>Campo obligatorio, no puede estar vacio</Error> : <Spacer />}</>
                </StyledFlexCol>
              )}
            </ApolloConsumer>
          </FlexCol>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column lg={6} md={6} sm={12}>
          <StyledFlexCol empty={zones.length === 1}>
            <span>* Sector</span>
            <CreatableSelect
              styles={zone.style}
              options={zones}
              onChange={onSelectZone}
              value={zone}
              noOptionsMessage={() => 'Digite un sector.'}
              formatCreateLabel={(value: string) => (
                <StyledNewChoice>
                  <StyledNewData>{value}</StyledNewData> <StyledAction> + Agregar</StyledAction>
                </StyledNewChoice>
              )}
              autoFocus={false}
              classNamePrefix="select-control"
            />
            {zone.error ? <Error>{'Campo obligatorio, no puede estar vacio'}</Error> : <Spacer />}
          </StyledFlexCol>
        </Grid.Column>
        <Grid.Column lg={6} md={6} sm={12}>
          <FlexCol>
            <Input
              onBlur={(e: any) => {
                saveInLocalStorage(e.target.value, 'street1')
              }}
              onChange={(e: any) => {
                validateData(e.target.value, 'street1')
              }}
              id="street1"
              type="text"
              label="Calle Principal"
              borderColor="#2684FF"
              required
              value={mainStreet.value}
              error={mainStreet.error}
              disabled={isDisabled()}
            />
            {mainStreet.error ? <Error>{mainStreet.message}</Error> : <Spacer />}
          </FlexCol>
        </Grid.Column>
        <Grid.Column lg={6} md={6} sm={12}>
          <FlexCol>
            <Input
              onBlur={(e: any) => {
                saveInLocalStorage(e.target.value, 'street2')
              }}
              onChange={(e: any) => {
                validateData(e.target.value, 'street2')
              }}
              id="street2"
              type="text"
              label="Calle Secundaria"
              borderColor="#2684FF"
              required
              error={secondaryStreet.error}
              value={secondaryStreet.value}
              disabled={isDisabled()}
            />
            {secondaryStreet.error ? <Error>{secondaryStreet.message}</Error> : <Spacer />}
          </FlexCol>
        </Grid.Column>
        <Grid.Column lg={6} md={6} sm={12}>
          <FlexCol>
            <Input
              onBlur={(e: any) => {
                saveInLocalStorage(e.target.value, 'number')
              }}
              onChange={(e: any) => {
                validateData(e.target.value, 'number')
              }}
              id="number"
              type="text"
              label="Numeración"
              borderColor="#2684FF"
              required
              error={streetNumber.error}
              value={streetNumber.value}
              disabled={isDisabled()}
            />
            {streetNumber.error ? <Error>{streetNumber.message}</Error> : <Spacer />}
          </FlexCol>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column lg={6} md={6} sm={12}>
          <FlexCol>
            <Textarea
              onBlur={(e: any) => {
                saveInLocalStorage(e.target.value, 'reference')
              }}
              onChange={(e: any) => {
                validateData(e.target.value, 'reference')
              }}
              onKeyUp={(e: any) => {
                setLetterCount(reference.value.length)
              }}
              id="reference"
              maxLength={50}
              rows={3}
              label="Detalle de vivienda o referencia"
              borderColor="#2684FF"
              placeholder="Ingrese una referencia de su vivienda (Edificio, No. Piso, Departamento, etc)."
              required
              error={reference.error}
              value={reference.value}
              disabled={isDisabled()}
            />
            <TextAreaElements>
              <Element>{reference.error ? <Error>{reference.message}</Error> : <Spacer />}</Element>
              <CountContainer>{letterCount}/50</CountContainer>
            </TextAreaElements>
          </FlexCol>
        </Grid.Column>
      </Grid.Row>
    </ContainerForm>
  )
}

export default ShippingAddress
