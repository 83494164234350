import styled from 'styled-components'
import { COLORS } from '../../styles/colors'

interface Props {
  backgroundcolor?: string
  isDisabled?: boolean
}
export const ContainerForm = styled.div`
  opacity: ${(props: Props) => (props.isDisabled ? '0.3' : '1')};
  ${(props: Props) => {
    return `
      width: 100%;
      background-color: ${props.backgroundcolor};
      box-shadow: 0 2px 4px 0 #d9d9d9;
      padding: 31px 32px 0px 33px;
      margin-bottom: 0.2rem;
      h2 {
        margin-bottom: 5px;
      }
      &:focus {
        border: solid 1px #2684FF;
      }
    `
  }}
  &.bp {
    padding: 31px 32px 28px 33px;
  }
`

export const Hr = styled.hr`
  border: 1px solid ${COLORS.veryLightPink};
  margin-top: 0;
  margin-bottom: 28px;
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  label,
  span {
    margin-bottom: 7px;
  }
  span {
    margin-top: 0px;
  }
`

export const Span = styled.span`
  margin-bottom: 7px;
`

export const ContainerElements = styled.div`
  margin-bottom: 1rem;
`

export const CustomInput = styled.div`
  width: 100%;
  position: relative;
`

interface SectionTitleProps {
  hasGrayedText: boolean
}
export const SectionTitle = styled.h3`
  color: ${(props: SectionTitleProps) =>
    props.hasGrayedText ? COLORS.veryLightPink : ''};
`
