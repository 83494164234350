import React, { useContext } from "react";
import ThemeContext from "../../context/ThemeContext";
import { Button } from "../Buttons";
import {
  IconContainer,
  Body,
  ConfirmContainer,
  Wrapper,
  Spacer,
  CircleIcon
} from "./style";
import Icon from "../Icon";

interface ModalContainerProps {
  children: React.ReactNode;
  icon?: string;
  backgroundIcon?: string;
  functionOK?: () => void;
  functionClose?: () => void;
}

const ModalContainer = ({
  children,
  icon,
  backgroundIcon,
  functionOK,
  functionClose
}: ModalContainerProps) => {
  const { primary, secondary } = useContext(ThemeContext);

  return (
    <Wrapper>
      {icon ? (
        <IconContainer>
          <CircleIcon backgroundColor={backgroundIcon}>
            <Icon
              color="white"
              name={icon ? icon : "ok"}
              size="24px"
              className="kronos-icon"
            />
          </CircleIcon>
        </IconContainer>
      ) : (
        ""
      )}
      <Body>{children}</Body>
      <ConfirmContainer>
        <Button
          backgroundColor="#75787b"
          color={secondary}
          onClick={functionClose}
        >
          Cancelar
        </Button>
        <Spacer />
        <Button
          backgroundColor={primary}
          color={secondary}
          onClick={() => {
            functionOK && functionOK();
            functionClose && functionClose();
          }}
        >
          Aceptar
        </Button>
      </ConfirmContainer>
    </Wrapper>
  );
};

export default ModalContainer;
