import React from 'react'
import Address from '../../model/Address'

type Props = {
  children: (hasCart: boolean) => React.ReactNode
}

class BillingViewHandler extends React.Component<Props> {
  render() {
    const { children } = this.props

    const address: Address = JSON.parse(
      window.localStorage.getItem('address') || '{}'
    )

    const cart = JSON.parse(window.localStorage.getItem('items') || '[]')

    if (
      (Object.entries(address).length === 0 &&
        address.constructor === Object) ||
      !cart ||
      cart.length <= 0
    ) {
      return children(false)
    }

    return children(true)
  }
}

export default BillingViewHandler
