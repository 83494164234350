const localStorageItems = [
  'identification',
  'showMessage',
  'chance',
  'isTestBenefit',
  'member-data',
  'prospect-data',
  'invoiceData',
  'isMember',
  'billing-back',
  'response',
  'paymentURL',
  'expireMemberData',

]

export const removeFromStorage = () => {
  localStorageItems.forEach((item: string) => {
    window.localStorage.removeItem(item)
  })
}

export const removeFromStorageExcluding = (exclusions: string[]) => {
  const itemsToRemove = exclusions.length > 0 ? filterExcludedItems(localStorageItems, exclusions) : localStorageItems

  itemsToRemove.forEach((item: string) => {
    window.localStorage.removeItem(item)
  })
}

const filterExcludedItems = (localStorageItems: string[], exclusions: string[]): string[] => {
  return localStorageItems.filter(item => !exclusions.includes(item))
}
