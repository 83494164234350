import React from "react";
import { toast } from "react-toastify";

import Badge from "../Badge";
import Icon from "../Icon";
import {
  ToastContainerBox,
  ToastContainer,
  ToastIconContainer,
  ToastText,
  TextContainer
} from "./style";

import "react-toastify/dist/ReactToastify.css";

interface ToastActions {
  notify: (iconName: string, message: string) => any;
}

interface ToastRootProps {
  children?: (actions: ToastActions) => React.ReactNode;
}

class Notification extends React.Component<ToastRootProps> {
  notify = (type: string, message: string) => {
    let color = "";
    let icon = "";
    switch (type) {
      case "fail": {
        color = "#ff4337";
        icon = "cancel";
        break;
      }
      case "ok": {
        color = "#6da531";
        icon = "ok";
        break;
      }
      case "alert": {
        color = "#ffb546";
        icon = "attention-alt";
        break;
      }
      default: {
        color = "#75787b";
        break;
      }
    }
    toast(() => {
      return (
        <ToastContainerBox color={color}>
          <ToastIconContainer>
            <Badge size="m">
              <Icon name={icon} />
            </Badge>
          </ToastIconContainer>
          <TextContainer>
            <ToastText>{message}</ToastText>
          </TextContainer>
        </ToastContainerBox>
      );
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.props.children && this.props.children({ notify: this.notify })}
        <ToastContainer
          position={"top-right"}
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          draggable
          pauseOnHover
          closeButton={false}
          style={{ top: "8rem" }}
        />
      </React.Fragment>
    );
  }
}

export default Notification;
