import React from 'react'
import { Container, Row, Column } from '../../components/Grid'
import {
    FlexCol,
    FlexRow,
    StyledResponsiveContactUs,
    ImgEstacionCompra,
    SpanCopyright,
    SpanConditionUp,
    ContainerTerms,
    FlexContainer
} from './style'

import callus from '../../assets/footer/callus-gt.svg'
import estacionCompra from '../../assets/footer/diners-logo-white.svg'

const Footer = () => {
  return (
    <Container>
      <Row>
        <Column sm={12} md={12} lg={12}>
          <FlexRow>
            <FlexCol>
              <SpanCopyright>PATROCINADO POR: </SpanCopyright>
              <ImgEstacionCompra src={estacionCompra} />
            </FlexCol>
            <FlexCol>
              <SpanConditionUp>
                <SpanCopyright>Derechos reservados. Diners Club Ecuador 2020</SpanCopyright>
                <ContainerTerms href={'https://medias.grandetable.com.ec/docs/tyc_grande_table_delivery.pdf'} target={'_blank'}>
                  Términos y condiciones
                </ContainerTerms>
              </SpanConditionUp>
              <a href="tel:02-500-1100">
                <img src={callus} />
              </a>
            </FlexCol>
          </FlexRow>
          <StyledResponsiveContactUs xs={6} md={0}>
            <FlexContainer>
              <div>
                <a className="contactus" href="tel:02-500-1100">
                  <ImgEstacionCompra src={callus} />
                </a>
              </div>
            </FlexContainer>
          </StyledResponsiveContactUs>
          <StyledResponsiveContactUs xs={6} md={0}>
            <FlexContainer>
              <div>
                <SpanCopyright>PATROCINADO POR: </SpanCopyright>
                <ImgEstacionCompra src={estacionCompra} />
              </div>
            </FlexContainer>
          </StyledResponsiveContactUs>
          <StyledResponsiveContactUs>
            <SpanConditionUp className="mobile">
              <SpanCopyright>Derechos reservados. Diners Club Ecuador 2020</SpanCopyright>
              <ContainerTerms href={'https://medias.grandetable.com.ec/docs/tyc_grande_table_delivery.pdf'} target={'_blank'}>
                Términos y condiciones
              </ContainerTerms>
            </SpanConditionUp>
          </StyledResponsiveContactUs>
        </Column>
      </Row>
    </Container>
  )
}

export default Footer
