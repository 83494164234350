import React, { useContext } from 'react'
import Modal from 'react-modal'
import { CityBox, CityItem, ModalContent } from './style'
import { ModalPanel, ModalContainer, ModalText } from '../style'
import closeIcon from '../../../assets/header/close.svg'
import localizationIcon from '../../../assets/images/localization.svg'
import rightArrow from '../../../assets/images/right-arrow.svg'
import LocationContext from '../../../context/LocationContext'
import { cities } from '..'

type CitySelectorProps = {
  open: boolean
  closeModal: (cityChanged?: boolean) => void
}

Modal.setAppElement('body')

export default function CitySelector({ open, closeModal }: CitySelectorProps) {
  const { city, loadCity } = useContext(LocationContext)

  return (
    <ModalPanel overlayClassName="overlay" isOpen={open} onRequestClose={() => closeModal()} shouldCloseOnOverlayClick={false}>
      <ModalContainer>
        {city && (
          <div className="close-btn-row">
            <button onClick={() => closeModal()}>
              <img src={closeIcon} alt="x" />
            </button>
          </div>
        )}
        <ModalContent>
          <div className="image-cropper">
            <img src={localizationIcon} alt="localization" />
          </div>
          {city ? (
            <>
              <ModalText className="announcement">
                Lo mejor de Grande
                <br />
                Table en <span>{city}</span>
              </ModalText>
              <ModalText className="select-txt">Selecciona otra ciudad</ModalText>
            </>
          ) : (
            <>
              {city ? (
                <ModalText className="announcement">Próximamente llegaremos a tu sector.</ModalText>
              ) : (
                <ModalText className="announcement">
                  Disfruta de
                  <br />
                  Grande Table en:
                </ModalText>
              )}
              {city && <ModalText className="select-txt">Disfruta de Grande Table en:</ModalText>}
            </>
          )}
          <CityBox>
            <hr />
            {cities
              .filter(cityName => city !== cityName)
              .map((cityName, index) => (
                <div key={index}>
                  <div className="box">
                    <CityItem
                      onClick={() => {
                        loadCity && loadCity(cityName)
                        closeModal(true)
                      }}
                    >
                      {cityName}
                      <img src={rightArrow} alt="x" />
                    </CityItem>
                  </div>
                  <hr />
                </div>
              ))}
          </CityBox>
        </ModalContent>
      </ModalContainer>
    </ModalPanel>
  )
}
