export function openRestaurant(scheduleData: string) {
  const date = new Date()
  const tempDate = new Date()
  const tempDate2 = new Date()
  const tempDate3 = new Date()
  //let items = JSON.parse(window.localStorage.getItem('items') || '[]')
  const schedule = scheduleData ? JSON.parse(scheduleData.toString()) : ''

  const weekdays = getWeekdays()

  let currentSchedule
  if (scheduleData) {
    if (schedule.mondayToSunday) {
      currentSchedule = schedule['mondayToSunday']
    } else {
      currentSchedule = schedule[weekdays[tempDate3.getDay()]]
    }
  } else {
    currentSchedule = 'Desconocido'
  }

  if (currentSchedule.active && currentSchedule.from.label !== undefined && currentSchedule.to.label  !== undefined) {
    const fromArray = currentSchedule.from.label.split(':')
    const toArray = currentSchedule.to.label.split(':')
    if (fromArray.length > 0 && toArray.length > 0) {
      const today = date
      const from = new Date(
        tempDate.setHours(parseInt(fromArray[0]), parseInt(fromArray[1]))
      )
      const to = new Date(
        tempDate2.setHours(parseInt(toArray[0]), parseInt(toArray[1]))
      )
      if (today >= from && today <= to) {
        return true
      }
      return false
    }
    return false
  }
  return false
}
export function addHoursToCurrentDate(hourLabel: String): Date {
  const timeTokens = hourLabel.split(':')
  const currentDate = new Date()
  let hour = timeTokens[0]
  let seconds = timeTokens[1]
  return new Date(currentDate.setHours(parseInt(hour), parseInt(seconds)))
}

export function isOpenSoonToday(currentSchedule: any): boolean{
  const fromDate = addHoursToCurrentDate(currentSchedule.from.label);
  const toDate = addHoursToCurrentDate(currentSchedule.to.label);
  let isOpen = false;
  const today = new Date();
  if (today < fromDate && today < toDate) {
    isOpen = true;
  }
  return isOpen;
}
export function isOpenTomorrow(currentSchedule: any): boolean{
  const fromDate = addHoursToCurrentDate(currentSchedule.from.label);
  const toDate = addHoursToCurrentDate(currentSchedule.to.label);
  let isOpenTomorrow = false;
  const today = new Date();
  if (today > fromDate && today > toDate) {
    isOpenTomorrow = true;
  }
  return isOpenTomorrow;
}
export function getWeekdays(): string[]{
  return [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
  ]
}

export function getWeekDaysSpanish(): string[]{
  return [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sábado'
  ]
}

export function obtainSchedule (scheduleData: any, dayNumber: number  ){
  const weekdays = getWeekdays();
    if (!scheduleData) {
      return null
    }
    if (scheduleData.mondayToSunday) {
      return scheduleData['mondayToSunday']
    }
    return scheduleData[weekdays[dayNumber]]
}


export function getMessage (dayNumber: number, isOpenSoon: boolean, currentSchedule: any ){
  const today = new Date();
  const days = getWeekDaysSpanish();
  const timeArray = currentSchedule.from.label.split(':')
  let hour = timeArray[0]
  let seconds = timeArray[1].length == 1 ? `${timeArray[1]}0` : timeArray[1]
  if( dayNumber != today.getDay()){
    let nameDay = dayNumber == today.getDay()+1? 'mañana': `el ${days[dayNumber]}`
    return `Pídelo para ${nameDay}`
  }
  return isOpenSoon? 'Abre a las ' + `${hour}:${seconds}`: '';
}
