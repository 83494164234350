import React from 'react'
import Layout from '../Layout'
import { Row, Column } from '../Grid'
import Stepper from './components/Stepper'
import { Spacer } from './style'
import { COLORS } from '../../styles/colors'
import { GET_SUPPLIER_STATUS } from './query'
import { Query, QueryResult } from 'react-apollo'
import SupplierModel from '../../model/Supplier'
import Loader from '../../components/Loader'

type Props = {
  children: React.ReactNode
  hasLocationBar?: boolean
}
interface DataSupplier {
  supplierId: SupplierModel
}
interface Variables {
  supplierID: string
}

const isNotCartPage = () => {
  return location.pathname !== '/cart'
}
function Checkout({ children, hasLocationBar }: Props) {
  const items = JSON.parse(window.localStorage.getItem('items') || '{}')
  const supplierVariable = {
    supplierID: items && items.length > 0 && items[0].product && items[0].product.supplier ? items[0].product.supplier.id : '',
  }

  return (
    <Layout backgroundColor={COLORS.teeth} hasLocationBar={hasLocationBar ? hasLocationBar : false}>
      {
        <Query<DataSupplier, Variables> query={GET_SUPPLIER_STATUS} fetchPolicy="network-only" variables={supplierVariable}>
          {({ loading, error, data }: QueryResult<DataSupplier, Variables>) => {
            if (loading) {
              return <Loader />
            }

            if (error) {
              return null
            }

            if (data && data.supplierId) {
              const supplier = data.supplierId
              if (supplier.status == 'inactive') {
                window.localStorage.removeItem('items')
                window.location.reload()
              }
            }
            return null
          }}
        </Query>
      }
      <Row>
        <Column>{isNotCartPage() && <Stepper />}</Column>
      </Row>
      <Spacer />
      <Row>
        <Column>{children}</Column>
      </Row>
    </Layout>
  )
}

export default Checkout
