import styled from 'styled-components'
import { Link } from 'react-router-dom'
import sizes from '../../styles/media'
import Modal from 'react-modal'
import { COLORS } from '../../styles/colors'
interface Props {
  backgroundcolor?: string
  fontcolor?: string
}

export const InHouseLabel = styled.div`
    font-size: 18px;
    padding: 1rem 0 1rem 0;
    font-family: inherit;
`
export const HearFormCoupon = styled.div`
  position: relative;
  button{
    position: absolute;
    right: 0;
    top:0;
    background-color: transparent;
    color: ${COLORS.black};
    font-size: 18px
  }
`

export const ApplyDiscount = styled.p`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  border: 1px dashed ${COLORS.grayBold};
  padding: 1rem 1rem;
  border-radius: 10px;
  letter-spacing: normal;
  text-align: center;
  justify-content: center;
  cursor:pointer;
  font-family: 'Roboto';
  font-weight: 600;
  img{
    padding-right: 2rem;
  }
`
export const ErrorDiscount = styled.p`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  background-color: ${COLORS.white_1};
  padding: 1rem 1rem;
  border-radius: 10px;
  letter-spacing: normal;
  text-align: center;
  justify-content: center;
  cursor:pointer;
  font-family: 'Roboto';
  font-weight: 600;
  a{
    text-decoration: underline;
    font-weight: bold;
    display: contents;
  }
  img{
    padding-right: 2rem;
  }
  
`
export const SuccessDiscount = styled.p`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  background-color: rgba(121, 197, 95, 0.1);
  padding: 1rem 1rem;
  border-radius: 10px;
  letter-spacing: normal;
  text-align: center;
  justify-content: center;
  cursor:pointer;
  font-family: 'Roboto';
  font-weight: 600;
  span{
    display: contents;
    font-weight: bold;
  }
  a{
    text-decoration: underline !important;
    font-weight: bold;
    display: contents;
  }
  img{
    padding-right: 2rem;
  }
`
export const TotalItems = styled.p`
  height: 19px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  margin: 0 0 2rem 0;
  display: flex;
  justify-content:center;
  @media ${sizes.md} {
    justify-content:left;
  }
`
export const Order = styled.div`
  height: 28px;
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  display: flex;
  justify-content:center;
  @media ${sizes.md} {
    justify-content:left;
  }
`

export const CardDetail = styled.div`
  height: auto;
  padding: 30px 30px 24px;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: ${COLORS.white};
  h2{
    margin: unset;
    padding-bottom: 2rem;
  }
  div > div:nth-child(2){
     text-align: right;
  }
  div:nth-child(6) {
    font-weight: bold;
    font-size: 16px;
    border-bottom: 1px solid ${COLORS.ligthGray_1};
    border-top: 1px solid ${COLORS.ligthGray_1};
    margin:  1rem .1rem 0 .1rem;
    padding: 1rem 0 1rem 0 ;
  }
  div:nth-child(8){
    border-top: 1px solid ${COLORS.ligthGray_1};
    margin:  1rem .1rem 0 .1rem;
    padding: 1rem 0 1rem 0 ;
  }
  div:nth-child(-n+5) > div {
    padding-top: 0.5rem
  }
  div:nth-child(6) > div {
    padding: unset;
  }
  div:nth-child(8) > div {
    padding: unset;
    margin: auto auto;
  }
`

export const RowQuantityContainer = styled.div`
  display: grid;
  top: 6.6rem;
  position: absolute;
  align-items: flex-end;
  flex-direction: row;
  width: 100%;
  small{
    display:none;
  }
  @media ${sizes.md} {
    top: 5rem;
    small{
      display:block;
      padding-bottom: 7px;
    }
  }
`
export const ProductListContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: ${COLORS.white};
  margin-top: 1rem;
  width: 100%;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 #d9d9d9;
  div:nth-child(2){
     margin: unset;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  color: ${COLORS.white};
  background: ${COLORS.black};
  text-align: left;
`

export const Title = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 1rem;
`

export const Name = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin: 0.2rem;
  height: auto;
  width: 100%;
  overflow-wrap: break-word;

  @media ${sizes.md} {
    font-size: 14px;
    margin: 0;
  }
`

export const Reference = styled.div`
  font-size: 11px;
  margin: 0.2rem 0;
  color: ${COLORS.deepGray};
  white-space: pre-line;
  @media ${sizes.lg} {
    font-size: 13px;
    margin: 0.2rem 0;
  }
`

export const IconTitle = styled.div`
  font-weight: bold;
  margin: 0 4px;
  @media ${sizes.xl} {
    margin: 0 4px 0 0;
  }
`

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 1rem;
`

export const StockContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: .1rem;
  margin-right: 2rem;
`

export const ItemStock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 0 1rem 1rem;
  @media ${sizes.md} {
    margin-left: 1rem;
  }
  @media ${sizes.xl} {
    padding: 0;
    margin-left: 0;
  }
`

export const ImgContainer = styled.div`
  display: flex;
  border: solid 1px #d9d9d9;
  width: 120px;
  flex-direction: column;
  justify-content: center;

`

export const Img = styled.img`
  width: 100%;
  max-width: 100%;
  @media ${sizes.md} {
    width: 152px;
  }
`

export const Stock = styled.span`
  color: red;
`

export const Quantity = styled.div`
  ${(props: Props) => {
    return `
      border: solid 1px ${props.backgroundcolor};
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      padding-top: 4px;
      `
  }};
`

export const QuantityButton = styled.button`
  ${(props: Props) => {
    return `
    background-color: ${props.backgroundcolor};
    color: ${props.fontcolor};
    border: solid 1px ${props.backgroundcolor};
    outline: none;
    cursor: pointer;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 4px;
    height: 100%;
    `
  }};
`

export const QuantityContainer = styled.div`
  display: flex;
  height: 28px;
  width: 75px;

  &.diners {
    padding-top: 0 !important;
  }

  @media ${sizes.xs} {
    height: auto;
    width: auto;
  }

  @media ${sizes.sm} {
    height: 28px;
  }
`

export const Line = styled.div`
  margin: auto;
  border-bottom: solid 1px #d9d9d9;
  width: 100%;
`

export const ActionsCartContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  width: 100%;
`

export const Clean = styled.div`
  background: none;
  cursor: pointer;
  color: ${COLORS.black};
  letter-spacing: 1px;
  text-decoration: underline;
  font-size: 14px;
`

export const Continue = styled(Link)`
    text-decoration: underline;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${COLORS.camel};
    text-transform: uppercase;
    padding-top: 1rem;
    justify-content: center;
    display: -ms-flexbox;
    display: flex;
`

export const DetailPayment = styled.div`
  ${(props: Props) => {
    return `
      background-color: ${props.backgroundcolor};
      display: flex;
      flex-direction: column;
      font-weight: bold;
      line-height: 1.43;
      padding: 1rem 16px 4px 16px;
    `
  }}
`
export const DetailPaymentMobile = styled.div`
  ${(props: Props) => {
    return `
      background-color: ${props.backgroundcolor};
      display: flex;
      flex-direction: column;
      font-weight: bold;
      line-height: 1.43;
      padding: 1rem 16px 4px 16px;
      margin-left:auto;
      width:60%;
    `
  }}
`

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

export const SpanPayment = styled.span`
  ${(props: Props) => {
    return `
      color: ${props.fontcolor}
      font-weight: bold;
    `
  }}
`

export const SpanShipping = styled.span`
  ${(props: Props) => {
    return `
      color: ${props.fontcolor}
      font-weight: bold;
      margin-bottom:1rem;
      float:right;
      padding-right: 1rem;
      margin-top: 1rem;
      font-size: 12px;
      @media ${sizes.md} {
        font-size: 14px;
      }
    `
  }}
`

export const TotalContainer = styled.div`
  ${(props: Props) => {
    return `
    background-color: ${props.backgroundcolor};
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-weight: bold;
    padding: 1rem 16px 4px 16px;
  `
  }}
`

export const TotalContainerMobile = styled.div`
  ${(props: Props) => {
    return `
    background-color: ${props.backgroundcolor};
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-weight: bold;
    padding: 0rem 16px 4px 16px;
    margin-left:auto;
    width:60%;
  `
  }}
`

export const AmountsContainer = styled.div`
  justify-content: right;
  margin-left: auto;
  width: 25%;
`

export const AmountsContainerMobile = styled.div`
  justify-content: right;
  margin-left: auto;
  margin-top: 0.5rem;
  width: 100%;
`

export const ButtonContainer = styled.div`
  margin-left: auto;
  width: 100%;
  padding: 20px 16px 4px 16px;
`
export const ButtonContainerMobile = styled.div`
  margin-left: auto;
  padding: 1rem 16px 4px 16px;
  width: 100%;
`
export const ButtonMobile = styled.div`
  margin-left: auto;
  position: absolute;
  right: 0.5rem;
  top: 0rem;
  button{
    background-color: ${COLORS.red};
  }
`

export const ListContainerMobile = styled.div`
  display: flex;
  align-items: normal;
  flex-direction: row;
  position: relative;
  width: 100%;
`

export const ListContainerHome= styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
`
export const ContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 2px 4px 0 #d9d9d9;
  background: ${COLORS.white};
  padding: 1rem;
  border-radius: 10px;
 
`

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: .2rem;
  position: relative;
  width: 100%;
   @media ${sizes.md} {
     margin-left: 1rem;
  }
  a{
     margin-right: 2rem;
  }
`

export const RowContainer = styled.div`
  display: inline-flex;
`

export const PriceContainer = styled.div`
  padding-left: 0.5rem;
  font-weight: bold;
  margin: auto 0;
  font-size: 16px;
  right: .5rem;
  top: .6rem;
  position: absolute;
  @media ${sizes.md} {
    right: 2rem;
    top: 2rem;
  }
`

export const ContainerItem = styled.div`
  width: 100%;
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-bottom: 2px solid #dedede;
  &:last-child{
    border-bottom: transparent;
  }
`

export const LinkProduct = styled(Link)`
  color: black;
  
`

export const InfoPromoContainer = styled.div`
  background: #e3eed6;
  border: solid 1px #d2e4bd;
  display: flex;
  border-radius: 6px;
  padding: 1rem;
  margin: 1rem 1rem 0;
`

export const CustomIcon = styled.img`
  margin-right: 1rem;
  width: 6rem;
  max-width: 100%;
`

export const InfoText = styled.h4`
  font-weight: 400;
  margin: 0;
  font-size: 0.9rem;
`
export const H2 = styled.h2`
  margin: 1rem 0 1rem;
  font-size: 1.1rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-weight: bold;

  @media ${sizes.sm} {
    margin: 1rem 0 0.2rem;
  }
`
export const CouponContainer = styled.div`
  border: 1px dashed #414141;
  background-color: ${COLORS.white};
  border-radius: 10px;
  padding: 20px 30px;
  color: ${COLORS.dark};
  margin: 20px auto;
  h2 {
    margin: 0;
    font-size: 1.1rem;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  .terms {
    font-family: LucidaGrande;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${COLORS.camel};
    margin: 10px 15px 0;
    text-decoration: underline;
  }
`
export const InputWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-top: 15px;
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  justify-content: flex-end;
  input {
    border: solid 1px ${COLORS.black};
    background-color: ${COLORS.white};
    margin: 5px 0 0;
    padding: 1rem;
    border-radius: 10px;
    border: solid 1px ${COLORS.gray};
    
  }
  label{
    text-align: left;
  }
  button {
    background-color: ${COLORS.dark};
    border: 1px solid ${COLORS.dark};
    color: ${COLORS.white};
    text-transform: uppercase;
    margin: 5px 0 0;
    padding: 1rem;
    font-weight: 0.8rem;
    font-family: 'Roboto-Bold';
    border-radius: 10px;
    &:disabled {
      opacity: 0.4;
    }
  }
`
export const Circle = styled.div`
  width: 68px;
  min-width: 68px;
  max-width: 68px;
  height: 68px;
  min-height: 68px;
  max-height: 68px;
  border-radius: 50%;
  background-color: #f45151;
  display: flex;
  justify-content: center;
  align-items: center;
  &.valid {
    background-color: #96d76d;
  }
  margin: auto;
`

export const ModalContent = styled.div`
  display: flex;
  margin: auto;
  flex-direction: column;
  color: ${COLORS.dark};
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  padding: 0 0 30px;
  font-family: 'Roboto';
  h1 {
    font-size: 1.42rem;
    line-height: normal;
    text-align: center;
    font-family: 'Roboto-Bold';
  }
  p {
    font-size: 1rem;
    line-height: 1.36;
    text-align: center;
    max-width: 400px;
  }
  ul {
    text-align: left;
    max-width: 340px;
    li {
      color: ${COLORS.camel};
      span {
        color: ${COLORS.dark};
      }
    }
  }
  img {
    margin: auto;
    width: 60%;
    &.contact2 {
      width: 80%;
    }
  }
`

export const CouponModal = styled(Modal)`
  position: relative;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  outline: none;
  max-width: 600px;
  width: 100%;
  text-align: center;
  z-index: 100;
  transform: translate(-50%, -50%);
`

export const AmountModal = styled(Modal)`
  position: relative;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  outline: none;
  /* padding: 30px; */
  max-width: 400px;
  width: 100%;
  text-align: center;
  z-index: 100;
  transform: translate(-50%, -50%);
`
interface ModalProps {
  backgroundcolor?: string
  padding: string
}
export const ModalMinDataContainer = styled.div`
  background: ${(props: ModalProps) => props.backgroundcolor};
  padding: ${(props: ModalProps) => props.padding};
  position: relative;

  .description {
    color: #75787b;
    font-size: 14px;
    margin: 0 0 20px 0;

    .amount-modal {
      font-family: 'Roboto-Bold';
      font-weight: bold;
      color: #112340;
    }
  }

  .arrow {
    clip-path: polygon(100% 0, 0 0, 50% 100%);
    position: absolute;
    top: 0;
    left: 50%;
    width: 35px;
    height: 15px;
    background: ${COLORS.white};
    transform: translate(-50%);
  }

  .modalArt {
    width: 80px;
  }

  @media ${sizes.sm} {
    .description {
      margin: 0px 30px 30px 30px;
    }
  }
`

export const Disclaimer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: ${COLORS.black};
  margin: 1rem 0;
  strong {
    font-weight: bold;
  }
  img {
    width: 32px;
    margin-right: 0.5rem;
  }
`
export const WhiteSpace = styled.div`
  margin-top: 1rem;
`
