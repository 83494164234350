import { Comments } from './style'
import { Textarea } from '../FormElements'
import React, { useState } from 'react'

const CartComment = () => {
  const [commentsState, setComments] = useState({
    error: false,
    value: window.localStorage.getItem('user_comments') || '',
  })

  const validateComments = (value: string) => {
    if (!/^[ñÑáéíóúÁÉÍa-zA-Z0-9 ]*$/g.test(value)) {
      return true
    }

    setComments({error: false, value: value})
    window.localStorage.setItem('user_comments', value)
    return false
  }

  return (
    <>
      <Comments>Comentarios</Comments>
      <Textarea
        placeholder="Agregar comentarios o peticiones adicionales"
        onChange={(e: any) => {
          validateComments(e.target.value)
        }}
        rows={5}
        maxLength={250}
        borderColor="#2684FF"
        label=""
        id="address"
        error={commentsState.error}
        value={commentsState.value}
      />
    </>
  )
}

export default CartComment
