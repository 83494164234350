import React from 'react'
import { Link } from 'react-router-dom'

function Categories({ setSearchOpen }: any) {
  const toHome = (categoryName: string) => ({
    pathname: '/',
    state: { scrollToRestaurants: true, categoryName }
  })

  return (
    <>
      <p className="title">Categorías</p>
      <Link
        to={toHome('Gourmet')}
        onClick={() => {
          setSearchOpen(false)
        }}
      >
        <p className="content">Gourmet</p>
      </Link>
      <Link
        to={toHome('Casual')}
        onClick={() => {
          setSearchOpen(false)
        }}
      >
        <p className="content">Casual</p>
      </Link>
    </>
  )
}

export default Categories
