type pixelFacebookProps = {
  restaurant: any
  metaData: any
  schedule: any
}

const getSchedule = (schedule: any, day: string) => {
  const weekdays : { [key: string]: string } = {
    'sunday': 'Domingo',
    'monday': 'Lunes',
    'tuesday': 'Martes',
    'wednesday': 'Miércoles',
    'thursday': 'Jueves',
    'friday': 'Viernes',
    'saturday': 'Sábado'
  }
  return schedule[day] ? schedule[day].day : weekdays[day]
}

const getScheduleTimeFrom = (schedule: any, day: string) => {
  return schedule[day] ? schedule[day].from.value : schedule['mondayToSunday'].from.value
}

const getScheduleTimeTo = (schedule: any, day: string) => {
  return schedule[day] ? schedule[day].to.value : schedule['mondayToSunday'].to.value
}

const pixelFacebookStructure = (props: pixelFacebookProps) => {
  const { restaurant, metaData, schedule } = props
  const structureData = `{
    "@context": "https://schema.org",
    "@type": "Restaurant",
    "name": "${restaurant.business_name}",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "${metaData.sectores}",
      "addressLocality": "${restaurant.city}",
      "addressRegion": "UI",
      "addressCountry": "EC"
    },
    "servesCuisine": "${metaData.categoria}",
    "priceRange": "$$$",
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": "${ getSchedule(schedule,'monday') }",
        "opens": "${ getScheduleTimeFrom(schedule,'monday') }",
        "closes": "${ getScheduleTimeTo(schedule,'monday') }"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": "${ getSchedule(schedule,'tuesday') }",
        "opens": "${ getScheduleTimeFrom(schedule,'tuesday') }",
        "closes": "${ getScheduleTimeTo(schedule,'tuesday') }"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": "${ getSchedule(schedule,'wednesday') }",
        "opens": "${ getScheduleTimeFrom(schedule,'wednesday') }",
        "closes": "${ getScheduleTimeTo(schedule,'wednesday') }"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": "${ getSchedule(schedule,'thursday') }",
        "opens": "${ getScheduleTimeFrom(schedule,'thursday') }",
        "closes": "${ getScheduleTimeTo(schedule,'thursday') }"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": "${ getSchedule(schedule,'friday') }",
        "opens": "${ getScheduleTimeFrom(schedule,'friday') }",
        "closes": "${ getScheduleTimeTo(schedule,'friday') }"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": "${ getSchedule(schedule,'saturday') }",
        "opens": "${ getScheduleTimeFrom(schedule,'saturday') } ",
        "closes": "${ getScheduleTimeTo(schedule,'saturday') }"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": "${ getSchedule(schedule,'sunday') }",
        "opens": "${ getScheduleTimeFrom(schedule,'sunday') }",
        "closes": "${ getScheduleTimeTo(schedule,'sunday') }"
      }
    ],
    "acceptsReservations": "Si"
  }`
  return structureData
}

export default pixelFacebookStructure
