export const questionItems = [
  {
    question: `¿Cuál es el proceso de compra?`,
    answer: `
    Para realizar una compra en <strong>GRANDE TABLE DELIVERY</strong> , con descuentos especiales para los miembros del Club Gourmet, debe seguir los siguientes pasos:
    <ol>
      <li>Seleccionar el establecimiento que se desee realizar la compra</li>
      <li>Seleccionar el/los menú(s) del establecimiento y enviar al carrito de compras</li>
      <li>Ingresar al carrito de compras (en caso de ser socio McNamara colocar el código promocional enviado al correo)</li>
      <li>Ingresar los datos de entrega</li>
      <li>Ingresar los datos de facturación, en este paso se valida con la cédula de identidad si es socios Grande Table, en caso de que sea puede seguir con el proceso. En caso de no ser no le permitirá seguir.</li>
      <li>Realizar el pago en el Botón de Pagos</li>
      <li>Recibir el correo de confirmación</li>
    </ol>
`
  },
  {
    question: `¿Cuál es el canal de atención al cliente?`,
    answer: `Por favor para cualquier consulta comunícate con nuestro contact center al 02-5001-100`
  },
  {
    question: `¿En qué sectores realizan las entregas?`,
    answer: `El área de cobertura varía por restaurante. Por favor revisa la información en la página interna de cada restaurante para conocer si el servicio tiene cobertura en tu sector. `
  },
  {
    question: `¿Cuál es el tiempo estimado de entrega desde que realizo mi pedido?`,
    answer: `El tiempo de entregará dependerá de cada establecimiento. Por favor revisa la información en la página interna de cada restaurante para conocer los tiempos estimados de entrega. `
  },
  {
    question: `¿Cuál es descuento Grande Table que recibiré?`,
    answer: `El descuento dependerá de cada establecimiento. Por favor revisa la información en la página interna de cada restaurante para conocer el descuento. `
  },
  {
    question: `¿Qué pasa si mi pedido no llega en el rango de tiempo establecido por el restaurante?`,
    answer: `Por favor comunícate con nuestro contact center al 02-5001-100 para solventar el inconveniente. </p>`
  },
  {
    question: `¿Puedo realizar pedidos de varios establecimientos en un solo proceso de compra?`,
    answer: `No. No podrás realizar pedidos en varios establecimientos en una sola compra. Si deseas realizar un pedido en otro establecimiento deberás realizar nuevamente el proceso de compra.`
  },
  {
    question: `¿Cuál es el horario de atención para realizar pedidos?`,
    answer: `Los horarios de atención y entregan varían de acuerdo con cada establecimiento. Por favor revisa la información en la página interna de cada restaurante para conocer horarios de atención. `
  },
  {
    question: `¿Qué procesos de higiene siguen para le entrega y despacho de productos?`,
    answer: `Los productos comprados serán entregados en tu domicilio manejando normas de higiene estrictas debido a la emergencia Sanitaria COVID-19 y tomando en consideración procesos de entrega que permitan una adecuada conservación de alimentos. 
    `
  },
  {
    question: `¿Cuál es el costo de envío?`,
    answer: `Por tiempo limitado el envío no tendrá costo.  `
  },
  {
    question: `¿Cuáles son las condiciones de pago?`,
    answer: `Solo podrás realizar pagos corrientes, al momento no se encuentra habilitada la opción de diferir tu pago. A demás, recibirás un descuento utilizando tu tarjeta Grande Table. Puedes ver más información en nuestros <a target="_blank" href="https://medias.grandetable.com.ec/docs/tyc_grande_table_delivery.pdf">Términos y condiciones</a>.
    `
  },
  {
    question: `¿Cómo voy a recibir mi factura?`,
    answer: `Te informamos que la factura le llegará al correo registrado en el proceso de compra, será enviado por parte del restaurante que realizó el pedido.`
  },
  {
    question: `¿Qué debo hacer si no recibí el comprobante de pago?`,
    answer: `Por cada transacción aprobada a través de Place to Pay, recibirá un comprobante del pago, que será enviado a la dirección de correo electrónico que proporcionó al momento de pagar. En caso de no recibir el comprobante, podrá contactarse a: soporte@placetopay.com, para solicitar el reenvío del mismo a la dirección de correo electrónico registrada al momento de pagar.`
  },
  {
    question: `¿Qué pasa si no me llega el correo con mi comprobante?`,
    answer: `Por favor comunícate con nuestro contact center al número 02-5001-100 para que ellos puedan ayudarte con el inconveniente. `
  },
  {
    question: `¿Qué pasa si realice un pedido, pero deseo cancelarlo o devolverlo?`,
    answer: `Los productos no están sujeta a devoluciones, cambios ni cancelaciones, por favor revisa nuestros <a target="_blank" href="https://medias.grandetable.com.ec/docs/tyc_grande_table_delivery.pdf">Términos y condiciones.</a>`
  }
]
export const questionItemsP2P = [
  {
    question: `¿Qué es PlacetoPay?`,
    answer: `PlacetoPay es la plataforma de pagos electrónicos que usa la plataforma “Grande Table Delivery” para procesar en línea las transacciones generadas en la tienda virtual con las formas de pago habilitadas para tal fin.`
  },
  {
    question: `¿Cómo puedo pagar?`,
    answer: `En la tienda virtual de “Grande Table Delivery” podrás realizar tu pago con los medios habilitados para tal fin. De acuerdo a las opciones de pago escogidas por el comercio, podrá pagar a través Diners Club, TITANIUM Visa y Discover con pago corriente.`
  },
  {
    question: `¿Es seguro ingresar mis datos bancarios en este sitio web?`,
    answer: `<p>Para proteger tus datos ”Grande Table Delivery” delega en PlacetoPay la captura de la información sensible. Nuestra plataforma de pagos cumple con los más altos estándares exigidos por la norma internacional PCI DSS de seguridad en transacciones con tarjeta de crédito. Además tiene certificado de seguridad SSL expedido por GeoTrust una compañía Verisign, el cual garantiza comunicaciones seguras mediante la encriptación de todos los datos hacia y desde el sitio; de esta manera, te podrás sentir seguro a la hora de ingresar la información de su tarjeta.</p>
    <p>Durante el proceso de pago, en el navegador se muestra el nombre de la organización autenticada, la autoridad que lo certifica y la barra de 
    dirección cambia a color verde. Estas características son visibles de inmediato, dan garantía y confianza para completar la transacción en PlacetoPay.</p>
    <p>PlacetoPay también cuenta con el monitoreo constante de McAfee Secure y la firma de mensajes electrónicos con Certicámara.</p>
    <p>PlacetoPay es una marca de la empresa colombiana EGM Ingeniería Sin Fronteras S.A.S.</p>`
  },
  {
    question: `¿Puedo realizar el pago cualquier día y a cualquier hora? `,
    answer: `Sí, en “Grande Table Delivery” podrás realizar tus compras en línea los 7 días de la semana, las 24 horas del día a sólo un clic de distancia.`
  },
  {
    question: `¿Puedo cambiar la forma de pago?`,
    answer: `Si aún no has finalizado tu pago, podrás volver al paso inicial y elegir la forma de pago que prefieras. Una vez finalizada la compra no es 
    posible cambiar la forma de pago.`
  },
  {
    question: `¿Pagar electrónicamente tiene algún valor para mí como comprador?`,
    answer: `No, los pagos electrónicos realizados a través de PlacetoPay no generan costos adicionales para el comprador.`
  },
  {
    question: `¿Qué debo hacer si mi transacción no concluyó?`,
    answer: `En primera instancia deberás revisar si llegó un mail de confirmación del pago en tu cuenta de correo electrónico (la inscrita en el momento de realizar el pago), en caso de no haberlo recibido, deberás contactar al contact center al 02-500-1100 para confirmar el estado de la transacción.
    <p>En caso que tu transacción haya declinado, debes verificar si la información de la cuenta es válida, está habilitada para compras no presenciales y si tienes cupo o saldo disponible. Si después de esto continua con la declinación debes comunicarte con “Grande Table Delivery”. En última instancia, puedes remitir tu solicitud a servicioposventa@placetopay.ec.</p>
    `
  },
  {
    question: `¿Qué debo hacer si no recibí el comprobante de pago?`,
    answer: `Por cada transacción aprobada a través de PlacetoPay, recibirás un comprobante del pago con la referencia de compra en la dirección de correo electrónico que indicaste al momento de pagar.  Si no lo recibes, podrás contactar a la línea 02-500-1100 para solicitar el reenvío del comprobante a la misma dirección de correo electrónico registrada al momento de pagar. En última instancia, puedes remitir tu solicitud a servicioposventa@placetopay.ec.`
  }
]
