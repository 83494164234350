import React from 'react'
import DateDelivery from '../../../../components/Checkout/components/DateDelivery'

export type DeliveryDateProps = {
  programmed: boolean
  startDate: Date | null
  startTime: Date | null
  deliveryDate: string
  mcn?: boolean
}
export default function DeliveryDate(props: DeliveryDateProps) {
  const { programmed, startDate, startTime, deliveryDate, mcn } = props

  return programmed && (startDate !== null || startTime !== null) ? (
    <DateDelivery
      schedule={deliveryDate}
      type={
        startTime !== null && startDate !== null
          ? ''
          : startDate === null
          ? 'time'
          : 'date'
      }
    />
  ) : !programmed || (programmed && startDate == null && startTime == null) ? (
    <DateDelivery schedule={'persist'} mcn={mcn}/>
  ) : (
    <DateDelivery mcn={mcn}/>
  )
}
