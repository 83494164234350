import styled from 'styled-components'
import Carousel from 'react-elastic-carousel'
import sizes from '../../styles/media'
import { COLORS } from '../../styles/colors'

type ArrowContainerProps = {
  onClick: any
  position: string
}

export const ArrowContainer = styled.div<ArrowContainerProps>`
  position: absolute;
  z-index: 2;
  top: 50%;
  ${(props) => props.position}: 0.5rem;
  cursor: pointer;
  @media ${sizes.md} {
    display: none;
  }
`

export const ImgLink = styled.a`
  cursor: pointer;
  display: none;
  @media ${sizes.md} {
    display: block;
  }
`

export const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  justify-content: center;
  margin-bottom: 1rem;
  display: none;
  li {
    flex: auto;
    margin-right: 1rem;
    border: solid 1px #d9d9d9;
    max-width: 20%;
    &:last-child {
      margin: 0;
    }
  }
`

export const StyledPaginationImg = styled.img`
  max-width: 100%;
`

export const StyledCarousel = styled(Carousel)`
  .rec-carousel {
    background: ${COLORS.white};
    border: solid 0px #d9d9d9;
    position: relative;

    .rec-item-wrapper {
      align-items: center;
    }
  }
  .rec-slider-container {
    margin: 0;
    overflow: scroll;
  }
`

export const SliderContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex: 1;
  margin-bottom: 1rem;

  @media ${sizes.sm} {
    margin-bottom: 1rem;
    padding: unset;
  }

  .img-product {
    margin: auto;
    max-height: 300px;
    width: 100%;

    @media ${sizes.sm} {
      max-height: unset;
    }
  }

  & img {
    margin: auto;
    border: solid 1px #d9d9d9;
    box-shadow: 0px 0px 10px #d9d9d9;
    @media ${sizes.md} {
      margin: 1rem auto;
    }
    @media ${sizes.lg} {
      margin: auto;
    }
  }
`
