import React, { useContext } from 'react'
import DateDelivery from '../../../../components/Checkout/components/DateDelivery'
import { TransactionBox, Name, TransactionMessage, OrderMessage, ConfirmationMailMessage, MembershipBox, Invitation, BenefitsBox, Benefit, ExtraInfo } from './style'
import reservationIcon from '../../../../assets/cart/reservation-sign.svg'
import deliveryIcon from '../../../../assets/cart/delivery-bike.svg'
import discountIcon from '../../../../assets/cart/discount-card.svg'
import { ExternalLink, NavButton } from '../../../../components/Buttons'
import { COLORS } from '../../../../styles/colors'
import CartContext from '../../../../context/CartContext'
import { removeFromStorage } from '../../../../helpers/shippingDataInLocalStorage'
import { Hr } from '../../../Shipping/style'
import { GRANDE_TABLE_PORTAL_URL } from '../../../../config'
import SocialContainer from '../../../../components/SocialContainer'
import { useIsMobile } from '../../../../helpers/resize'

export default function ApproveMessage() {
  const { firstName, email } = JSON.parse(window.localStorage.getItem('invoiceData') || '{}')
  const { reference, status } = JSON.parse(window.localStorage.getItem('response') || '{reference:""}')
  const isMember = window.localStorage.getItem('isMember')
  const { onDelAllItems } = useContext(CartContext)

  return (
    <TransactionBox>
      <Name>{firstName}</Name>
      <TransactionMessage>{status == 'APPROVED' && 'Tu transacción ha sido realizada exitosamente.'}</TransactionMessage>
      <OrderMessage>
        Empezaremos a gestionar tu pedido:
        <div className="colored">#{reference}</div>
        <DateDelivery resume={true} />
      </OrderMessage>
      <ConfirmationMailMessage>
        Recibirás la confirmación de tu compra al correo:
        <br />
        <span>{email}</span>
      </ConfirmationMailMessage>
      {isMember != 'true' ? (
        <MembershipBox>
          <Invitation>Sé parte del Club Gourmet más exclusivo del país</Invitation>
          <p className="enjoy-text">disfruta de beneficios y descuentos todos los días</p>
          <BenefitsBox>
            <li>
              <Benefit>
                <img src={reservationIcon} />
                <div>Reservas exclusivas y eventos gastronómicos</div>
              </Benefit>
            </li>
            <li>
              <Benefit>
                <img src={deliveryIcon} />
                <div>Delivery Gratuito</div>
              </Benefit>
            </li>
            <li>
              <Benefit>
                <img src={discountIcon} />
                <div>Descuento en restaurantes y delivery</div>
              </Benefit>
            </li>
          </BenefitsBox>
          <ExternalLink
            target="_blank"
            externalURL={`${GRANDE_TABLE_PORTAL_URL}/membership`}
            text="Únete al club gourmet"
            backgroundColor={COLORS.camel}
            textColor={COLORS.white}
            className="fixed-width"
            onClick={() => {
              window.localStorage.removeItem('coupon')
              window.localStorage.removeItem('encasa')
              onDelAllItems && onDelAllItems()
              removeFromStorage()
            }}
          />
        </MembershipBox>
      ) : (
        <NavButton
          className="single-bottom"
          to="/"
          backgroundColor={COLORS.dark}
          color={COLORS.white}
          bordercolor={COLORS.dark}
          onClick={() => {
            window.localStorage.removeItem('coupon')
            window.localStorage.removeItem('encasa')
            onDelAllItems && onDelAllItems()
            removeFromStorage()
          }}
        >
          SEGUIR COMPRANDO
        </NavButton>
      )}
      <Hr />
      <ExtraInfo>
        Atendemos tus dudas llamando a nuestro call center
        <br />
        <b>02 - 500 - 1100</b>
        <br />
        <br />
        Horarios de atención:
        <br />
        <b>Lunes a viernes de 10am - 6pm /{useIsMobile(window.matchMedia(`(max-width: 767px)`).matches) && <br />} Sábado y domingo de 10am - 5pm</b>
      </ExtraInfo>
      <SocialContainer textMessage="Házles saber a tus amigos de Grande Table" />
    </TransactionBox>
  )
}
