import { PromoRepresentation } from '../components/Promotions/components/PromotionItem'
import { PromoImages, Promotion } from '../model/Promotion'

export const toRepresentation = (promotion: Promotion): PromoRepresentation => ({
  image: getImages(promotion.assets),
  title: promotion.name,
  comment: promotion.summary,
  priority: promotion.order,
  url: promotion.url,
})

export const toRepresentations = (promotions: Promotion[]): PromoRepresentation[] => promotions.map((promotion: Promotion) => toRepresentation(promotion))

const getImages = (promoImages: PromoImages[]): string => {
  return !promoImages || promoImages.length == 0 ? '' : promoImages[0].url
}
