const sizes = {
  xs: 350,
  xsm: 426,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
}

const media = {
  xs: `(min-width: ${sizes.xs}px)`,
  xsm: `(min-width: ${sizes.xsm}px)`,
  sm: `(min-width: ${sizes.sm}px)`,
  md: `(min-width: ${sizes.md}px)`,
  lg: `(min-width: ${sizes.lg}px)`,
  xl: `(min-width: ${sizes.xl}px)`
}

export default media
