import styled from 'styled-components'
import { COLORS } from '../../styles/colors'
import media from '../../styles/media'

export const PromotionsBox = styled.div`
  padding-bottom: 15px;
  margin: 2rem 0;
  @media ${media.xl} {
    padding-bottom: 5px;
  }

  .no-side-margins {
    margin: 0 -15px;
  }

  p.sub {
    color: ${COLORS.purpleBrown};
    margin-top: 0;
    font-family: 'Roboto-Light';
  }

  .promo-slider {
    .slick-list {
      margin: 0 45px;
      @media ${media.xl} {
        margin: 0;
      }

      .slick-track {
        .slick-active {
          margin-right: -280px;
          @media ${media.lg} {
            margin-right: -75px;
          }
          @media ${media.xl} {
            margin-right: 0.71rem;
            width: 360px !important;
          }

          & > div {
            width: 290px;
            @media ${media.lg} {
              width: 310px;
            }
            @media ${media.xl} {
              width: 360px;
            }
          }
        }
      }
    }

    &.left-aligned {
      .slick-list {
        margin: 0 auto 0 0;

        .slick-track {
          width: auto !important;

          .slick-active {
            @media ${media.xl} {
              margin-right: 2.5%;
            }
          }
        }
      }
    }
  }
`
