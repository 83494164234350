import styled from 'styled-components'
import sizes from '../../../../../../styles/media'

interface Props {
  backgroundcolor?: string
  fontcolor?: string
}

export const Title = styled.span`
  font-weight: bold;
  font-size: 1rem;
`
export const PriceContainer = styled.div`
  margin-top: 15px;

  @media ${sizes.xs} {
    margin-top: 0;
  }

  ${(props: Props) => {
    const style = `
      width: 210px;
      border-radius: 5px;
      border: solid 1px ${props.fontcolor};
      display: inline-block;
      padding: 1rem 1.5rem;
    `
    return style
  }}
`

export const ItemContainer = styled.div`
  margin-bottom: 0.5rem;

  i {
    margin-right: 4px;
  }
`
export const RegularPrice = styled.span`
  color: #ff4d4d;
  text-decoration: line-through;
`

export const StyledPrice = styled.span`
  ${(props: Props) => {
    return `
      color: ${props.fontcolor};
      font-size: 36px;
      font-weight: bold;
    `
  }};
`

export const SavedPrice = styled.span`
  color: #198e00;
  font-weight: bold;
`
