import React from 'react'
import { SliderArrow } from './style'
import prevArrow from '../../../../assets/banners/prev-arrow.svg'
import nextArrow from '../../../../assets/banners/next-arrow.svg'

interface ArrowProps {
  className?: string
  onClick?: any
  isPrev?: boolean
}
export const Arrow = (props: ArrowProps) => {
  const { className, onClick, isPrev } = props

  return (
    <SliderArrow className={className} onClick={onClick}>
      <img src={isPrev ? prevArrow : nextArrow} />
    </SliderArrow>
  )
}
