import React from 'react'
import { DateDeliveyContainer, DateInfo, DateText, DateIcon } from './style'
//import CartContext from '../../../../context/CartContext'
import timer from '../../../../assets/images/clock.svg'
import blackTimer from '../../../../assets/images/clock.svg'

interface Props {
  schedule?: string
  type?: string
  resume?: boolean
  mcn?: boolean
}

function DateDelivery({ schedule, type, resume = false, mcn }: Props) {
  const days = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miércoles',
    'Jueves',
    'Viernes',
    'Sábado'
  ]
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ]

  const storageScheduled = JSON.parse(
    window.localStorage.getItem('scheduled') || '{}'
  )
  /* iOS date format fix: .replace(/-/g, '/')  by https://stackoverflow.com/a/5646753i */
  const date =
    schedule && schedule !== ''
      ? new Date(schedule.replace(/-/g, '/'))
      : storageScheduled &&
        storageScheduled.scheduled &&
        storageScheduled.date &&
        storageScheduled.date !== null
      ? new Date(storageScheduled.date.replace(/-/g, '/'))
      : null
  let dateName

  dateName = date
    ? `${days[date.getDay()]} ${
        date.getDate() < 10 ? '0' : ''
      }${date.getDate()} de ${months[date.getMonth()]} a las ${
        date.getHours() < 10 ? '0' : ''
      }${date.getHours()}:${
        date.getMinutes() < 10 ? '0' : ''
      }${date.getMinutes()} Horas `
    : ''

  if (type && type === 'date') {
    dateName = date
      ? `${days[date.getDay()]} ${
          date.getDate() < 10 ? '0' : ''
        }${date.getDate()} de ${months[date.getMonth()]}`
      : ''
  }

  if (type && type === 'time') {
    dateName = date
      ? `a las ${date.getHours() < 10 ? '0' : ''}${date.getHours()}:${
          date.getMinutes() < 10 ? '0' : ''
        }${date.getMinutes()} Horas `
      : ''
  }

  //const { deliverInfo } = useContext(CartContext)
  const deliverInfoLS = window.localStorage.getItem('deliverInfo')
   const message = schedule
    ? schedule === 'persist'
      ? deliverInfoLS
      : `${!mcn ? 'Tu' : 'Su'} pedido será entregado el ${dateName}`
    : storageScheduled && storageScheduled.scheduled && storageScheduled.date
    ? `${!mcn ? 'Tu' : 'Su'} pedido será entregado el ${dateName}`
    : deliverInfoLS
  return (
    <DateDeliveyContainer className={resume ? 'resume' : ''}>
      <DateIcon>
        <img src={resume ? blackTimer : timer} />
      </DateIcon>
      <DateInfo>
        <DateText className={resume ? 'resume' : ''}>{message}</DateText>
      </DateInfo>
    </DateDeliveyContainer>
  )
}

export default DateDelivery
