import React, { useContext, useState } from 'react'
import ThemeContext from '../../context/ThemeContext'
import CartContext from '../../context/CartContext'
import { Row, Column } from '../../components/Grid'
import {
  ModalWrapper,
  ModalDataContainer,
  ButtonContainer,
  FeatureErrors
} from './style'
import Select from 'react-select'
import { Button } from '../../components/Buttons'
import Variation from '../../model/Variation'
import { COLORS } from '../../styles/colors'

type Option = {
  id: string
  name: string
}

type OptionSelect = {
  label: string
  value: string
  feature: string
}

type Feature = {
  id: string
  name: string
  options: Option[]
}

interface ModalContainerProps {
  productname?: string
  variations: Variation[]
  modalOpen: boolean
  closeVariationModal?: () => void
  features?: Feature[]
  addVariationToCart?: (variation: Variation) => void
  functionClose?: () => void
}

const VariationModal = ({
  productname,
  variations,
  features,
  modalOpen,
  addVariationToCart,
  closeVariationModal
}: ModalContainerProps) => {
  const { primary, secondary } = useContext(ThemeContext)
  const { forceShowNotification } = useContext(CartContext)

  const [featureErrors, setFeatureErrors] = useState(() => {
    const errors = {}
    features &&
      features.forEach((feature: Feature, index: number) => {
        errors[feature.id] = ''
      })
    return errors
  })
  const [selectedFeatures, setSelectedFeatures] = useState(() => {
    const featuresDefault =
      features &&
      features.map((feature: Feature) => {
        const optionDefault: OptionSelect = {
          value: '',
          label: 'Seleccionar',
          feature: feature.id
        }
        return optionDefault
      })
    return featuresDefault && featuresDefault.length > 0 ? featuresDefault : []
  })

  const handleFeatureChange = (optionFromSelect: any) => {
    const newSelectedFeatures = selectedFeatures
    selectedFeatures.forEach((option: OptionSelect, index: number) => {
      if (option.feature === optionFromSelect.feature) {
        newSelectedFeatures[index].feature = optionFromSelect.feature
        newSelectedFeatures[index].label = optionFromSelect.label
        newSelectedFeatures[index].value = optionFromSelect.value
      }
    })
    setSelectedFeatures(newSelectedFeatures)
  }

  const findVariationOverOptions = () => {
    let filteredVariations = variations.slice()
    selectedFeatures.forEach((option: OptionSelect) => {
      filteredVariations = filteredVariations.filter((variation: Variation) => {
        const variationOptions = variation.options
        const foundedOption = variationOptions.find(
          (optionV: any) => optionV.id === option.value
        )
        return !!foundedOption
      })
    })
    return filteredVariations.length > 0 ? filteredVariations[0] : null
  }

  const validateFeatureSelection = () => {
    let foundError = false
    const newError = Object.assign({}, featureErrors)
    selectedFeatures.forEach((option: OptionSelect) => {
      if (option.value.length === 0 || option.value === '') {
        newError[option.feature] = 'Campo Obligatorio'
        foundError = true
      } else {
        newError[option.feature] = ''
      }
    })
    setFeatureErrors(newError)

    if (!foundError) {
      if (addVariationToCart) {
        const getVariation = findVariationOverOptions()
        if (!getVariation) {
          forceShowNotification &&
            forceShowNotification({
              type: 'alert',
              message: 'Opción no válida'
            })
        } else {
          addVariationToCart(getVariation)
        }
      }
    }
  }

  return (
    <ModalWrapper
      style={{
        overlay: {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'auto',
          padding: '15px',
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          zIndex: 100
        }
      }}
      ariaHideApp={false}
      isOpen={modalOpen}
    >
      <>
        <ModalDataContainer
          padding={'30px 30px 0px 30px'}
          backgroundcolor={COLORS.white}
        >
          <Row>
            <Column>
              <h2>{productname}</h2>
              <p className="description">
                Seleccione las opciones para su plato
              </p>
              {features &&
                features.map((feature: Feature, index: number) => {
                  const featureOptions = feature.options.map(
                    (option: Option) => {
                      const selectOption = {
                        label: option.name,
                        value: option.id,
                        feature: feature.id
                      }
                      return selectOption
                    }
                  )

                  /* Filter the array of selected option by feature id */
                  const selectedOptionFiltered = selectedFeatures.filter(
                    (option: OptionSelect) => {
                      return feature.id === option.feature
                    }
                  )

                  return (
                    <Row key={index}>
                      <Column style={{ textAlign: 'left' }}>
                        <p>{feature.name}</p>
                        <Select
                          required
                          options={featureOptions}
                          onChange={(optionSelected: any) => {
                            handleFeatureChange(optionSelected)
                          }}
                          value={selectedOptionFiltered}
                        />
                        <FeatureErrors>
                          {featureErrors[feature.id]}
                        </FeatureErrors>
                      </Column>
                    </Row>
                  )
                })}

              <br></br>
              <br></br>
            </Column>
          </Row>
        </ModalDataContainer>
        <ModalDataContainer
          padding={'30px 30px 10px 30px'}
          backgroundcolor={'#f5f5f5'}
        >
          <span className="arrow"></span>
          <Row>
            <Column sm={6}>
              <ButtonContainer>
                <Button
                  backgroundColor={'#75787b'}
                  color={secondary}
                  onClick={() => closeVariationModal && closeVariationModal()}
                >
                  Cancelar
                </Button>
              </ButtonContainer>
            </Column>
            <Column sm={6}>
              <ButtonContainer>
                <Button
                  backgroundColor={primary}
                  color={secondary}
                  onClick={validateFeatureSelection}
                >
                  Agregar
                </Button>
              </ButtonContainer>
            </Column>
          </Row>
        </ModalDataContainer>
      </>
    </ModalWrapper>
  )
}

export default VariationModal
