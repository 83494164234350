import styled from 'styled-components'
import media from '../../../../styles/media'

export const SliderArrow = styled.button`
  display: none;
  @media ${media.md} {
    display: block;
    border: none;
    background: none;
    height: 35px;
    position: absolute;
    top: 35%;
  }

  &.next-btn {
    right: 0;
   @media ${media.xl} {
     right: -4%;
   } 
  }

  &.prev-btn {
    @media ${media.xl} {
      left: -5%;
    }
  }

  img {
    width: 35px;
  }
`
