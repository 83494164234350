import Address from "../../model/Address";
import Customer from "../../model/Customer";
import Variation from "../../model/Variation";
import {IPageEvent, PageEventProps} from "./IPageEvent";

export class PurchaseEvent implements IPageEvent {
  private readonly TypeEvent: string;
  private readonly ActionField: string;
  private readonly GroupActionEvent: string;

  constructor(typeEvent: string, actionField: string, groupActionEvent: string) {
    this.TypeEvent = typeEvent;
    this.ActionField = actionField;
    this.GroupActionEvent = groupActionEvent;
  }

  initialize(items: Variation[], order: string): PageEventProps {
    let stepOneProducts: any[] = [];
    const address: Address = JSON.parse(window.localStorage.getItem('address') || '{}')
    const customer: Customer = JSON.parse(window.localStorage.getItem('customer') || '{}')
    const enCasa = JSON.parse(window.localStorage.getItem('encasa') || '{}')
    const orderId = order

    let variationProduct = items.map((variation: Variation) => {
      const category = variation.product.categories !== undefined && variation.product.categories.length > 0 && variation.product.categories.map((x) => x.slug);
      return {
        name: variation.product.name,
        id: variation.reference,
        price: variation.price,
        supplier: variation.product.supplier.business_name,
        category: category,
        quantity: variation.quantity,
      }
    });
    stepOneProducts.push(...variationProduct)

    return {
      event: this.TypeEvent,
      ecommerce: {
        [this.GroupActionEvent]: {
          actionField: { list: this.ActionField },
          order: orderId,
          address: address,
          customer: customer,
          enCasa: enCasa,
          ...this.calculatePayment(items),
          products: stepOneProducts
        },
      }
    }
  }

  calculatePayment(items: Variation[]): any {
    let preBilling = {
      subtotal: 0,
      tax: 0,
      shipping: 0
    }

    items.forEach((variation: Variation) => {
      let total = variation.price * variation.quantity
      let subtotal = total / 1.12
      preBilling.subtotal += subtotal
      preBilling.tax += total - subtotal
    })

    return preBilling
  }


}
