import React from 'react'
import { Column, Row } from '../../components/Grid'
import Lottie from 'react-lottie'
import animationData from '../../assets/delivery/gt-data-desktop.json'
import animationMobile from '../../assets/delivery/gt-data-mobile.json'
import { ImgMobile, ImgLaptop } from './style'

function Delivery() {
  const LaptopOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMax slice'
    }
  }
  const MobileOptions = {
    loop: true,
    autoplay: true,
    animationData: animationMobile,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMax slice'
    }
  }

  return (
    <Row style={{paddingTop: '1rem'}}>
      <Column md={12} lg={12}>
        <ImgLaptop>
          <Lottie options={LaptopOptions} height={'auto'} width={'auto'} />
        </ImgLaptop>
        <ImgMobile>
          <Lottie options={MobileOptions} height={'auto'} width={'auto'} />
        </ImgMobile>
      </Column>
    </Row>
  )
}

export default Delivery
