import React, { useContext, useState, useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
// import Variation from '../../model/Variation'
import formatNumber from '../../helpers/formatNumber'
import Icon from '../Icon'
import { Button } from '../Buttons'
import LazyImage from '../LazyImage'
import { NavButton } from '../Buttons'
import ThemeContext from '../../context/ThemeContext'
import CartContext from '../../context/CartContext'
import calculateDiscount from '../../helpers/calculateDiscount'
import {
  ProductContainer,
  ImgContainer,
  ImgContainerMobile,
  ButtonContainer,
  ProductInfoContainer,
  ProductPriceContainer,
  CustomTitle,
  DetailContainer,
  ItemContainer,
  Title,
  QuantityContainer,
  QuantityButton,
  Quantity,
  Price,
  PriceInfo
} from './style'
import { DiscountContainer, DiscountLabel } from '../Global/style'
import Product from '../../model/Product'
import Variation from '../../model/Variation'
import Assets from '../../model/Assets'
import { COLORS } from '../../styles/colors'

interface Props extends RouteComponentProps<any> {
  product: Product
  mcn?: boolean
}

export const ProductCard = ({ history, product, mcn }: Props) => {
  const [count, setCount] = useState(1)
  const [selectedVariation, setselectedVariation] = useState<
    Variation | undefined
  >()
  const { onAddToCart, forceShowNotification } = useContext(CartContext)
  const { primary, secondary } = useContext(ThemeContext)

  const { name, slug, assets } = product

  const redirectProduct = (slug: string) => history.push(`/product/${slug}`)
  const selectVariation = (variation: Variation) => {
    setselectedVariation(variation)
    let arrAssets: Assets[] = []
    if (product.variations) {
      arrAssets = arrAssets.concat(variation.assets)
    }
    if (product.assets) {
      arrAssets = arrAssets.concat(product.assets)
    }
  }
  const [maxRegularPrice, setMaxRegularPrice] = useState<number>(0)

  const addtoCart = () => {
    if (selectedVariation) {
      const {
        inventory: { localStock, storageStock }
      } = selectedVariation
      let no_stock = localStock + storageStock == 0 ? true : false
      if (no_stock) {
        forceShowNotification &&
          forceShowNotification({
            type: 'fail',
            message: 'Producto fuera de stock'
          })
      } else {
        const addVariation = selectedVariation
        addVariation['product'] = product
        onAddToCart && onAddToCart(addVariation, count)
        forceShowNotification &&
          forceShowNotification({
            type: 'ok',
            message: name + ' se ha añadido al carrito.'
          })
        setCount(1)
      }
    } else {
      forceShowNotification &&
        forceShowNotification({
          type: 'fail',
          message: 'Seleccione un producto'
        })
    }
  }

  const quantityComponent = () => {
    if (selectedVariation) {
      let {
        inventory: { localStock, storageStock }
      } = selectedVariation
      let no_stock = localStock + storageStock == 0 ? true : false
      return (
        <>
          <DetailContainer>
            <ItemContainer>
              <Title>Cantidad: </Title>
              <QuantityContainer>
                <QuantityButton
                  backgroundcolor={primary}
                  fontcolor={secondary}
                  onClick={() => count > 1 && setCount(count - 1)}
                >
                  <Icon
                    color={secondary}
                    name="minus"
                    className="kronos-icon"
                  />
                </QuantityButton>
                <Quantity backgroundcolor={primary}>
                  {no_stock ? 0 : count}
                </Quantity>
                <QuantityButton
                  backgroundcolor={primary}
                  fontcolor={secondary}
                  onClick={() =>
                    localStock + storageStock > count &&
                    10 > count &&
                    setCount(count + 1)
                  }
                >
                  <Icon color={secondary} name="plus" className="kronos-icon" />
                </QuantityButton>
              </QuantityContainer>
            </ItemContainer>
          </DetailContainer>
          <ButtonContainer>
            <Button
              backgroundColor={no_stock ? '#aaaaaa80' : secondary}
              color={primary}
              mcn={mcn}
              bordercolor={primary}
              data-product={name}
              data-quantity={count}
              id="MKTPL_DESPRO_COM"
              onClick={addtoCart}
            >
              Agregar al carrito
            </Button>
          </ButtonContainer>
        </>
      )
    } else {
      return <></>
    }
  }

  useEffect(() => {
    if (product) {
      setMaxRegularPrice(product.maxRegularPrice);
      if (product.variations) {
        const filteredVariations = product.variations.filter((variation) => {
          return variation.status == "active";
        });
        if (filteredVariations.length > 0) {
          selectVariation(filteredVariations[0]);
        }
        const regularPrice = product.variations.map((variation: Variation) => {
          return variation.regularPrice;
        });
        setMaxRegularPrice(Math.min(...regularPrice));
      }
    }
  }, []);
  
  const discountValue = () => { 
    return calculateDiscount(
      product.minPrice,
      maxRegularPrice
    )
  }
  const firstCategory = product.categories && product.categories[0] && product.categories[0].slug
  
  return (
    <ProductContainer
      onClick={() => redirectProduct(slug)}
      className="MKTPL_HOME_SELEC"
      data-product={name}
      id={name}
    >
      <ImgContainer>
        <LazyImage
          src={assets && assets.length > 0 ? assets[0].url : ''}
          alt={name + '-' + firstCategory + '-' + product.supplier.business_name + '-Grande Table Delivery'}
        />
      </ImgContainer>
      <ImgContainerMobile>
        <LazyImage
          /* src={
            assets && assets.length > 0
              ? assets.length > 1
                ? assets[1].url
                : assets[0].url
              : ''
          } */
          src={assets && assets.length > 0 ? assets[0].url : ''}
          alt={name + '-' + firstCategory + '-' + product.supplier.business_name + '-Grande Table Delivery'}
        />
      </ImgContainerMobile>
      <div>
        <ProductInfoContainer>
          <CustomTitle>{name}</CustomTitle>
          {/* <Reference>{reference}</Reference> */}
        </ProductInfoContainer>
        <ProductPriceContainer>
          {/* <Price>$ {formatNumber(product.minPrice)}</Price> */}
          <DiscountContainer>
            <Price>$ {formatNumber(product.minPrice)}</Price>
            {discountValue() > 0 && (
              <DiscountLabel
                backgroundcolor={primary}
                fontcolor={secondary}
                fontSize={'1rem'}
              >
                -{discountValue()}%
              </DiscountLabel>
            )}
          </DiscountContainer>
          <PriceInfo>
            Precio incluye IVA {discountValue() > 0 ? 'y descuento' : ''}
          </PriceInfo>
        </ProductPriceContainer>
        <ButtonContainer
          onClick={() => redirectProduct(slug)}
          className="MKTPL_HOME_SELEC"
          data-product={name}
        >
          <NavButton
            to={`/product/${slug}`}
            backgroundColor={secondary}
            color={primary}
            mcn={mcn}
            bordercolor={primary}
            third={COLORS.camel}
          >
            Ver más
          </NavButton>
        </ButtonContainer>
      </div>
      {quantityComponent()}
    </ProductContainer>
  )
}

export default withRouter(ProductCard)
