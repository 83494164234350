import React, { useState } from 'react'
import Slider from 'react-slick'
import { Query, QueryResult } from 'react-apollo'
import gql from 'graphql-tag'
import { Banner, ActiveBanners } from '../../model/Banner'
import SliderCounter from './components/SliderCounter'
import { MainBanner, ItemSlider } from './style'
import defaultBannerImage from '../../assets/banners/default-banner-desktop.jpg'
import defaultBannerMobileImage from '../../assets/banners/default-banner-mobile.jpg'
import Loader from '../Loader'
import { ImageUrl } from '../../model/ImageUrl'
import { getAssetData } from '../../helpers/assetDataHelper'

const GET_ACTIVE_BANNERS = gql`
  query getActiveBanners {
    activeBanners {
      name
      status
      order
      assets {
        type
        platform_type
        url
      }
    }
  }
`

const getImages = (activeBanners: Banner[]): ImageUrl[] => {
  const images: ImageUrl[] = []
  for (let banner of activeBanners) {
    if (banner.assets.length == 0) {
      continue
    }
    const desktopAsset = getAssetData(banner.assets, 'desktop')
    const mobileAsset = getAssetData(banner.assets, 'mobile')
    images.push({ desktop: desktopAsset.url, mobile: mobileAsset.url })
  }

  return images
}

export default function BannerBox() {
  const [slideIndex, setSlideIndex] = useState(0)
  const [layoutSlider, setLayoutSlider] = useState<Slider | null>(null)

  const setting = {
    infinite: true,
    speed: 2000,
    autoplay: true,
    autoplaySpeed: 7000,
    slidesToScrool: 1,
    pauseOnHover: true,
    arrows: true,
    dots: false,
    beforeChange: (current: number, next: number) => setSlideIndex(next),
  }

  const next = () => {
    layoutSlider && layoutSlider.slickNext()
  }

  const previous = () => {
    layoutSlider && layoutSlider.slickPrev()
  }

  const defaultImage: ImageUrl = {
    desktop: defaultBannerImage,
    mobile: defaultBannerMobileImage,
  }

  return (
    <MainBanner>
      <Query<ActiveBanners> query={GET_ACTIVE_BANNERS}>
        {({ loading, error, data }: QueryResult<ActiveBanners>) => {
          if (loading) {
            return <Loader />
          }

          if (error) {
            return <ItemSlider image={defaultImage} />
          }

          if (!data || !data.activeBanners) {
            return <ItemSlider image={defaultImage} />
          }

          const sortedBanners: Banner[] = data.activeBanners.sort((a: Banner, b: Banner) => a.order - b.order)
          const images: ImageUrl[] = getImages(sortedBanners)

          return images.length >= 2 ? (
            <>
              <Slider ref={slider => setLayoutSlider(slider)} {...setting}>
                {images.map((image: ImageUrl, index: number) => (
                  <ItemSlider key={index} image={image} />
                ))}
              </Slider>
              {layoutSlider && <SliderCounter item={slideIndex + 1} slides={images.length} next={next} previous={previous} />}
            </>
          ) : images.length == 1 ? (
            <ItemSlider image={images[0]} />
          ) : (
            <ItemSlider image={defaultImage} />
          )
        }}
      </Query>
    </MainBanner>
  )
}
