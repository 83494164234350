import styled from 'styled-components'
import { Column, Row } from '../../components/Grid'
import Icon from '../../components/Icon'
import { COLORS } from '../../styles/colors'
import sizes from '../../styles/media'

export const BannersContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${sizes.md} {
    display: flex;
    flex-direction: row;
  }
`

type ContainerProps = {
  backgroundColor?: string
  color?: string
}

export const CategoryContainer = styled.div`
  z-index: 2;
  background-color: ${COLORS.teeth};
  padding-bottom: 1rem;
`

export const CategoryResponsive = styled.div`
  @media ${sizes.lg} {
    display: none;
  }
`

export const CategoryDesktop = styled.div`
  display: none;
  @media ${sizes.lg} {
    display: block;
  }
`

export const PromoBannerContainer = styled(Column)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-self: center;
`

export const StyledColumn = styled(Column)`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: 0.5rem 0;

  @media ${sizes.md} {
    margin: 0;
  }
`

export const StyledRow = styled(Row)``

export const StyledAnimateBanner = styled.div`
  margin: auto;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const StyleContentText = styled.div`
  display: none;
  margin: 5px 3em;
  && h1 {
    font-size: 40px;
    margin: 0;
    margin-top: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
  }

  p {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.13;
    letter-spacing: normal;
  }

  @media ${sizes.lg} {
    display: initial;
  }
`

export const CategoryTitle = styled.h1`
  font-family: 'Roboto';
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  color: ${(props: ContainerProps) => props.color};
  text-align: center;
  margin-bottom: 20px;
  font-size: 18px;
  margin-top: 35px;
  letter-spacing: 1.38px;

  @media ${sizes.sm} {
    margin-top: 0;
    justify-content: center;
    letter-spacing: 1.85px;
    font-size: 24px;
  }
`
export const LabelCategory = styled.label`
  display: flex;
  justify-content: center;
  font-family: 'Roboto';
  margin: 0;
  padding: 15px 0;
  color: ${(props: ContainerProps) => props.color};
  line-height: 1.43;
  font-size: 1rem;
  margin-bottom: 20px;
  cursor: pointer;

  @media ${sizes.sm} {
    padding: 25px 30px;
    justify-content: flex-start;
  }
`

export const CenteredRow = styled(Row)`
  justify-content: center;
`

export const CategoryImages = styled.img`
  width: 26px;
  margin-right: 15px;
`

export const CategoryButtonContainer = styled.div`
  box-shadow: 0 2px 8px 0 rgba(78, 78, 78, 0.3);
  background: ${(props: ContainerProps) => props.backgroundColor};
`
interface CouponProps {
  hasCoupon?: boolean
}

export const BellContainer = styled.div`
  position: absolute;
  z-index: 3;
  top: 1.3rem;
  right: 0.8rem;

  display: ${(props: CouponProps) => (props.hasCoupon ? 'flex' : 'none')};
  align-items: center;

  cursor: ${(props: CouponProps) => (props.hasCoupon ? 'pointer' : '')};
  i {
    &:before {
      margin: 0;
    }
    animation: mymove 0.8s infinite;
  }
  &:hover {
    i {
      color: ${COLORS.camel};
    }
  }

  @keyframes mymove {
    from {
      left: -2px;
    }
    to {
      left: 2px;
    }
  }

  @media ${sizes.md} {
    top: 2rem;
    right: 8%;
  }
`

export const StyledIcon = styled(Icon)`
  position: relative;
  right: -0.8rem;
  @media ${sizes.md} {
    right: 0;
  }
`

export const Oval = styled.span`
  font-family: Arial;
  color: ${COLORS.white};
  background: red;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
  position: absolute;
  top: -0.6rem;
  right: -0.6rem;
  width: 18px;
  height: 18px;
  padding-top: 3px;
`
