import gql from 'graphql-tag'

const GET_PRODUCT_QUERY = gql`
  query getProduct($productSlug: String!) {
    product(id: $productSlug) {
      name
      slug
      description
      summary
      status
      minPrice
      maxRegularPrice
      assets {
        url
        order
      }
      supplier {
        id
        slug
        allow_coupons
        business_name
        city
        metaData {
          entrega
          monto_minimo
          horarios
          en_casa
        }
        status
      }
      categories {
        slug
        name
        id
      }
      features {
        id
        name
        options {
          id
          name
        }
      }
      variations {
        id
        price
        regularPrice
        reference
        inventory {
          localStock
          storageStock
        }
        status
        metaData {
          beneficio
          orderTime
        }
        options {
          id
          name
        }
        assets {
          url
          order
        }
      }
    }
  }
`

const GET_RESTAURANT_QUERY = gql`
  query getSupplierBySlug($filters: SupplierFilters!) {
    supplier(filters: $filters) {
      id
      metaData {
        horario
        horarios
        sectores
        entrega
        descuento
        categoria
      }
    }
  }
`

const GET_CITY_QUERY = gql`
  query getCityByName($filters: CityFilters!) {
    cityByName(filters: $filters) {
      id
      name
      parent {
        id
        name
      }
    }
  }
`

export { GET_PRODUCT_QUERY, GET_RESTAURANT_QUERY, GET_CITY_QUERY }
