import gql from 'graphql-tag'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { graphql, Query, QueryResult } from 'react-apollo'
import Collapsible from 'react-collapsible'
import { withRouter } from 'react-router-dom'
import { createClient } from '../../client'
import Checkout from '../../components/Checkout'
import ProductSummary from '../../components/Checkout/components/ProductSummary'
import TitleSummary from '../../components/Checkout/components/TitleSummary'
import TitleSummaryResponsive from '../../components/Checkout/components/TitleSummaryResponsive'
import * as Grid from '../../components/Grid'
import Loader from '../../components/Loader'
import CartContext from '../../context/CartContext'
import { GtmEventsLoad } from '../../helpers/gtm'
import { CheckoutEvent } from '../../helpers/GTM/CheckoutEvent'
import { Validate } from '../../helpers/inputValidations'
import { openRestaurant } from '../../helpers/openRestaurant'
import { useIsIpad, useIsMobile } from '../../helpers/resize'
import { customStyle, errorStyle } from '../../helpers/selectProps'
import Address from '../../model/Address'
import Buttons, { ButtonsProps } from './components/Buttons'
import ContactInfo, { ContactInfoProps } from './components/ContactInfo'
import DeliveryDate, { DeliveryDateProps } from './components/DeliveryDate'
import IdValidator, { MemberData, ProspectData } from './components/IdValidator'
import { retrieveMemberData, validator } from './components/IdValidator/validator'
import Scheduler, { SchedulerProps } from './components/Scheduler'
import ShippingAddress, { ShippingAddressProps } from './components/ShippingAddress'
import TestBenefitMessage from './components/TestBenefitMessage'
import { ContainerElements, SectionTitle } from './style'

const ADD_TRACKING_MUTATION = gql`
  mutation AddTrack(
    $token: String!
    $createdAt: String!
    $createdAtUnix: Int!
    $type: String!
    $eventType: String!
    $email: String
    $phoneNumber: String
    $mobile: String
    $document: String
    $docUpdated: Int!
    $deliverName: String
    $deliverPhone: String
    $deliverEmail: String
  ) {
    addTrack(
      token: $token
      createdAt: $createdAt
      createdAtUnix: $createdAtUnix
      type: $type
      eventType: $eventType
      email: $email
      phoneNumber: $phoneNumber
      mobile: $mobile
      document: $document
      docUpdated: $docUpdated
      deliverName: $deliverName
      deliverPhone: $deliverPhone
      deliverEmail: $deliverEmail
    ) {
      token
    }
  }
`

const GET_STATES = gql`
  query getStates($filters: StateFilters, $options: StateOptions) {
    states(filters: $filters, options: $options) {
      nodes {
        id
        name
      }
    }
  }
`

const GET_RESTAURANT_QUERY = gql`
  query getSupplierBySlug($filters: SupplierFilters!) {
    supplier(filters: $filters) {
      id
      metaData {
        sectores
      }
    }
  }
`

type Zone = {
  label: string
  value: string
}

function Shipping({ history, mutate }: any) {
  const { forceShowNotification, onSetLoaded, loaded, updateScheduledOrder, items } = useContext(CartContext)
  //Loading initial values
  const storageScheduled = JSON.parse(window.localStorage.getItem('scheduled') || '{}')
  const enCasa = JSON.parse(window.localStorage.getItem('encasa') || '{}')

  const scheduleData =
    items && items.length > 0 && items[0].product && items[0].product.supplier && items[0].product.supplier.metaData && items[0].product.supplier.metaData.horarios
      ? items[0].product.supplier.metaData.horarios
      : null
  const schedule = scheduleData ? JSON.parse(scheduleData.toString()) : ''

  const [validMembership, isValidMembership] = useState(JSON.parse(window.localStorage.getItem('isMember') || 'false'))
  const [showMessage, setMessageVisible] = useState(JSON.parse(window.localStorage.getItem('showMessage') || 'false'))
  const [testBenefit, isTestBenefit] = useState(JSON.parse(window.localStorage.getItem('isTestBenefit') || 'false'))
  const [chance, setChance] = useState(JSON.parse(window.localStorage.getItem('chance') || '0'))
  const [identification, setIdentification] = useState(window.localStorage.getItem('identification') || '')
  const { firstName, lastName, mainStreet, secondaryStreet, steetNumber, email, phone, addressReference }: MemberData = JSON.parse(
    window.localStorage.getItem('member-data') || '{}'
  )
  const { email: prospectEmail }: ProspectData = JSON.parse(window.localStorage.getItem('prospect-data') || '{}')
  const [memberData, setMemberData] = useState<MemberData | null>(null)
  const [prospectData, setProspectData] = useState<ProspectData | null>(null)
  const tryOut = true

  //programmed order
  const [programmed, setProgrammed] = useState(storageScheduled ? storageScheduled.scheduled : false)
  const [startDate, setStartDate] = useState<Date | null>(storageScheduled && storageScheduled.date ? new Date(storageScheduled.date.replace(/-/g, '/')) : null)
  const [startTime, setStartTime] = useState<Date | null>(storageScheduled && storageScheduled.date ? new Date(storageScheduled.date.replace(/-/g, '/')) : null)
  const [collapsed] = useState(true)

  const [deliveryDate, setDeliveryDate] = useState('')
  const date = new Date()
  const today = date.setHours(0, 0)
  const tumorrow = date.setHours(0, 0)
  const [minTime, setMinTime] = useState<Date>(new Date(today))
  const [maxTime, setMaxTime] = useState<Date>(new Date(tumorrow))

  const [disabledTime, setDisabled] = useState(storageScheduled && storageScheduled.date ? false : true)
  const [timeMessage, setTimeMessage] = useState('Seleccione la hora de entrega')

  const [dateError, setDateError] = useState(false)
  const [timeError, setTimeError] = useState(false)

  const [orderTime, setOrderTime] = useState(0)
  const [open, setOpen] = useState(true)

  // Display media hooks
  const initialMql = window.matchMedia(`(max-width: 767px)`)
  const isMobile = useIsMobile(initialMql.matches)
  const initialMqlIpad = window.matchMedia(`(max-width: 1024px)`)
  const isIpad = useIsIpad(initialMqlIpad.matches)

  const navigatedFromBillingPage = JSON.parse(window.localStorage.getItem('billing-back') || 'false')
  // get initial values of localstorage
  const {
    province: initialState,
    provinceName,
    city: initialCity,
    cityName,
    street1: initialStreet1,
    street2: initialStreet2,
    number: initialNumber,
    zone: initialZone,
    zoneName,
    reference: initialReference,
    firstName: initialFirstName,
    lastName: initialLastName,
    phone: initialPhone,
    email: initialEmail
  }: Address = JSON.parse(window.localStorage.getItem('address') || '{}')
  // Province, City, Zone selected Hooks
  const [provinceState, setProvinceState] = useState({
    value: initialState ? initialState : '',
    label: initialState ? provinceName : 'Seleccione una provincia',
    error: false,
    style: customStyle
  })
  const [cityState, setCityState] = useState({
    value: initialCity ? initialCity : '',
    label: initialCity ? cityName : 'Seleccione una ciudad',
    error: false,
    style: customStyle
  })
  const [zoneState, setZoneState] = useState({
    value: initialZone ? initialZone : '',
    label: initialZone ? zoneName : 'Ingrese un sector',
    error: false,
    style: customStyle
  })
  // define hooks for every input
  const [street1State, setStreet1] = useState({
    error: false,
    message: '',
    value: navigatedFromBillingPage ? initialStreet1 : mainStreet ? mainStreet : ''
  })
  const [street2State, setStreet2] = useState({
    error: false,
    message: '',
    value: navigatedFromBillingPage ? initialStreet2 : secondaryStreet ? secondaryStreet : ''
  })
  const [numberState, setNumber] = useState({
    error: false,
    message: '',
    value: navigatedFromBillingPage ? initialNumber : steetNumber ? steetNumber : ''
  })

  const [referenceState, setReference] = useState({
    error: false,
    message: '',
    value: navigatedFromBillingPage ? initialReference : addressReference ? addressReference : ''
  })
  const [firstNameState, setFirstName] = useState({
    error: false,
    message: '',
    value: navigatedFromBillingPage ? initialFirstName : firstName ? firstName : ''
  })
  const [lastNameState, setLastName] = useState({
    error: false,
    message: '',
    value: navigatedFromBillingPage ? initialLastName : lastName ? lastName : ''
  })
  const [phoneState, setPhone] = useState({
    error: false,
    message: '',
    value: navigatedFromBillingPage ? initialPhone : phone ? phone : ''
  })
  const [emailState, setEmail] = useState({
    error: false,
    message: '',
    value: navigatedFromBillingPage ? initialEmail : email ? email : prospectEmail ? prospectEmail : ''
  })

  const [count, setCount] = useState(initialReference ? initialReference.length : 0)

  const validateSelects = () => {
    if (provinceState.value.length <= 0) {
      setProvinceState({
        ...provinceState,
        error: true,
        style: errorStyle
      })
    }
    if (cityState.value.length <= 0) {
      setCityState({
        ...cityState,
        error: true,
        style: errorStyle
      })
    }
    if (zoneState.value.length <= 0) {
      setZoneState({
        ...zoneState,
        error: true,
        style: errorStyle
      })
    }
  }

  const validateData = (value: string, id: string) => {
    let dataValidated = Validate(value)
    switch (id) {
      case 'street1':
        setStreet1(dataValidated)
        break
      case 'street2':
        setStreet2(dataValidated)
        break
      case 'number':
        setNumber(dataValidated)
        break
      case 'reference':
        dataValidated = Validate(value, '', 50)
        setReference(dataValidated)
        break
      case 'firstName':
        dataValidated = Validate(value, 'characters')
        setFirstName(dataValidated)
        break
      case 'lastName':
        dataValidated = Validate(value, 'characters')
        setLastName(dataValidated)
        break
      case 'phone':
        dataValidated = Validate(value, 'numeric', 10)
        setPhone(dataValidated)
        break
      case 'email':
        dataValidated = Validate(value, 'email', 60)
        setEmail(dataValidated)
        break
    }
    if (dataValidated.error) return true
    else return false
  }

  const validateAllInputs = () => {
    validateSelects()
    validateData(street1State.value, 'street1')
    validateData(street2State.value, 'street2')
    validateData(numberState.value, 'number')
    validateData(referenceState.value, 'reference')
    validateData(firstNameState.value, 'firstName')
    validateData(lastNameState.value, 'lastName')
    validateData(phoneState.value, 'phone')
    validateData(emailState.value, 'email')
    return true
  }

  const saveInLocalStorage = (value: string, id: string) => {
    if (validateData(value, id) == false) {
      const address = JSON.parse(window.localStorage.getItem('address') || '{}')
      address[id] = value
      window.localStorage.setItem('address', JSON.stringify(address))
    }
  }

  const changeDelivery = (currentDate: Date, type: string) => {
    let selectedTime
    let selectedDate
    if (type === 'date') {
      selectedTime = startTime
        ? `${startTime.getHours() < 10 ? '0' : ''}${startTime.getHours()}:${startTime.getMinutes() < 10 ? '0' : ''}${startTime.getMinutes()}:${
            startTime.getSeconds() < 10 ? '0' : ''
          }${startTime.getSeconds()}`
        : ''

      selectedDate = currentDate
        ? `${currentDate.getFullYear()}-${currentDate.getMonth() + 1 < 10 ? '0' : ''}${currentDate.getMonth() + 1}-${currentDate.getDate() < 10 ? '0' : ''}${currentDate.getDate()}`
        : ''
    } else {
      selectedTime = currentDate
        ? `${currentDate.getHours() < 10 ? '0' : ''}${currentDate.getHours()}:${currentDate.getMinutes() < 10 ? '0' : ''}${currentDate.getMinutes()}:${
            currentDate.getSeconds() < 10 ? '0' : ''
          }${currentDate.getSeconds()}`
        : ''

      selectedDate = startDate
        ? `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? '0' : ''}${startDate.getMonth() + 1}-${startDate.getDate() < 10 ? '0' : ''}${startDate.getDate()}`
        : ''
    }

    setDeliveryDate(`${selectedDate} ${selectedTime}`)
  }

  const handleTimeRange = (date: Date, effect: boolean) => {
    const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

    let currentSchedule
    if (scheduleData) {
      if (schedule.mondayToSunday) {
        currentSchedule = schedule['mondayToSunday']
      } else {
        currentSchedule = schedule[weekdays[date.getDay()]]
      }
    } else {
      currentSchedule = 'Desconocido'
    }
    if (currentSchedule.active) {
      const fromArray = currentSchedule.from.label.split(':')
      const toArray = currentSchedule.to.label.split(':')
      if (fromArray.length > 0 && toArray.length > 0) {
        const from = date.setHours(parseInt(fromArray[0]) + 1, parseInt(fromArray[1]))
        const to = date.setHours(parseInt(toArray[0]), parseInt(toArray[1]))
        const fromDate = moment(from).toDate()
        const toDate = moment(to).toDate()
        const nowDate = moment().add(2, 'h').add(0, 'm').toDate()

        if (!effect) {
          if (nowDate > fromDate && nowDate < toDate) {
            setMinTime(nowDate)
            setMaxTime(toDate)
            setDisabled(false)
            setTimeMessage('Seleccione la hora de entrega')
          } else if (nowDate > toDate) {
            setDisabled(true)
            setStartTime(null)
            setTimeMessage('Cerrado')
          } else {
            setMinTime(fromDate)
            setMaxTime(toDate)
            setDisabled(false)
            setTimeMessage('Seleccione la hora de entrega')
          }
        } else {
          setMinTime(fromDate)
          setMaxTime(toDate)
          setDisabled(false)
          setTimeMessage('Seleccione la hora de entrega')
        }
      } else {
        setDisabled(true)
        setStartTime(null)
      }
    } else {
      setMinTime(new Date(today))
      setMaxTime(new Date(tumorrow))
      setDisabled(true)
      setStartTime(null)
      setTimeMessage('Cerrado')
    }
    if (effect && !startDate) {
      setDisabled(true)
    }
  }

  const handleScheduled = (value: boolean) => {
    if (!value) {
      setStartTime(null)
      setStartDate(null)
      setDisabled(true)
    }
    if (!open || (enCasa && enCasa.added)) {
      const message = !open ? 'Restaurante cerrado.' : enCasa && enCasa.added ? 'El tiempo mínimo para solicitar restaurante en casa es de 24 horas' : ''
      forceShowNotification &&
        forceShowNotification({
          type: 'alert',
          message: message
        })
      return
    }

    const tempMetadataOrderTime = items.find(x => x.metaData != null && x.metaData.orderTime != null)
    if (tempMetadataOrderTime !== undefined) {
      setProgrammed(true)
      forceShowNotification &&
        forceShowNotification({
          type: 'alert',
          message: `Pedido con ${tempMetadataOrderTime.metaData.orderTime} horas de anticipación`
        })
      return
    }

    setProgrammed(!programmed)
  }

  const handleSelectedDate = (date: Date) => {
    handleTimeRange(date, false)
    setStartDate(date)
    setDateError(false)
    changeDelivery(date, 'date')
  }

  const handleSelectedTime = (date: Date) => {
    setStartTime(date)
    setTimeError(false)
    changeDelivery(date, 'time')
  }

  const handleClickOnNext = (firstName: String, lastName: String, email: String, phone: String) => {
    onSetLoaded && onSetLoaded(false)
    GtmEventsLoad(new CheckoutEvent(1, 'checkout', 'Shipping', 'shipping').initialize(items))
    var token = window.localStorage.getItem('token') || ''
    mutate({
      variables: {
        token: token,
        createdAt: new Date().toLocaleString(),
        createdAtUnix: Math.round(+new Date() / 1000),
        type: 'NEXT_SHIPPING_EVENT',
        eventType: 'onClick',
        deliverName: firstName + ' ' + lastName,
        deliverPhone: phone,
        deliverEmail: email,
        docUpdated: 0
      }
    })
  }

  // Filters an Options to
  const filters = { s: '' }
  const options = { limit: 100 }
  // Onclick event in next button
  const goToBilling = () => {
    validateAllInputs()
    if (
      !provinceState.value ||
      !cityState.value ||
      !street1State.value ||
      !street2State.value ||
      !numberState.value ||
      !zoneState.value ||
      !referenceState.value ||
      !firstNameState.value ||
      !lastNameState.value ||
      !phoneState.value ||
      !emailState.value ||
      provinceState.error ||
      cityState.error ||
      street1State.error ||
      street2State.error ||
      numberState.error ||
      zoneState.error ||
      referenceState.error ||
      firstNameState.error ||
      lastNameState.error ||
      phoneState.error ||
      emailState.error
    ) {
      forceShowNotification &&
        forceShowNotification({
          type: 'alert',
          message: 'Para avanzar, verifique que todos los campos sean los correctos.'
        })
      return
    }

    if (programmed && (!startDate || !startTime || startDate === null || startTime === null)) {
      if (!startDate || startDate === null) {
        setDateError(true)
      }
      if (!startTime || startTime === null) {
        setTimeError(true)
      }
      forceShowNotification &&
        forceShowNotification({
          type: 'alert',
          message: 'Para programar tu envio, fecha y hora son requeridos.'
        })
      return
    }

    let timeSelected: Date | undefined
    let dateSelected: Date | undefined

    if (startTime && startDate) {
      timeSelected = moment(startTime).toDate()
      dateSelected = moment(startDate).toDate()
    }

    const now = moment()
    const timeSelectedParsed = moment(timeSelected)
    const diffMinutes = timeSelectedParsed.diff(now, 'm')
    const checkIsSameDay = moment(timeSelected).isSame(dateSelected, 'd')
    const checkIsInvalidHour = diffMinutes < 120

    if (forceShowNotification && programmed && checkIsSameDay && checkIsInvalidHour) {
      forceShowNotification({
        type: 'alert',
        message: 'El tiempo mínimo requerido para programar tu pedido es de 2 horas de anticipación.'
      })
      return
    }

    const addressData: Address = {
      city: cityState.value,
      cityName: cityState.label,
      number: numberState.value,
      reference: referenceState.value,
      province: provinceState.value,
      provinceName: provinceState.label,
      street1: street1State.value,
      street2: street2State.value,
      zone: zoneState.value,
      zoneName: zoneState.label,
      firstName: firstNameState.value,
      lastName: lastNameState.value,
      email: emailState.value,
      phone: phoneState.value
    }
    window.localStorage.setItem('address', JSON.stringify(addressData))

    const selectedTime = startTime
      ? `${startTime.getHours() < 10 ? '0' : ''}${startTime.getHours()}:${startTime.getMinutes() < 10 ? '0' : ''}${startTime.getMinutes()}:${
          startTime.getSeconds() < 10 ? '0' : ''
        }${startTime.getSeconds()}`
      : null

    const selectedDate = startDate
      ? `${startDate.getFullYear()}-${startDate.getMonth() + 1 < 10 ? '0' : ''}${startDate.getMonth() + 1}-${startDate.getDate() < 10 ? '0' : ''}${startDate.getDate()}`
      : null

    if (programmed) {
      updateScheduledOrder &&
        updateScheduledOrder({
          scheduled: programmed,
          date: `${selectedDate} ${selectedTime}`
        })
    } else {
      updateScheduledOrder &&
        updateScheduledOrder({
          scheduled: programmed
        })
    }

    handleClickOnNext(firstNameState.value, lastNameState.value, emailState.value, phoneState.value)
    history.push('/billing')
  }

  useEffect(() => {
    setOpen(openRestaurant(scheduleData))
    if (!openRestaurant(scheduleData)) {
      setProgrammed(true)
    }
    handleTimeRange(storageScheduled && storageScheduled.date ? new Date(storageScheduled.date.replace(/-/g, '/')) : new Date(), true)
  }, [scheduleData])
  const [mcn, setMcn] = useState(false)
  const nowDate = new Date()
  const handleWhenNoAffiliate = ({ testBenefit, chance, messageVisible }: any) => {
    isTestBenefit(testBenefit)
    setChance(chance)
    setMessageVisible(messageVisible)
    window.localStorage.setItem('isTestBenefit', `${testBenefit}`)
    window.localStorage.setItem('showMessage', `${messageVisible}`)
    window.localStorage.setItem('expireMemberData', nowDate.setHours(nowDate.getHours() + 4) + '')
    window.localStorage.setItem('chance', `${chance}`)
    window.localStorage.setItem('billing-back', `true`)
  }
  useEffect(() => {
    const mcnStorage = window.sessionStorage.getItem('mcn')
    if (mcnStorage === '1') {
      setMcn(true)
      setIdentification(window.sessionStorage.getItem('identification') || '')
    }

    if (enCasa && enCasa.added) {
      setProgrammed(true)
    }
    const strmcn = window.sessionStorage.getItem('mcn')
    if (strmcn === '1') {
      setMcn(true)
      setIdentification(window.sessionStorage.getItem('identification') || '')
    }
    const couponData = JSON.parse(window.localStorage.getItem('coupon') || '{}')
    async function retrieveMembershipData() {
      if (couponData.identification) {
        const { isAffiliate, affiliate, unAffiliate } = await validator(couponData.identification)
        window.localStorage.setItem('identification', couponData.identification)
        isValidMembership(isAffiliate)
        setIdentification(couponData.identification)
        if (isAffiliate) {
          setMemberData(retrieveMemberData(affiliate))
          return
        }
        if (!unAffiliate) {
          handleWhenNoAffiliate({
            testBenefit: true,
            chance: 1,
            messageVisible: true
          })
          return
        }

        if (unAffiliate) {
          const prospectData = {
            identification: unAffiliate.identification_number,
            email: unAffiliate.email,
            invoiceData: {
              identification: unAffiliate.identification_number,
              email: unAffiliate.email
            }
          }
          window.localStorage.setItem('isMember', JSON.stringify(false))
          window.localStorage.setItem('prospect-data', JSON.stringify(prospectData, ['identification', 'email']))
          window.localStorage.setItem('invoiceData', JSON.stringify(prospectData.invoiceData))
          setProspectData(prospectData)

          if (unAffiliate.purchases < 2) {
            handleWhenNoAffiliate({
              testBenefit: true,
              chance: 2,
              messageVisible: true
            })
            return
          }

          if (unAffiliate.purchases >= 2) {
            handleWhenNoAffiliate({
              testBenefit: false,
              chance: 3,
              messageVisible: true
            })
          }
        }
      }
    }
    retrieveMembershipData()
  }, [])

  useEffect(() => {
    const tempMetadataOrderTime = items.find(x => x.metaData !== null && x.metaData.orderTime != null)
    setOrderTime(tempMetadataOrderTime !== undefined ? Number(tempMetadataOrderTime.metaData.orderTime) : 0)
    tempMetadataOrderTime !== undefined && setProgrammed(true)
    async function getZones() {
      const client = createClient()
      const supplierSlug = items && items.length > 0 && items[0].product && items[0].product.supplier && items[0].product.supplier.slug ? items[0].product.supplier.slug : null

      const { data } = await client.query({
        query: GET_RESTAURANT_QUERY,
        variables: {
          filters: { slug: supplierSlug ? supplierSlug : '' }
        }
      })
      if (data) {
        const suppliersZones: Zone[] = []
        const schedulesData: string = data.supplier.metaData.sectores
        const schedules = schedulesData.indexOf(',') >= 0 ? schedulesData.split(',') : [schedulesData]
        schedules.forEach((Z: any) => {
          suppliersZones.push({ label: Z, value: Z })
        })
        window.localStorage.setItem('zones', JSON.stringify(suppliersZones))
      }
    }
    getZones()
  })

  const isDisabled = () => !validMembership && !testBenefit

  const storedIdentification = window.localStorage.getItem('identification')

  useEffect(() => {
    if (memberData) {
      setStreet1({ error: false, message: '', value: memberData.mainStreet })
      setStreet2({
        error: false,
        message: '',
        value: memberData.secondaryStreet
      })
      setNumber({ error: false, message: '', value: memberData.steetNumber })
      setReference({
        error: false,
        message: '',
        value: memberData.addressReference ? memberData.addressReference : ''
      })
      setFirstName({ error: false, message: '', value: memberData.firstName })
      setLastName({ error: false, message: '', value: memberData.lastName })
      setPhone({ error: false, message: '', value: memberData.phone })
      setEmail({ error: false, message: '', value: memberData.email })
      return
    }

    if (prospectData) {
      setEmail({ error: false, message: '', value: prospectData.email })
    }
  }, [memberData, prospectData])

  return loaded ? (
    <Query variables={{ filters, options }} query={GET_STATES} fetchPolicy="network-only">
      {({ loading, error, data }: QueryResult<any>) => {
        if (loading) {
          return (
            <Checkout>
              <Grid.Container>
                <Loader />
              </Grid.Container>
            </Checkout>
          )
        }

        if (error) {
          return <div>error</div>
        }

        if (!data) {
          return <div>No hay data</div>
        }

        let minDate = new Date()
        minDate.setDate(enCasa && enCasa.added ? minDate.getDate() + 1 : minDate.getDate())
        minDate.setHours(orderTime)
        const schedulerProps: SchedulerProps = {
          collapsed,
          programmed,
          handleScheduled,
          startDate,
          handleSelectedDate,
          minDate,
          dateError,
          startTime,
          handleSelectedTime,
          timeMessage,
          minTime,
          maxTime,
          disabledTime,
          timeError,
          isMobile,
          isDisabled,
          mcn
        }

        const shippingAddressProps: ShippingAddressProps = {
          isDisabled,
          isMobile,
          nodes: data.states.nodes,
          filters,
          options,
          validateData,
          province: provinceState,
          setProvince: setProvinceState,
          city: cityState,
          setCity: setCityState,
          zone: zoneState,
          setZone: setZoneState,
          mainStreet: street1State,
          secondaryStreet: street2State,
          streetNumber: numberState,
          reference: referenceState,
          letterCount: count,
          setLetterCount: setCount,
          saveInLocalStorage
        }

        const contactInfoProps: ContactInfoProps = {
          isDisabled,
          isMobile,
          saveInLocalStorage,
          firstName: firstNameState,
          lastName: lastNameState,
          phone: phoneState,
          email: emailState
        }

        const buttonsProps: ButtonsProps = {
          isDisabled,
          goToBilling,
          onSetLoaded
        }

        const deliveryDateProps: DeliveryDateProps = {
          programmed,
          startDate,
          startTime,
          deliveryDate,
          mcn
        }

        return (
          <Checkout>
            <Grid.Row>
              {isMobile && (
                <Grid.Column>
                  <div style={{ marginBottom: '1rem' }}>
                    <DeliveryDate {...deliveryDateProps} />
                  </div>
                </Grid.Column>
              )}
              <Grid.Column md={isMobile || isIpad ? 12 : 9}>
                <div className="animated fadeInLeft">
                  {!showMessage && !storedIdentification && (
                    <IdValidator
                      isValidMembership={isValidMembership}
                      isTestBenefit={isTestBenefit}
                      setChance={setChance}
                      identification={identification}
                      setIdentification={setIdentification}
                      setMessageVisible={setMessageVisible}
                      tryOut={tryOut}
                      mcn={mcn}
                      setMemberData={setMemberData}
                      setProspectData={setProspectData}
                    />
                  )}
                  {showMessage && <TestBenefitMessage chance={chance} mcn={mcn} />}
                  <Grid.Row>
                    <Grid.Column>
                      <SectionTitle hasGrayedText={isDisabled()}>{mcn ? 'Programe su envío' : 'Programa tu envío'}</SectionTitle>
                    </Grid.Column>
                  </Grid.Row>
                  <Scheduler {...schedulerProps} mcn={mcn} />

                  <Grid.Row>
                    <Grid.Column md={12}>
                      <SectionTitle hasGrayedText={isDisabled()}>Dirección de envío</SectionTitle>
                    </Grid.Column>
                  </Grid.Row>
                  <ShippingAddress {...shippingAddressProps} />

                  <Grid.Row>
                    <Grid.Column>
                      <SectionTitle hasGrayedText={isDisabled()}>Persona de contacto para entrega</SectionTitle>
                    </Grid.Column>
                  </Grid.Row>
                  <ContactInfo {...contactInfoProps} />
                </div>
              </Grid.Column>
              {!isMobile && (
                <Grid.Column md={isIpad ? 6 : 3}>
                  <TitleSummary />
                  <ContainerElements>
                    <ProductSummary />
                  </ContainerElements>
                  {!isIpad && (
                    <>
                      <ContainerElements>
                        <DeliveryDate {...deliveryDateProps} />
                      </ContainerElements>
                      <Buttons {...buttonsProps} />
                    </>
                  )}
                </Grid.Column>
              )}
              {!isMobile && isIpad && (
                <Grid.Column md={6} style={{ margin: '3.5rem 0 0' }}>
                  <ContainerElements>
                    <DeliveryDate {...deliveryDateProps} />
                  </ContainerElements>
                  <Buttons {...buttonsProps} />
                </Grid.Column>
              )}
              {isMobile && (
                <Grid.Column>
                  <ContainerElements>
                    <Collapsible trigger={<TitleSummaryResponsive iconName="down-open" />} triggerWhenOpen={<TitleSummaryResponsive iconName="up-open" />}>
                      <ProductSummary />
                    </Collapsible>
                  </ContainerElements>
                  <Buttons {...buttonsProps} />
                </Grid.Column>
              )}
            </Grid.Row>
          </Checkout>
        )
      }}
    </Query>
  ) : (
    <Checkout>
      <Grid.Row>
        <Loader />
      </Grid.Row>
      <ProductSummary />
    </Checkout>
  )
}

const AddTracklWithMutation = graphql(ADD_TRACKING_MUTATION)(withRouter(Shipping))
export default AddTracklWithMutation
