import React, {useContext, useEffect, useState} from 'react'
import ThemeContext from '../../../../context/ThemeContext'
import CartContext from '../../../../context/CartContext'

import {
  FlexRow,
  SummaryContainer,
  ProductContainer,
  ImageContainer,
  Img,
  DescriptionContainer,
  ProductName,
  Reference,
  DetailPayment,
  SpanPayment,
  TotalContainer,
  ProductService
} from './style'
import formatNumber from '../../../../helpers/formatNumber'
import noImage from '../../../../assets/cart/noImage84x87.png'
import { COLORS } from '../../../../styles/colors'
import atHomeImg from "../../../../assets/at-home/product.svg";

function ProductSummary() {
  const { primary, secondary, third } = useContext(ThemeContext)
  const storageCoupon = JSON.parse(
    window.localStorage.getItem('coupon') || '{}'
  )

  const enCasa = JSON.parse(window.localStorage.getItem('encasa') || '{}')
  const [enCasaAdded] = useState(enCasa ? enCasa.added : false)
  const {
    items,
    loaded,
    onSetLoaded,
    updateItems,
    forceShowNotification,
    onRemoveFromCart
  } = useContext(CartContext)

  useEffect(() => {
    !loaded &&
      updateItems &&
      updateItems().then((ok: boolean) => {
        onSetLoaded && onSetLoaded(ok)
      })
  })

  let subtotal: number = 0,
    discount: number = 0,
    iva: number = 0,
    total: number = 0
  const calculatePayment = () => {
    items.forEach((item) => {
      discount +=
        (item.supplierCost * item.supplierDiscount * item.quantity) / 100
      subtotal += item.supplierCost * item.quantity
    })
    if (enCasa && enCasa.variation && enCasa.variation.price) {
      subtotal = subtotal + enCasa.variation.price / 1.15
    }
    iva = (subtotal - discount) * 0.15
    total = subtotal - discount + iva
  }
  calculatePayment()
  return loaded ? (
    <>
      {loaded ? (
        <>
          <SummaryContainer backgroundcolor={secondary}>
            {items.map((item, index: number) => {
              if (item.quantity > 0) {
                return (
                  <ProductContainer key={item.id}>
                    <ImageContainer>
                      <Img
                        src={
                          item.assets && item.assets.length > 0
                            ? item.assets[0].url
                            : item.product && item.product.assets
                            ? item.product.assets[0].url
                            : noImage
                        }
                      />
                    </ImageContainer>
                    <DescriptionContainer>
                      <ProductName>
                        <span>{item.product ? item.product.name : ''}</span>
                      </ProductName>
                      {item.reference.indexOf(item.product.name) === -1 && (
                        <Reference>
                          <i>{item.reference}</i>
                        </Reference>
                      )}
                      <span>
                        <b>Cant: </b>
                        {item.quantity}
                      </span>
                      <span>
                        <b>$ {formatNumber(item.price)}</b>
                      </span>
                    </DescriptionContainer>
                  </ProductContainer>
                )
              } else {
                if (forceShowNotification && onRemoveFromCart) {
                  let result = forceShowNotification({
                    type: 'fail',
                    message: item.product.name + ' se ha quedado sin stock'
                  })
                  if (result) onRemoveFromCart(index)
                }
                return <div key={index} />
              }
            })}
            { enCasaAdded && (
                <>
                  <ProductContainer >
                    <ImageContainer>
                      <Img
                          src={atHomeImg}
                      />
                    </ImageContainer>
                    <DescriptionContainer>
                      <ProductService>
                        <p><b>Servicio</b></p>
                        <p><b>Restaurante en Casa</b></p>

                      </ProductService>

                      <Reference>
                        {enCasa &&
                        enCasa.variation &&
                        enCasa.variation.reference && (
                            <i> {enCasa.variation.reference}</i>
                        )}
                      </Reference>
                      <span>
                        <b>
                                ${' '}
                          {formatNumber(
                              enCasa &&
                              enCasa.variation &&
                              enCasa.variation.price
                                  ? enCasa.variation.price
                                  : 0
                          )}
                              </b>
                      </span>
                    </DescriptionContainer>
                  </ProductContainer>
               </>
            )

            }
          </SummaryContainer>
          <DetailPayment backgroundcolor={secondary}>
            <FlexRow>
              <span>SUBTOTAL:</span>
              <SpanPayment fontcolor={primary}>
                $ {formatNumber(subtotal)}
              </SpanPayment>
            </FlexRow>
            <FlexRow>
              <span>DESCUENTO:</span>
              <SpanPayment fontcolor={primary}>
                $ {formatNumber(discount)}
              </SpanPayment>
            </FlexRow>
            <FlexRow>
              <span>IVA:</span>
              <SpanPayment fontcolor={primary}>
                $ {formatNumber(iva)}
              </SpanPayment>
            </FlexRow>
            {storageCoupon && storageCoupon.use && (
              <FlexRow>
                <span>CUPÓN:</span>
                <SpanPayment fontcolor={primary}>
                  - $ {formatNumber(storageCoupon.value)}
                </SpanPayment>
              </FlexRow>
            )}
          </DetailPayment>
          <TotalContainer backgroundcolor={COLORS.veryLightPink}>
            <SpanPayment fontcolor={primary}>TOTAL</SpanPayment>
            <SpanPayment fontcolor={primary}>
              ${' '}
              {storageCoupon && storageCoupon.use
                ? formatNumber(
                    total - storageCoupon.value > 0
                      ? total - storageCoupon.value
                      : 0
                  )
                : formatNumber(total)}
            </SpanPayment>
          </TotalContainer>
        </>
      ) : null}
    </>
  ) : null
}

export default ProductSummary
