import styled from 'styled-components'
import { COLORS } from '../../../styles/colors'

export const ModalContent = styled.div`
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .image-cropper {
    width: 60px;
    height: 60px;
    margin: 0 auto;
    padding-bottom: 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }
`

export const CityBox = styled.div`
  margin-bottom: 30px;

  .box {
    padding: 20px 0 10px;
    display: flex;
    flex-direction: column;
  }

  hr {
    width: 100%;
    border: 1px solid ${COLORS.veryLightPink};
    margin: 0;
  }
`

export const CityItem = styled.button`
  border: none;
  background: none;
  margin: 0 10px 10px 20px;
  font-family: 'Roboto-Medium';
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  img {
    widht: 8px;
    height: 16px;
  }
`
