import dotenv from 'dotenv'

dotenv.config({ path: '../.env' })

export const API_URL = process.env.REACT_APP_API_URL || `${window.location.protocol}//${window.location.host}/api`

export const GRANDE_TABLE_PORTAL_URL = process.env.REACT_APP_GRANDE_TABLE_PORTAL_URL || 'https://www.grandetable.com.ec'

export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || ''

export const FACEBOOK_PIXEL_ID = process.env.FACEBOOK_PIXEL_ID || ''