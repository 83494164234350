import React, { useContext, useState } from 'react'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router'
import gql from 'graphql-tag'
import { NavIconButton } from '../../../Buttons'
import ThemeContext from '../../../../context/ThemeContext'
import PaymentResponseContext from '../../../../context/PaymentResponseContext'
import { Steps, TypeStep } from '../../../../helpers/steps'
import ResumeData from '../../../../helpers/resumeData'
import TextDelivery from '../../../../helpers/dateDelivery'
import CartContext from '../../../../context/CartContext'
import { StepperContainer, Step, StepsContainer, StepName } from './style'
import { COLORS } from '../../../../styles/colors'

const GET_ORDER_STATUS = gql`
  query getOrderStatus($orderId: String!) {
    OrderStatus(orderId: $orderId) {
      status
    }
  }
`

function Stepper({ location, history, client }: any) {
  const { primary, btnPrimary } = useContext(ThemeContext)
  const { items } = useContext(CartContext)
  let { setResponse } = useContext(PaymentResponseContext)
  const [state, setState] = useState(true)
  if (items.length == 0) {
    location.pathname = '/cart'
  }

  const {
    orderStatus,
    orderId,
    requestId,
    signature,
    emailCustomer,
    reference
  } = JSON.parse(window.localStorage.getItem('response') || '{}')

  const actualStep = Steps.filter(step => step.pathname === location.pathname)

  const getOrderStatus = async (order_id: string) => {
    const { data, loading } = await client.query({
      query: GET_ORDER_STATUS,
      variables: {
        orderId: order_id
      },
      fetchPolicy: 'network-only'
    })

    if (loading) {
      return ''
    }

    let res = data.OrderStatus.status
    if (res == 'paid') {
      const response = ResumeData(
        'APPROVED',
        requestId,
        signature,
        TextDelivery,
        emailCustomer,
        orderId,
        reference
      )
      setResponse && setResponse(response)
      window.localStorage.setItem('response', JSON.stringify(response))
    }
    if (res == 'canceled') {
      const response = ResumeData(
        'REJECTED',
        requestId,
        signature,
        TextDelivery,
        emailCustomer,
        orderId,
        reference
      )
      setResponse && setResponse(response)
      window.localStorage.setItem('response', JSON.stringify(response))
    }
    return res
  }

  const validatePendingOrders = async () => {
    if (orderStatus == 'processing' && state) {
      setState(false)
      await getOrderStatus(orderId)
      history.push('/resume')
    }
  }
  if (state) {
    validatePendingOrders()
  }

  if (actualStep.length > 0) {
    return (
      <StepperContainer>
        <StepsContainer>
          {Steps.map(({ pathname, name, order }: TypeStep) => {
            if (orderStatus === 'paid') {
              return (
                <Step key={pathname}>
                  <NavIconButton color="#97d653" stepNumber={order + 1} />
                  <StepName color="#75787b">{name}</StepName>
                </Step>
              )
            }

            if (actualStep[0].order < order) {
              return (
                <Step key={pathname}>
                  <NavIconButton
                    color={COLORS.veryLightPink}
                    stepNumber={order + 1}
                  />
                  <StepName color={COLORS.veryLightPink}>{name}</StepName>
                </Step>
              )
            }

            if (actualStep[0].order === order) {
              return (
                <Step key={pathname}>
                  <NavIconButton color={btnPrimary} stepNumber={order + 1} />
                  <StepName color={primary}>{name}</StepName>
                </Step>
              )
            }

            return (
              <Step key={pathname}>
                <NavIconButton color="#97d653" stepNumber={order + 1} />
                <StepName color="#75787b">{name}</StepName>
              </Step>
            )
          })}
        </StepsContainer>
      </StepperContainer>
    )
  }

  return (
    <StepperContainer>
      <StepsContainer>
        {Steps.map(({ pathname, name, order }: TypeStep) => {
          return (
            <Step key={pathname}>
              <NavIconButton color="#97d653" stepNumber={order + 1} />
              <StepName color="#75787b">{name}</StepName>
            </Step>
          )
        })}
      </StepsContainer>
    </StepperContainer>
  )
}

export default withRouter(withApollo(Stepper))
