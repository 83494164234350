import React from "react";
import { BreadcrumbContainer, StyledList, ItemList } from "./style";
import { Link } from "react-router-dom";

export type Item = {
  label: string;
  to?: string;
};

type Props = {
  links: Item[];
};

const Breadcrumb = ({ links }: Props) => (
  <BreadcrumbContainer>
    <StyledList>
      {links.map(link => (
        <ItemList key={`link-${link.label}`} active={link.to ? false : true}>
          {link.to ? <Link to={link.to}>{link.label}</Link> : link.label}
        </ItemList>
      ))}
    </StyledList>
  </BreadcrumbContainer>
);

export default Breadcrumb;
