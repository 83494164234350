import styled from 'styled-components'
import { COLORS } from '../../../../styles/colors'
import sizes from '../../../../styles/media'

export const BenefitForm = styled.div`
  .content {
    padding: 30px 17px;
    text-align: center;

    hr {
      margin-bottom: 20px;
      @media ${sizes.md} {
        width: 65%;
      }
    }

    p {
      font-family: 'Roboto';
      color: #000000;

      &.counter-title {
        font-family: 'Roboto-Bold';
        font-size: 16px;
        color: ${COLORS.purpleBrown};
        @media ${sizes.md} {
          letter-spacing: 1.38px;
        }
        @media ${sizes.lg} {
          font-size: 18px;
        }
      }

      &.compact {
        margin-bottom: 10px;
        margin-top: 0;
      }

      &.excess {
        width: 86%;
        margin: 0 auto 15px;
      }

      &.remember-msg {
        margin-bottom: 20px;

        &.compact {
          margin-bottom: 0;
        }
      }
    }

    .access-note {
      font-family: 'Roboto-Light';
      font-size: 11.5px;
      padding: 5px 20px 0;
      @media ${sizes.md} {
        font-size: 13.5px;
      }
    }

    a.fixed-width {
      width: 100%;
      @media ${sizes.md} {
        width: 50%;
        margin: 0 auto;
      }
      @media ${sizes.lg} {
        width: 32%;
      }
      @media ${sizes.xl} {
        width: 37%;
      }
    }

    &.compact {
      padding: 20px;
    }
  }
`

export const TwoColorMessage = styled.p`
  line-height: 20px;
  font-size: 12.5px;
  @media ${sizes.md} {
    font-size: 14px;
    letter-spacing: 1.23px;
    width: 80%;
    margin: 0 auto;
  }
  @media ${sizes.lg} {
    font-size: 16px;
  }

  span {
    font-family: 'Roboto-Bold';
    color: ${COLORS.camel};
  }
`
