import React, { useContext, useState, useEffect } from 'react'

import { RouteComponentProps, withRouter } from 'react-router'
import { Query, QueryResult, withApollo } from 'react-apollo'
import { Redirect } from 'react-router-dom'

import { GET_PRODUCT_QUERY, GET_RESTAURANT_QUERY, GET_CITY_QUERY } from './query'
import { Column, Row } from '../../components/Grid'
import Layout from '../../components/Layout'
import Delivery from '../../components/Delivery'
import Description from './components/Description'
import Error from '../Error'
import CartContext from '../../context/CartContext'
import ProductModel from '../../model/Product'
import Loader from '../../components/Loader'
import {GtmEventsLoad} from "../../helpers/gtm";
import {ProductEvent} from "../../helpers/GTM/ProductEvent";

type Data = {
  product: ProductModel
}

interface Variables {
  productSlug: String
}

interface RouterParams
  extends RouteComponentProps<{
    productSlug?: string
  }> {
  client: any
}

function Product({
  match: {
    params: { productSlug },
  },
  client,
}: RouterParams) {
  const { itemSelected, forceShowNotification, onGetProduct } = useContext(CartContext)

  const [mcn, setMcn] = useState(false)

  useEffect(() => {
    const mcnStorage = window.sessionStorage.getItem('mcn')
    if (mcnStorage === '1') {
      setMcn(true)
    }
  }, [])

  const getZones = async (supplierSlug: string) => {
    const { data, loading } = await client.query({
      query: GET_RESTAURANT_QUERY,
      variables: {
        filters: { slug: supplierSlug ? supplierSlug : '' },
      },
    })
    if (loading) {
      return <Loader />
    }

    if (data) {
      const response = data.supplier.metaData
      return response.sectores
    }
  }

  const getCityByName = async (cityName: string) => {
    const { data, loading } = await client.query({
      query: GET_CITY_QUERY,
      variables: {
        filters: { s: cityName ? cityName : '' },
      },
    })
    if (loading) {
      return <Loader />
    }

    if (data) {
      const response = data.cityByName
      return response
    }
  }

  if (itemSelected && itemSelected.slug == productSlug) {
    return (
      <Layout showCart={true} hasLocationBar>
        {!mcn && <Delivery />}
        <Description mcn={mcn} getCityByName={getCityByName} getZones={getZones} product={itemSelected} />
      </Layout>
    )
  }
  return (
    <Layout hasLocationBar>
      {!mcn && <Delivery />}
      <Query<Data, Variables> query={GET_PRODUCT_QUERY} fetchPolicy="network-only" variables={{ productSlug: productSlug ? productSlug : '' }}>
        {({ loading, error, data }: QueryResult<Data, Variables>) => {
          if (loading) {
            return <Loader />
          }

          if (error) {
            return <Error errorCode={503} />
          }

          if (data && data.product) {
            const product = data.product
            if (product.status == 'inactive' || product.supplier.status == 'inactive') {
              forceShowNotification &&
                forceShowNotification({
                  type: 'fail',
                  message: 'Producto no disponible',
                })
              return <Redirect to="/" />
            }
            GtmEventsLoad(new ProductEvent('productClick', 'Detail', 'productClick').initialize(product))
            onGetProduct && onGetProduct(product)
            return <Description mcn={mcn} getCityByName={getCityByName} getZones={getZones} product={product ? product : undefined} />
          }

          return (
            <Row>
              <Column>No data</Column>
            </Row>
          )
        }}
      </Query>
    </Layout>
  )
}

export default withRouter(withApollo(Product))
