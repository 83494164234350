import styled from 'styled-components'
import sizes from '../../styles/media'

export const ContainerResume = styled.div`
  background-color: white;
  box-shadow: 0 2px 4px 0 #d9d9d9;
  padding: 3rem;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  margin-top: 1rem;

  @media ${sizes.md} {
    padding: 5rem;
  }
`

export const ContainerTitle = styled.div`
  margin-bottom: 25px;
  h2 {
    color: #000000;
    margin-bottom: 0;
    font-size: 20px;
  }

  @media ${sizes.md} {
    h2 {
      font-size: 1.75rem;
    }
  }
`

type InfoContainerProps = {
  align?: string
}
export const DeliverContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
  padding-bottom: 10px;
  @media ${sizes.sm} {
    width: 60%;
    margin: auto;
  }

  @media ${sizes.xl} {
    width: 50%;
  }
`

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${(props: InfoContainerProps) => props.align};

  strong {
    font-size: 13px;
  }

  p {
    font-size: 13px;
    text-align: left;
    margin: 3px 0;
  }
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const CallUsContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5.1px;
`

export const LittleSpan = styled.span`
  font-size: 9.8px;
  /* @media */
`

export const NumberSpan = styled.span`
  font-size: 1rem;
  font-weight: bold;
`

export const IconContainer = styled.div`
  margin-right: 10px;
  @media ${sizes.sm} {
    margin-right: 5px;
  }
`

export const VerticalLine = styled.div`
  background-color: black;
  border: solid 1px black;
  width: 1px;
  height: 31px;
`

export const ContainerButton = styled.div`
  width: 100%;
  @media ${sizes.md} {
    width: 25%;
  }
`
