import React, { useContext, useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import * as Grid from '../../components/Grid'
import Icon from '../../components/Icon'
import gql from 'graphql-tag'
import { graphql, compose, withApollo } from 'react-apollo'
import { ContainerResume, ContainerTitle, BodyContainer, CallUsContainer, FlexCol, NumberSpan, LittleSpan, IconContainer, VerticalLine, ContainerButton } from './style'
import { NavButton } from '../../components/Buttons'
import ThemeContext from '../../context/ThemeContext'
import { useIsMobile } from '../../helpers/resize'
import Stepper from '../../components/Checkout/components/Stepper'
import SocialContainer from '../../components/SocialContainer'
import CartContext from '../../context/CartContext'
import { removeFromStorage, removeFromStorageExcluding } from '../../helpers/shippingDataInLocalStorage'
import ApproveMessage from './components/ApproveMessage'

const ADD_TRACKING_MUTATION = gql`
  mutation AddTrack(
    $token: String!
    $createdAt: String!
    $createdAtUnix: Int!
    $type: String!
    $eventType: String!
    $email: String
    $phoneNumber: String
    $document: String
    $docUpdated: Int!
    $paymentResponse: String
  ) {
    addTrack(
      token: $token
      createdAt: $createdAt
      createdAtUnix: $createdAtUnix
      type: $type
      eventType: $eventType
      email: $email
      phoneNumber: $phoneNumber
      document: $document
      docUpdated: $docUpdated
      paymentResponse: $paymentResponse
    ) {
      token
    }
  }
`

const GET_ORDER_STATUS = gql`
  query getOrderStatus($orderId: String!) {
    OrderStatus(orderId: $orderId) {
      status
      customer {
        email
      }
    }
  }
`

function Resume({ mutate, client }: any) {
  const [imageState, setImageState] = useState('')
  const [message, setMessage] = useState('')
  const [title, setTitle] = useState('')
  const [body, setBody] = useState([''])
  const [status, setStatus] = useState('')
  const { primary, secondary } = useContext(ThemeContext)
  const { onDelAllItems, deleteItemsFromStorage } = useContext(CartContext)

  const initialMql = window.matchMedia(`(max-width: 767px)`)
  const isMobile = useIsMobile(initialMql.matches)
  const isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(window.navigator.platform)

  setTimeout(() => {
    window.localStorage.removeItem('scheduled')
  }, 5000)

  useEffect(() => {
    const importImage = async () => {
      const lsData = JSON.parse(window.localStorage.getItem('response') || '')
      const imgImported = await import(`../../assets/cart/${lsData.image}`)
      setImageState(imgImported.default)
    }

    if (window.localStorage.getItem('response')) {
      const { message, title, body, status } = JSON.parse(window.localStorage.getItem('response') || '')
      setMessage(message)
      setTitle(title)
      setBody(body)
      setStatus(status)
      importImage()
    }

    async function obtainOrderStatus() {
      if (isIOS && isMobile) {
        const { status, customer } = await getOrderInfo()
        setStatus(status)
        finalMessage({ status, customer })
        removeItemsFromStorage(status)
      }
    }
    obtainOrderStatus()
  }, [])

  useEffect(() => {
    removeItemsFromStorage(status)
  }, [])

  const removeItemsFromStorage = (status: string) => {
    if (['APPROVED', 'paid', 'processing', 'pending'].includes(status)) {
      deleteItemsFromStorage && deleteItemsFromStorage()
      window.localStorage.removeItem('coupon')
      window.localStorage.removeItem('encasa')
      removeFromStorageExcluding(['invoiceData', 'paymentURL', 'response', 'isMember'])
    }
  }

  const importiOSImage = async (image: string) => {
    const imgImported = await import(`../../assets/cart/${image}`)
    setImageState(imgImported.default)
  }

  const getOrderInfo = async () => {
    const orderId = window.localStorage.getItem('orderId')
    const { data } = await client.query({
      query: GET_ORDER_STATUS,
      variables: {
        orderId,
      },
    })

    return data.OrderStatus
  }

  type MessageProps = {
    message: string
    title: string
    body: string[]
    image: string
  }
  const summaryMessage = (messageParts: MessageProps) => {
    const { message, title, body, image } = messageParts
    setMessage(message)
    setTitle(title)
    setBody(body)
    importiOSImage(image)
  }

  const finalMessage = ({ status, email }: any) => {
    switch (status) {
      case 'processing':
        summaryMessage({
          message: '',
          title: 'EN PROCESO DE APROBACIÓN',
          body: [
            `Tu transacción se encuentra en proceso de aprobación. Se te confirmará`,
            `por correo electrónico la aprobación o negación de tu pago.`,
            `Cualquier duda o consulta por favor comunícate con tu banco emisor.`,
            `<br/>`,
            `<br/>`,
          ],
          image: 'resumePending.svg',
        })
        break
      case 'paid':
        summaryMessage({
          message: '',
          title: 'Tu transacción ha sido realizada exitosamente. Empezaremos a gestionar tu pedido.',
          body: [
            `<br/>`,
            `<span>Recibirás la confirmación de tu compra al correo: <b>${email}</b></span>`,
            `<br/>`,
            `<br/>`,
            `<span>Por cualquier duda por favor comunícate a nuestro call center</span> `,
            `<span><a href="tel:02-500-1100"><b>02 - 500 - 1100</b></a></span> `,
            `<span>Horarios de atención: <b>Lunes a viernes de 10am a 6pm</b></span>`,
            `<span><b>Sábado y domingo de 10am a 5pm</b></span>`,
            `<br/>`,
          ],
          image: 'resumeApprove.svg',
        })
        break
      case 'pending':
        summaryMessage({
          message: '',
          title: 'EN PROCESO DE APROBACIÓN',
          body: [
            `Tu transacción se encuentra en proceso de aprobación. Se te confirmará`,
            `por correo electrónico la aprobación o negación de tu pago.`,
            `Cualquier duda o consulta por favor comunícate con tu banco emisor.`,
            `<br/>`,
            `<br/>`,
          ],
          image: 'resumePending.svg',
        })
        break
      case 'canceled':
        summaryMessage({
          message: 'Si desea puede intentar realizar el pago nuevamente',
          title: 'El Pago ha sido cancelado.',
          body: [`<span> &nbsp;</span>`],
          image: 'resumeRejected.svg',
        })
        break
      case 'rejected':
        summaryMessage({
          message: '',
          title: 'TRANSACCIÓN RECHAZADA',
          body: [`Lo sentimos, tu transacción ha sido rechazada. Por favor reintenta`, `con otro medio de pago o comunícate con tu banco emisor.`, `<br/>`, `<br/>`],
          image: 'resumeRejected.svg',
        })
        break
    }
  }

  const handleClickOnNext = (paymentResponse: any) => {
    const token = window.localStorage.getItem('token') || ''
    const { identificationNumber, phone, email } = JSON.parse(window.localStorage.getItem('customer') || '{}')
    mutate({
      mutation: ADD_TRACKING_MUTATION,
      variables: {
        token: token,
        createdAt: new Date().toLocaleString(),
        createdAtUnix: Math.round(+new Date() / 1000),
        type: 'RESUME_EVENT',
        eventType: 'onLoad',
        email: email,
        phoneNumber: phone,
        document: identificationNumber,
        paymentResponse: paymentResponse,
        docUpdated: 1,
      },
    })
  }

  return (
    <Layout>
      <Grid.Container onLoad={() => handleClickOnNext(status)} id={'MKTPL_PAY_' + status}>
        <Grid.Row>
          <Grid.Column>
            <Stepper />
          </Grid.Column>
        </Grid.Row>
        <div className="animated fadeInLeft">
          <ContainerResume>
            {status == 'APPROVED' || status == 'paid' ? (
              <ApproveMessage />
            ) : (
              <>
                <img src={imageState} />
                <ContainerTitle>
                  <h2>{title}</h2>
                  <br></br>
                  <span>{message}</span>
                </ContainerTitle>
                <BodyContainer>
                  {body &&
                    body.map((item: string, i: number) => {
                      return <div dangerouslySetInnerHTML={{ __html: item }} key={i} />
                    })}
                </BodyContainer>

                {status != 'APPROVED' && (
                  <CallUsContainer>
                    <IconContainer>
                      <Icon size="50px" name="iconos_marketplace-06" />
                    </IconContainer>
                    <VerticalLine />
                    <FlexCol>
                      <LittleSpan>Comuníquese con nosotros</LittleSpan>
                      <NumberSpan>
                        <a href="tel:02-500-1100">02-500-1100</a>
                      </NumberSpan>
                    </FlexCol>
                  </CallUsContainer>
                )}

                {status != 'REJECTED' && <SocialContainer textMessage="Comparte con tus amigos" />}

                <ContainerButton>
                  {status != 'REJECTED' ? (
                    <NavButton
                      to="/"
                      backgroundColor={primary}
                      color={secondary}
                      bordercolor={primary}
                      onClick={() => {
                        window.localStorage.removeItem('coupon')
                        window.localStorage.removeItem('encasa')
                        onDelAllItems && onDelAllItems()
                        removeFromStorage()
                      }}
                    >
                      SEGUIR COMPRANDO
                    </NavButton>
                  ) : (
                    <NavButton to="/payment" backgroundColor={primary} color={secondary} bordercolor={primary}>
                      REINTENTAR
                    </NavButton>
                  )}
                </ContainerButton>
              </>
            )}
          </ContainerResume>
        </div>
      </Grid.Container>
    </Layout>
  )
}

const AddTracklWithMutation = compose(graphql(ADD_TRACKING_MUTATION))(withApollo(Resume))
export default AddTracklWithMutation
