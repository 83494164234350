import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { COLORS } from '../../styles/colors'
import media from '../../styles/media'

export const Wrapper = styled.div`
  background-color: ${COLORS.white};
  padding: 10px 0;
  min-height: 270px;
  @media ${media.md} {
    padding-top: 1.5rem;
  }
`
export const Logo = styled(Link)`
  display: none;
  @media ${media.md} {
    display: block;
    width: 100px;
  }
  @media ${media.xl} {
    width: 120px;
  }
`

export const SearchBox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`

export const SearchWrapper = styled.div`
  transition: all 300ms ease-in-out;
  padding: 0.7rem 0;
  border-radius: 10px;
  background-color: #f4f4f4;
  min-width: 90%;
  text-align: center;
  @media ${media.sm} {
    min-width: 80%;
  }

  .search {
    position: absolute;
    width: 22px;
    margin-left: 1rem;
    margin-right: 1rem;
    @media ${media.sm} {
      margin-left: -1rem;
    }
  }
`

export const Input = styled.input`
  border: none;
  height: 100%;
  width: 100%;
  background-color: ${COLORS.ligthGray};
  outline: none;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.purpleBrown};
  padding-left: 3rem;
  opacity: 1;
  max-width: 500px;
  @media ${media.sm} {
    padding-left: 1rem;
  }
`

export const Close = styled.div`
  transition: all 300ms ease-in-out;
  cursor: pointer;
  z-index: 9999;
  display: flex;
  align-items: center;
  margin-top: 9px;

  img {
    width: 24px;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
  @media ${media.md} {
    flex-basis: 80%;
  }
  @media ${media.lg} {
    flex-basis: 60%;
  }
  @media ${media.xl} {
    flex-basis: 50%;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${COLORS.gray};
    margin: 1rem 0 0.7rem;
  }

  .content {
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${COLORS.purpleBrown};
    margin: 0 0 0.5rem;
  }
`

export const StoreItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.8rem 0;
  .store {
    display: flex;
    align-items: center;
  }

  img {
    width: 40px;
    height: 40px;
    border: solid 1px #eaeaea;
    border-radius: 50%;
    margin-right: 1rem;
  }

  .item-info {
    p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${COLORS.purpleBrown};
      margin: 0;
    }

    p.city-name {
      color: ${COLORS.gray};
      font-size: 13.5px;
    }
  }
`

export const Item = styled.li`
  list-style-type: none;
  padding: 1.5rem;
  text-transform: uppercase;
  font-size: 14px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  width: 280px;

  a,
  a:hover {
    display: flex;
    justify-content: space-between;
    color: black;
  }
`

export const EmptyBox = styled.div`
  text-align: center;
  padding: 7rem 0 0 2.3rem;
  @media ${media.md} {
    padding: 3rem 2rem;
  }
`
