import React from 'react'
import { Arrow } from '../Arrow'
import { CounterBox } from './style'

type SliderCounterProps = {
  slides: number
  item: number
  next: () => void
  previous: () => void
}
export default function SliderCounter(props: SliderCounterProps) {
  const { item, slides, next, previous } = props

  return (
    <CounterBox>
      <Arrow onClick={previous} isPrev />
      <span className="colored">{item}</span>
      <span>/ {slides}</span>
      <Arrow onClick={next} />
    </CounterBox>
  )
}
