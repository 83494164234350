import React, { Component, createRef } from 'react'
import { Query, QueryResult } from 'react-apollo'
import Product from '../../model/Product'
import { GET_PRODUCTS_QUERY } from './query'

import { Row } from '../Grid'

import { StyledTitle, StyledColumn } from './style'

import Loader from '../Loader'
import ProductCard from '../ProductCard'
import Category from '../../model/Category'

interface DataProducts {
  products: {
    nodes: Product[]
  }
}

interface Filter {
  id: String
}

interface Options {
  limit: Number
  sortBy: String
}

interface Variables {
  filters: Filter
  options: Options
}

type Props = {
  supplierId: string
  productScroll?: string
  mcn?: boolean
}

class Catalog extends Component<Props> {
  state = {
    selectedCategory: {
      value: 'all',
      label: 'Todos',
    },
    items: 150,
    title: 'Todos los restaurantes',
    resetCities: false,
  }

  private myRef = createRef<HTMLDivElement>()

  scroll = (ref: any) => {
    ref.current.scrollIntoView({ behavior: 'smooth' })
  }

  setSelectedCategory = (category: any) => {
    const { selectedCategory } = this.state
    if (category.value !== selectedCategory.value) {
      this.setState({
        selectedCategory: category,
        title: category.value !== 'all' ? category.label : 'Todos los restaurantes',
        resetCities: category.value !== 'all' ? true : false,
      })
    } else {
      this.setState({
        selectedCategory: {
          value: 'all',
          label: 'Seleccione una ciudad',
        },
        title: 'Todos los restaurantes',
        resetCities: false,
      })
    }
    this.scroll(this.myRef)
  }

  productFocus() {
    const { productScroll } = this.props
    setTimeout(() => {
      const divToScrollTo = document.getElementById(`${productScroll}`)
      if (divToScrollTo) {
        divToScrollTo.scrollIntoView()
      }
    }, 100)
  }

  render() {
    const { supplierId, mcn } = this.props
    const productsVariables = {
      filters: { id: supplierId },
      options: {
        limit: this.state.items,
        sortBy: 'priority',
      },
    }

    const { selectedCategory } = this.state
    return (
      <Query<DataProducts, Variables> query={GET_PRODUCTS_QUERY} variables={productsVariables}>
        {({ loading, error, data }: QueryResult<DataProducts, Variables>) => {
          if (loading) {
            return <Loader />
          }

          if (error) {
            return <p>Error</p>
          }

          if (!data) {
            return <p>No Data</p>
          }
          const products = data.products.nodes
          const productsCategories: Category[] = []

          products.forEach((item: Product) => {
            if (item.categories && item.categories.length > 0) {
              const currentCategory = item.categories[0]
              if (!productsCategories.some(category => currentCategory.name === category.name)) {
                productsCategories.push(item.categories[0])
              }
            }
          })

          productsCategories.sort((a, b) => a.priority - b.priority)

          return (
            <>
              <div ref={this.myRef} />
              {productsCategories.map((category, index) => {
                return (
                  <div key={index}>
                    <Row>
                      <StyledColumn sm={7} xs={12}>
                        <StyledTitle>{category.name}</StyledTitle>
                      </StyledColumn>
                    </Row>
                    <Row>
                      {products &&
                        products
                          .filter((node: any) => {
                            if (selectedCategory.value == 'all') {
                              return true
                            }
                            return false
                          })
                          .filter((product: Product) => {
                            const cat = product.categories && product.categories.length ? product.categories[0].name : ''
                            return category.name === cat
                          })
                          .map((product: Product, index: number) => {
                            return (
                              <StyledColumn xs={6} xsm={6} sm={6} md={4} lg={3} key={`product-${index}`}>
                                <ProductCard product={product} mcn={mcn} />
                              </StyledColumn>
                            )
                          })
                          .sort((a: any, b: any) => a.priority - b.priority)}
                      {this.productFocus()}
                    </Row>
                  </div>
                )
              })}
            </>
          )
        }}
      </Query>
    )
  }
}

export default Catalog
