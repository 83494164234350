import React from 'react'
import ShippingViewHandler from '../views/ShippingViewHandler'

type SwitchProps = {
  Component: React.ComponentType
  FallbackComponent: React.ComponentType
}

type ShippingFallBackProps = {
  Component: React.ComponentType
  FallbackComponent: React.ComponentType
}

const Switch = (props: SwitchProps) => {
  const { Component, FallbackComponent, ...rest } = props

  return (
    <ShippingViewHandler>
      {(hasCart: boolean) => {
        if (!hasCart) {
          return <FallbackComponent {...rest} />
        }
        return <Component {...rest} />
      }}
    </ShippingViewHandler>
  )
}

const shippingFallback = (
  Component: React.ComponentType,
  FallbackComponent: React.ComponentType
) => {
  return (props: ShippingFallBackProps) => (
    <Switch
      {...props}
      Component={Component}
      FallbackComponent={FallbackComponent}
    />
  )
}

export default shippingFallback
