import React, { useEffect, useContext } from 'react'
import Checkout from '../../components/Checkout'
import ProductList from '../../components/ProductsList'
import CartContext from '../../context/CartContext'
import Loader from '../../components/Loader'
import * as Grid from '../../components/Grid'

function CartView() {
  const { loaded, onSetLoaded, updateItems } = useContext(CartContext)
  useEffect(() => {
    const response = window.localStorage.getItem('response') || ''
    !loaded &&
      updateItems &&
      !response &&
      updateItems().then((ok: boolean) => {
        onSetLoaded && onSetLoaded(ok)
      })
  })
  return (
    <Checkout hasLocationBar>
      <div className="animated fadeIn">
        {loaded ? (
          <ProductList />
        ) : (
          <Grid.Container>
            <Loader />
          </Grid.Container>
        )}
      </div>
    </Checkout>
  )
}

export default CartView
