import styled from 'styled-components'
import { COLORS } from '../../../../styles/colors'
import sizes from '../../../../styles/media'

export const DateDeliveyContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  border-radius: 5px;
  background-color: rgba(255, 240, 217, 0.29);
  border: solid 1px ${COLORS.camel};
  padding: 1rem 2rem;
  margin: 1rem 0;
  text-align: center;
  align-items: center;
  color: ${COLORS.camel};
  &.resume {
    background-color: transparent;
    border: solid 0px transparent;
    color: #000000;
  }

  @media ${sizes.xs} {
    padding: 1rem 1.5rem;
  }

  @media ${sizes.md} {
    padding: 1rem 0.5rem;
  }

  @media ${sizes.xl} {
    padding: 1rem 1.5rem 1rem 1rem;
  }
`

export const DateIcon = styled.div`
  img {
    max-width: 50px;
  }
`

export const DateInfo = styled.div`
  line-height: 1.2;
  display: flex;
`

export const DateText = styled.span`
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 13px;
  letter-spacing: normal;
  color: ${COLORS.camel};
  line-height: 1.2;
  text-align: left;
  padding-right: 0.2rem;
  margin-left: 10px;
  &.resume {
    color: #000000;
  }
`
