import styled from 'styled-components'
import { Link } from 'react-router-dom'
import sizes from '../../styles/media'
import { COLORS } from '../../styles/colors'

interface Props extends React.HTMLProps<HTMLButtonElement> {
  backgroundcolor?: string
  color: string
  bordercolor?: string
}

interface CProps extends React.HTMLProps<HTMLButtonElement> {
  total: number
}

export const NavButtonContainer = styled(Link)<Props>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem 0rem 0.65rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 13px;
  text-transform: uppercase;
  background-color: ${(props) => props.backgroundcolor};
  color: ${(props) => props.color};
  border: ${(props) =>
    props.bordercolor
      ? '1px solid ' + props.bordercolor
      : '1px solid transparent'};
  transition: 0.2s ease-in;
  &:hover {
    background-color: ${(props) => props.color};
    color: ${(props) => props.backgroundcolor};
    border: 1px solid ${(props) => props.backgroundcolor};
  }

  border-radius: 18px;

  @media ${sizes.sm} {
    border-radius: 15px;
    padding: 0.5rem 0rem 0.55rem;
  }
`

export const CustomButtonContainer = styled.div<CProps>`
  display: ${(props) => (props.total === 0 ? 'none' : 'flex')};
  position: fixed;
  bottom: 0.9rem;
  width: 100%;
  justify-content: center;
  z-index: 1;
  .container {
    width: 92%;
  }

  .subotal-float {
    margin-left: 10px;
    font-size: 13px;
    font-weight: bold;
  }

  .value {
    font-size: 13px;
    font-weight: bold;
    margin-left: 20px;
  }

  @media ${sizes.sm} {
    display: none;
    width: 270px;
    right: 15px;
    .container {
      width: 100%;
    }
  }

  @media ${sizes.md} {
    right: 30px;
    width: 270px;
  }
`

export const TotalSpan = styled.span`
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.17px;
  text-align: right;
  color: #f2f2f2;
`
export const StyledIcon = styled.img`
  position: relative;
  max-width: 23px;
  margin-right: 0.4rem;
`

export const Quantity = styled.div`
  width: 40px;
  height: 30px;
  border-radius: 10px;
  background-color: #414141;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${COLORS.white};
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Wrapper = styled.div`
  width: 100%;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
